import { Badge } from '@/app/atoms/badge';
import { BadgeProps, BadgeVariant } from '@/app/atoms/badge/types';
import { order } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { useMemo } from 'react';

type StatusInfo = {
	variant: BadgeVariant;
	text: string;
};

type OrderStatusProps = Omit<BadgeProps, 'variant' | 'children'> & {
	status: order.OrderStatus;
};

export const OrderStatus = ({ status, ...rest }: OrderStatusProps) => {
	const info = useMemo<StatusInfo>(
		() =>
			match(status, {
				ordered: () => ({ variant: 'green', text: 'Ordered' }),
				cancelled: () => ({ variant: 'red', text: 'cancelled' }),
				acknowledged: () => ({ variant: 'green', text: 'Order confirmed' })
			}),
		[status]
	);

	return (
		<Badge variant={info.variant} {...rest}>
			{info.text}
		</Badge>
	);
};
