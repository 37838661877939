import { slice } from '.';
import { MESH_SCALE } from '../constants';
import { DiagramMesh } from '../types';
import { polygonArea, relativeToCenter } from './geometry';

export const meshKindSortKey = (mesh: DiagramMesh) => {
	const key: Record<DiagramMesh['kind'], number> = {
		whiteout: 0,
		line: 1,
		hotspot: 2,
		polygon: 3
	};

	return key[mesh.kind] ?? 4;
};

export const meshAreaSortKey = (mesh: DiagramMesh) => {
	if (mesh.kind !== 'polygon') {
		return 0;
	}

	return -polygonArea(mesh.polygon);
};

export const relativeMesh = (
	mesh: DiagramMesh,
	sizes: { width: number; height: number; ratio: number; scale?: number }
): DiagramMesh => {
	switch (mesh.kind) {
		case 'whiteout': {
			return {
				...mesh,
				rect: slice([
					relativeToCenter(mesh.rect[0], sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					),
					relativeToCenter(mesh.rect[1], sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					),
					relativeToCenter(mesh.rect[2], sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					),
					relativeToCenter(mesh.rect[3], sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					)
				])
			};
		}
		case 'line': {
			return {
				...mesh,
				from: mesh.from.map(vec2 =>
					relativeToCenter(vec2, sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					)
				),
				to: mesh.to.map(vec2 =>
					relativeToCenter(vec2, sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					)
				)
			};
		}
		case 'polygon': {
			return {
				...mesh,
				polygon: mesh.polygon.map(vec2 =>
					relativeToCenter(vec2, sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					)
				)
			};
		}
		case 'hotspot': {
			return {
				...mesh,
				point: relativeToCenter(mesh.point, sizes.width, sizes.height).multiplyScalar(
					sizes.scale ?? MESH_SCALE
				)
			};
		}
	}
};
