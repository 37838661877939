import { isNil } from 'lodash-es';

export const isDefined = <T>(value: T | null | undefined): value is T => !isNil(value);

export const recursiveFlattenObject = <T>(item: T, more: (item: T) => T[]): T[] => {
	return [item, ...more(item).flatMap(each => recursiveFlattenObject(each, more))];
};

export const recursiveFlattenArray = <T>(items: T[], more: (item: T) => T[]): T[] => {
	return [...items, ...items.flatMap(item => recursiveFlattenArray(more(item), more))];
};
