import { gapc_common, part_slot } from '@/sdk/reflect/reflect';
import { GapcPartType, GapcPosition, PartSelectionContexts, PartSlot, PartSlotIds } from '@sdk/lib';

export const createPartSlotIds = (partSlot?: PartSlot | null): PartSlotIds | null => {
	if (!partSlot) {
		return null;
	}

	return {
		gapcPartTypeId: partSlot.gapcPartType?.id,
		gapcPositionId: partSlot.gapcPosition?.id
	};
};
export const formatPartContext = (
	gapcPartType: GapcPartType | null,
	gapcPosition: GapcPosition | null,
	description: string | null,
	mpn: string | null
): string | null => {
	if (gapcPartType && gapcPosition) {
		return `${gapcPartType.name}, ${gapcPosition.name}`;
	}

	if (gapcPartType) {
		return `${gapcPartType.name}`;
	}

	if (description) {
		return description;
	}

	if (mpn) {
		return mpn;
	}

	return null;
};

export const formatSelectionContext = (context: PartSelectionContexts | null) => {
	const partNames = context
		?.map(c => formatPartContext(c.gapcPartType, c.gapcPosition, c.description, c.mpn))
		.filter(Boolean)
		.join(', ');

	if (!partNames) {
		return 'N/A';
	}

	return partNames;
};

export const formatSelectionContextReflect = (
	context: part_slot.exp.PartSelectionContexts | null
) => {
	const partNames = context
		?.map(c => formatPartContextReflect(c.gapc_part_type, c.gapc_position, c.description, c.mpn))
		.filter(Boolean)
		.join(', ');

	if (!partNames) {
		return 'N/A';
	}

	return partNames;
};

export const formatPartContextReflect = (
	gapcPartType: gapc_common.GapcPartType | null,
	gapcPosition: gapc_common.GapcCommonProperty | null,
	description: string | null,
	mpn: string | null
): string | null => {
	if (gapcPartType && gapcPosition) {
		return `${gapcPartType.name}, ${gapcPosition.name}`;
	}

	if (gapcPartType) {
		return `${gapcPartType.name}`;
	}

	if (description) {
		return description;
	}

	if (mpn) {
		return mpn;
	}

	return null;
};
