import { CollisionArea, CollisionHca, CollisionMap } from '../types';
import { Context } from '../utils/context';
import { isDefined } from '../utils/object';

export const createCollisionMap = (
	id: string | null | undefined,
	ctx: Context
): CollisionMap | null => {
	if (!id) {
		return null;
	}

	const collisionMap = ctx.resources.get({ path: 'collision_maps', id });
	if (!collisionMap) {
		return null;
	}

	const collisionAreas: CollisionArea[] = collisionMap.collision_area_ids
		.map(collisionAreaId => createCollisionArea(collisionAreaId, ctx))
		.filter(isDefined);

	return {
		id: collisionMap.id,
		description: collisionMap.description,
		imageUrl: collisionMap.image_url,
		collisionAreas
	};
};

export const createCollisionArea = (
	id: string | null | undefined,
	ctx: Context
): CollisionArea | null => {
	if (!id) {
		return null;
	}

	const collisionArea = ctx.resources.get({ path: 'collision_areas', id });

	if (!collisionArea) {
		return null;
	}

	const hcas = collisionArea.collision_hcas
		.map(collisionHcaId => createCollisionHca(collisionHcaId, ctx))
		.filter(isDefined);

	return {
		id: collisionArea.id,
		name: collisionArea.name,
		partSlotIds: collisionArea.part_slots.map(slot => ({
			gapcPositionId: slot.part_slot.gapc_position_id,
			gapcPartTypeId: slot.part_slot.gapc_part_type_id
		})),
		hcas
	};
};

export const createCollisionHca = (
	id: string | null | undefined,
	ctx: Context
): CollisionHca | null => {
	if (!id) {
		return null;
	}

	const collisionHca = ctx.resources.get({ path: 'collision_hcas', id });

	if (!collisionHca) {
		return null;
	}

	return {
		id: collisionHca.id,
		name: collisionHca.name,
		partSlotIds: collisionHca.part_slots.map(slot => ({
			gapcPartTypeId: slot.part_slot.gapc_part_type_id,
			gapcPositionId: slot.part_slot.gapc_position_id
		}))
	};
};
