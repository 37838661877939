import { useMeasurement } from '@/app/hooks/use-measure';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { isNil, values } from 'lodash-es';
import { SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { DEFAULT_DISTANCE_Z, DISTANCE_Z_STEP } from '../../constants';
import { useSearchValue } from '../../hooks/use-search-value';
import {
	CategoryTree,
	CategoryTreeLeaf,
	Diagram,
	DiagramPartSlot,
	PartsFormData,
	PartsSelection
} from '../../types';
import { PartsDisplay } from '../parts-display';
import {
	PartsDiagramNavigation,
	PartsDiagramSuspenseRenderer,
	PartsDiagramToolbar
} from './subcomponents';

type PartsDiagramProps = InheritableElementProps<
	'div',
	{
		categories: CategoryTree[];
		other?: CategoryTreeLeaf | null;
		category?: CategoryTreeLeaf | null;
		diagram?: Diagram | null;
		partSlot?: DiagramPartSlot | null;
		highlighted?: string | null;
		control: Control<PartsFormData>;
		selection: PartsFormData;
		navigating?: boolean;
		actions: {
			view: {
				set: (action: SetStateAction<boolean>) => void;
			};
			part: {
				highlight: (partSlotId: string) => void;
			};
			custom: {
				add: () => void;
			};
			category: {
				set: (category: CategoryTreeLeaf) => void;
			};
			diagram: {
				set: (diagram: Diagram) => void;
			};
		};
	}
>;

export const PartsDiagram = ({
	className,
	categories,
	other,
	category,
	diagram,
	partSlot,
	highlighted,
	control,
	selection,
	navigating,
	actions,
	style,
	...rest
}: PartsDiagramProps) => {
	const q = useSearchValue();
	const { value: nav } = useMeasurement('navigation-bar');
	const [hide, setHide] = useState(false);
	const [distanceZ, setDistanceZ] = useState(DEFAULT_DISTANCE_Z);
	const controls = useRef<OrbitControlsImpl | null>(null);

	const zoom = useMemo(() => Math.round((2 - distanceZ / DEFAULT_DISTANCE_Z) * 100), [distanceZ]);

	useEffect(() => {
		if (!diagram) {
			return;
		}
		controls.current?.reset();
		setHide(false);
	}, [diagram?.id]);

	return (
		<div
			className={tlsx('relative flex-1 grid place-items-center w-full', className)}
			style={style}
			{...rest}
		>
			{!isNil(category) ? (
				<PartsDiagramSuspenseRenderer
					className={className}
					diagram={diagram}
					hightlighted={highlighted}
					selection={selection}
					hide={hide}
					actions={{
						part: {
							highlight: actions.part.highlight
						},
						controls: {
							start: orbitControls => {
								setDistanceZ(orbitControls.object.position.z);
								controls.current = orbitControls;
							},
							change: camera => {
								setDistanceZ(camera.position.z);
							}
						}
					}}
				/>
			) : (
				<div className="grid place-items-center w-full h-full bg-[#FCFDFD]">
					<span className="text-gray-600 text-sm font-medium">
						{q
							? categories.length > 0 || !isNil(other)
								? `Select an assembly from the filtered list for "${q}" to view parts on their diagrams`
								: `No results for "${q}"`
							: 'Select an assembly from the list to view parts on their diagrams'}
					</span>
				</div>
			)}

			{partSlot && (
				<div
					className="absolute z-10 top-4 right-4 flex flex-col gap-2.5 w-[26rem] overflow-auto"
					style={{
						maxHeight: `calc(100dvh - ${nav?.height}px - 2.5rem)`
					}}
				>
					{partSlot.assemblies.map(assembly => (
						<Controller
							key={assembly.id}
							control={control}
							name={assembly.id}
							render={({ field }) => {
								const checked =
									(selection[assembly.id]?.quantity ?? field?.value?.quantity ?? 0) > 0;
								return (
									<PartsDisplay
										className="w-full"
										assembly={assembly}
										checkbox={{
											checked: checked,
											onChange: e => {
												if (!assembly) {
													return;
												}
												const data: PartsSelection = {
													mpn: assembly.part.mpn,
													gapcBrandId: assembly.part.gapcBrandId,
													partSlotIds: assembly.partSlotIds,
													quantity: e.target.checked ? 1 : 0,
													assemblyId: assembly.id,
													description: assembly.description,
													hcas: assembly.hcas,
													order: values(selection).filter(s => (s?.quantity ?? 0) > 0).length
												};
												field.onChange(data);
											}
										}}
										fitment={partSlot.assemblies.length > 1}
										onClick={() => actions.part.highlight(partSlot.id)}
									/>
								);
							}}
						/>
					))}
				</div>
			)}

			{!isNil(category) && !isNil(diagram) && (
				<PartsDiagramToolbar
					category={category}
					diagram={diagram}
					zoom={zoom}
					actions={{
						diagram: { set: actions.diagram.set },
						zoom: {
							in: () => controls.current?.object.position.setZ(distanceZ - DISTANCE_Z_STEP),
							out: () => controls.current?.object.position.setZ(distanceZ + DISTANCE_Z_STEP)
						},
						hide: setHide,
						menu: {
							custom: actions.custom,
							diagram: {
								recenter: () => {
									if (!controls.current) {
										return;
									}
									// maintain zoom
									const { z } = controls.current.object.position;
									controls.current.reset();
									controls.current?.object.position.setZ(z);
								},
								resetZoom: () => {
									controls.current?.object.position.setZ(DEFAULT_DISTANCE_Z);
								},
								resetAll: () => controls.current?.reset()
							}
						}
					}}
				/>
			)}

			<PartsDiagramNavigation
				categories={categories}
				other={other}
				selected={category}
				diagram={diagram}
				selection={selection}
				open={!!navigating}
				actions={{
					view: actions.view,
					category: actions.category,
					diagram: actions.diagram
				}}
			/>
		</div>
	);
};
