import StandardDialog, { StandardDialogProps } from '@/app/organisms/standard-dialog';
import { order } from '@/sdk/reflect/reflect';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, NumberInput, Select, Textarea } from '@mantine/core';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { OrderTableItem } from '../../table-builder';

const reasons: { value: order.OrderItemReturnReason; label: string }[] = [
	{ value: 'damaged', label: 'Damaged' },
	{ value: 'incorrectly_supplied', label: 'Incorrectly supplied' },
	{ value: 'incorrect_fitment', label: "Doesn't fit" },
	{ value: 'not_required', label: 'No longer required' },
	{ value: 'short_supplied', label: 'Short supplied' },
	{ value: 'other', label: 'Other...' }
];

const schema = yup.object({
	reason: yup.string().required().label('Reason'),
	quantity: yup.number().required().label('Quantity'),
	details: yup.string().required().label('Details')
});

export type RejectItemDialogData = yup.InferType<typeof schema>;

type AcceptItemDialogProps = StandardDialogProps & {
	item: OrderTableItem | null; // todo: decouple
	onSubmit: (data: RejectItemDialogData) => void;
};

const defaultValues = {
	reason: 'damaged',
	quantity: 1,
	details: ''
};

export const RejectItemDialog = ({
	open,
	item,
	onSubmit,
	onClose,
	...rest
}: AcceptItemDialogProps) => {
	const form = useForm({
		defaultValues,
		resolver: yupResolver(schema)
	});

	useEffect(() => {
		if (typeof item?.status === 'object' && 'returned' in item.status) {
			form.reset({
				reason: item.status.returned.reason,
				quantity: item.status.returned.quantity,
				details: item.status.returned.details
			});

			return;
		}

		form.reset({ ...defaultValues, quantity: item?.quantity ?? 1 });
	}, [item]);

	return (
		<StandardDialog open={open} onClose={onClose} {...rest}>
			<StandardDialog.Title>Return item</StandardDialog.Title>
			<StandardDialog.Content>
				<form noValidate onSubmit={form.handleSubmit(onSubmit)}>
					<div className="space-y-2">
						<Controller
							control={form.control}
							name="reason"
							render={({ field, fieldState }) => (
								<Select
									withinPortal
									label="Reason"
									className="w-full"
									required
									error={fieldState?.error?.message}
									data={reasons}
									{...field}
								/>
							)}
						/>
						<Controller
							control={form.control}
							name="quantity"
							render={({ field, fieldState }) => (
								<NumberInput
									label="Quantity"
									className="w-full"
									max={item?.quantity ?? 1}
									min={1}
									required
									error={fieldState?.error?.message}
									{...field}
								/>
							)}
						/>
						<Controller
							control={form.control}
							name="details"
							render={({ field, fieldState }) => (
								<Textarea
									label="Details"
									className="w-full"
									required
									error={fieldState?.error?.message}
									{...field}
								/>
							)}
						/>
					</div>
					<div className="flex items-center justify-end gap-2 mt-6">
						<Button variant="default" onClick={onClose}>
							Cancel
						</Button>
						<Button type="submit">Submit</Button>
					</div>
				</form>
			</StandardDialog.Content>
		</StandardDialog>
	);
};
