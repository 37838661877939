import { PartAssemblyRaw, PartSubAssemblyRaw } from '@/sdk/generated';
import { PartAssemblyLink } from '@sdk/generated/models/PartAssemblyLink';
import {
	GapcDiagram,
	PartAssembliesTree,
	PartAssembly,
	PartAssemblyInfo,
	PartSlot,
	PartSubAssembly,
	PartSupplySummary
} from '../types';
import { Context } from '../utils/context';
import { isDefined } from '../utils/object';
import { decodeGapcPartIdentityKey, ResourcePath } from '../utils/resources';
import { createShippingTime } from './cart';
import { createGapcBrand, createGapcDiagrams, createPartSlot } from './gapc';
import { createListingPricing, createListings } from './listings';

type PartAssembliesTreeRaw = {
	assemblies: PartAssemblyLink[];
};

export const createPartAssembly = (assembly: PartAssemblyRaw, ctx: Context): PartAssembly => {
	const partSlot = assembly.part_slot ? createPartSlot(assembly.part_slot, ctx) : null;
	const part = assembly.part_identity ? createPartAssemblyInfo(assembly.part_identity, ctx) : null;
	const diagrams = assembly.diagrams ? createGapcDiagrams(assembly.diagrams, ctx) : null;

	const subAssemblies = createPartSubAssemblies(assembly.sub_assemblies, ctx);

	const description = createPartAssemblyDescription(
		assembly.description ?? null,
		part,
		diagrams,
		partSlot
	);

	const supply = createPartAssemblySupply(assembly, ctx);

	return {
		id: assembly.id,
		description,
		partSlot,

		part,
		diagrams,
		assemblyType: assembly.assembly_type,
		subAssemblies,

		attributes: assembly.attributes,
		fitment: assembly.fitment,
		confidence: assembly.confidence,
		quantityRequired: assembly.quantity_required,
		supply
	};
};

export const createPartAssemblies = (items: string[], ctx: Context): PartAssembly[] => {
	return items
		.map(key => {
			const path = ResourcePath.create<'part_assemblies'>(key);
			if (!path) {
				return null;
			}

			const value = ctx.resources.get(path);
			if (!value) {
				return null;
			}

			return createPartAssembly(value, ctx);
		})
		.filter(isDefined);
};

export const createPartAssembliesTree = (
	tree: PartAssembliesTreeRaw,
	ctx: Context
): PartAssembliesTree => {
	return {
		assemblies: createPartAssemblies(tree.assemblies, ctx)
	};
};

export const createPartSubAssemblies = (
	subAssemblies: PartSubAssemblyRaw[],
	ctx: Context
): PartSubAssembly[] => {
	return subAssemblies
		.map((sub_assembly): PartSubAssembly | null => {
			if (typeof sub_assembly === 'string') {
				const path = ResourcePath.create<'part_assemblies'>(sub_assembly);
				if (!path) {
					return null;
				}

				const value = ctx.resources.get(path);
				if (!value) {
					return null;
				}
				const assembly = createPartAssembly(value, ctx);

				return {
					kind: 'single',
					assembly
				};
			}
			const variants = sub_assembly.variants
				.map(key => {
					const path = ResourcePath.create<'part_assemblies'>(key);
					if (!path) {
						return null;
					}

					const value = ctx.resources.get(path);
					if (!value) {
						return null;
					}
					return createPartAssembly(value, ctx);
				})
				.filter(isDefined);
			return {
				kind: 'variants',
				variants
			};
		})
		.filter(isDefined);
};

export const createPartAssemblyInfo = (partIdentity: string, ctx: Context): PartAssemblyInfo => {
	const { gapcBrandId, mpn } = decodeGapcPartIdentityKey(partIdentity);
	const brand = createGapcBrand(gapcBrandId, ctx);
	return {
		gapcBrandId,
		mpn,
		partIdentity,
		brand
	};
};

export const createPartAssemblySupply = (
	assembly: PartAssemblyRaw,
	ctx: Context
): PartSupplySummary | null => {
	if (!assembly.supply) {
		return null;
	}
	const price = assembly.supply.price ? createListingPricing(assembly.supply.price) : null;
	const shipping = assembly.supply.shipping ? createShippingTime(assembly.supply.shipping) : null;
	const listings = createListings(assembly.supply.listings, ctx);
	return {
		availability: assembly.supply.availability,
		grades: assembly.supply.grades,
		images: assembly.supply.images,
		listingTypes: assembly.supply.listing_types,
		partNumbers: assembly.supply.part_numbers,
		listings,
		price,
		shipping
	};
};

export const createPartAssemblyDescription = (
	description: string | null,
	part: PartAssemblyInfo | null,
	diagrams: GapcDiagram[] | null,
	partSlot: PartSlot | null
) => {
	const assemblyParts =
		diagrams
			?.flatMap(diagram => diagram.partSlots)
			?.flatMap(partSlot => partSlot.parts)
			?.filter(assemPart => part?.partIdentity == assemPart.partIdentity) ?? [];

	if (description) {
		return description;
	}

	const partSlotDescription = [partSlot?.gapcPartType?.name, partSlot?.gapcPosition?.name]
		.filter(isDefined)
		.filter(each => each !== 'N/A')
		.join(', ');

	if (partSlotDescription) {
		return partSlotDescription;
	}

	const assemblyPartsDescription = assemblyParts
		.map(p => p.description)
		.filter(isDefined)
		.filter(d => d.length > 0)
		.filter(each => each !== 'N/A')
		.at(0);
	if (assemblyPartsDescription) {
		return assemblyPartsDescription;
	}

	return 'Hardware part';
};
