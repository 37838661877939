import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { GapcAssemblyPartAttribute, GapcAssemblyPartFitment } from '@sdk/lib';

type FitmentInfoProps = InheritableElementProps<
	'div',
	{
		fitment?: GapcAssemblyPartFitment[];
		attributes?: GapcAssemblyPartAttribute[];
	}
>;

const FitmentInfo = ({ className, fitment, attributes, ...rest }: FitmentInfoProps) => {
	if (!fitment?.length && !attributes?.length) {
		return null;
	}

	return (
		<div className={tlsx('border rounded space-y-2 p-4', className)} {...rest}>
			{!!fitment?.length && (
				<div>
					<h3 className="text-xs font-medium text-gray-500">Fits</h3>
					{fitment?.map((fitment, i) => (
						<div key={fitment.type + i} className="flex gap-1">
							<dt className="flex-shrink-0 text-xs text-gray-500">{fitment.type}:</dt>
							<dd className="text-xs text-gray-500">{fitment.value}</dd>
						</div>
					))}
				</div>
			)}
			{!!attributes?.length && (
				<div>
					<h3 className="text-xs font-medium text-gray-500">About</h3>
					{attributes?.map((attr, i) => (
						<div key={attr.type + i} className="flex gap-1">
							<dt className="flex-shrink-0 text-xs text-gray-500">{attr.type}:</dt>
							<dd className="text-xs text-gray-500">{attr.value}</dd>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default FitmentInfo;
