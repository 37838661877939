/**
 * Extract 'key' from value
 * @example getKey("listing/123") => "123"
 * @example getKey("uvdb_property/456") => "456"
 * @example getKey("789") => "789"
 */
export const getId = (value: string): string | null => {
	const parts = value.split('/');
	const id = parts[parts.length - 1];
	if (!id) {
		return null;
	}

	return id;
};

/**
 * Extract 'key' from value or throw error
 *
 * @example getKey("listing/123") => "123"
 * @example getKey("uvdb_property/456") => "456"
 * @example getKey("789") => "789"
 */
export const getIdOrThrow = (value: string): string => {
	const id = getId(value);
	if (!id) {
		throw new Error('Unable to extract id');
	}

	return id;
};

export const getValueFromId = <T extends string | number, K>(
	values: Record<T, K> | null | undefined,
	key: T | null | undefined
): K | null => {
	if (!key) {
		return null;
	}

	return values?.[key] ?? null;
};

export const getValueFromIdOrThrow = <T extends string | number, K>(
	values: Record<T, K> | null | undefined,
	key: T | null | undefined
): K => {
	const value = getValueFromId(values, key);
	if (!value) {
		throw new Error('Unable to extract value');
	}

	return value;
};
