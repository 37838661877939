import { Badge } from '@/app/atoms/badge';
import { BadgeProps, BadgeVariant } from '@/app/atoms/badge/types';
import { draft_order } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { useMemo } from 'react';

type StatusInfo = {
	variant: BadgeVariant;
	text: string;
	icon?: React.ComponentType;
};

type OrderRequestItemStatusBadgeProps = Omit<BadgeProps, 'variant' | 'children'> & {
	status: draft_order.DraftOrderItemStatus;
};

export const OrderRequestItemStatusBadge = ({
	status,
	...rest
}: OrderRequestItemStatusBadgeProps) => {
	const info = useMemo<StatusInfo>(
		() =>
			match(status, {
				Approved: ({ reason }) => ({ variant: 'green', text: getApprovedReasonText(reason) }),
				Pending: () => ({ variant: 'neutral', text: 'Pending' }),
				Rejected: ({ reason }) => ({
					variant: 'red',
					text: getRejectedReasonText(reason),
					icon: ExclamationTriangleIcon
				})
			}),
		[status]
	);

	return (
		<Badge variant={info.variant} {...rest}>
			{info.icon && <Badge.LeadingIcon as={info.icon} />}
			{info.text}
		</Badge>
	);
};

const getApprovedReasonText = (reason: draft_order.DraftOrderItemApprovedReason): string => {
	return match(reason, {
		Estimator: () => 'Approved',
		Supplier(arg) {
			if (!arg) {
				return 'Approved';
			}

			return match(arg, {
				Replacement: () => 'Replacement'
			});
		}
	});
};

const getRejectedReasonText = (reason: draft_order.DraftOrderItemRejectedReason): string => {
	return match(reason, {
		Estimator(arg) {
			return match(arg, {
				NotWanted: () => 'Not wanted'
			});
		},
		Supplier(arg) {
			return match(arg, {
				IncorrectFitment: () => 'Incorrect fitment',
				NotNeeded: () => 'Not needed',
				NoSupply: () => 'No supply',
				OutOfStock: () => 'Out of stock'
			});
		}
	});
};
