import {
	CollisionAreaRaw,
	CollisionHcaRaw,
	CollisionMapRaw,
	GapcAssemblyRaw,
	GapcDiagramRaw,
	GapcPartRaw,
	JobRaw,
	JobTypeRaw,
	ListingRaw,
	PartAssemblyRaw,
	SupplyLocationRaw,
	SupplyVendorRaw,
	WithI18n_for_GapcPropertyRaw,
	WithI18n_for_UvdbPropertyRaw
} from '@/sdk/generated';
import { GapcPartIdentity } from '@sdk/lib/types';

export type ResouceMapKey = keyof IResourceMap;

export type IResourceMap = {
	collision_hcas?: Record<string, CollisionHcaRaw> | null;
	collision_areas?: Record<string, CollisionAreaRaw> | null;
	collision_maps?: Record<string, CollisionMapRaw> | null;
	jobs?: Record<string, JobRaw> | null;
	job_types?: Record<string, JobTypeRaw> | null;
	listings?: Record<string, ListingRaw> | null;
	vendors?: Record<string, SupplyVendorRaw> | null;
	locations?: Record<string, SupplyLocationRaw> | null;
	gapc_parts?: Record<string, GapcPartRaw> | null;
	gapc_assemblies?: Record<string, GapcAssemblyRaw> | null;
	gapc_diagrams?: Record<string, GapcDiagramRaw> | null;
	gapc_properties?: Record<string, WithI18n_for_GapcPropertyRaw> | null;
	uvdb_properties?: Record<string, WithI18n_for_UvdbPropertyRaw> | null;
	part_assemblies?: Record<string, PartAssemblyRaw> | null;
};

type ExtractRecordValue<T> = T extends Record<string, infer U> ? U : never;

type ResourceTypeMap = NoUndefinedField<{
	[K in keyof IResourceMap]: ExtractRecordValue<IResourceMap[K]>;
}>;

type NoUndefinedField<T> = { [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>> };

type IResourcePath = {
	path: ResouceMapKey;
	id: string;
};

function normalizeMPN(mpn: string): string {
	const removeLeadingZeros = /^0+/;
	const removeSpecialChars = /[-\s"'()+]/gi;

	let normalizedMpn = mpn.trim();
	normalizedMpn = normalizedMpn.replace(removeLeadingZeros, '');
	normalizedMpn = normalizedMpn.replace(removeSpecialChars, '');

	return normalizedMpn.toUpperCase();
}

export class ResourceMap {
	private constructor(private props: IResourceMap) {}

	public get<K extends ResouceMapKey>(
		resourcePath: ResourcePath<K> | { path: ResouceMapKey; id: string }
	): ResourceTypeMap[K] | null {
		const resource = this.props[resourcePath.path];
		if (!resource) {
			return null;
		}

		const item = resource[resourcePath.id] as ResourceTypeMap[K];
		if (!item) {
			return null;
		}

		return item;
	}

	public static create(resourceMap: IResourceMap): ResourceMap {
		return new ResourceMap(resourceMap);
	}
}

export class ResourcePath<K extends ResouceMapKey> {
	private constructor(private props: IResourcePath) {}

	public get path(): K {
		return this.props.path as K;
	}

	public get id(): string {
		return this.props.id;
	}

	public static create<K extends keyof IResourceMap>(resourcePath: string): ResourcePath<K> | null {
		const [path, id] = resourcePath.split('/');

		if (!path) {
			return null;
		}

		if (!id) {
			return null;
		}

		return new ResourcePath<K>({
			path: path as K,
			id
		});
	}
}

export function encodeGapcPartIdentityKey(val: GapcPartIdentity): string {
	return `(${val.gapcBrandId},${normalizeMPN(val.mpn)})`;
}

export function decodeGapcPartIdentityKey(val: string): GapcPartIdentity {
	const [gapcBrandId, mpn] = val.slice(1, -1).split(',');
	return {
		gapcBrandId,
		mpn
	};
}
