import { createContext, useContext } from 'react';
import { VehicleSelectionContext as VehicleSelectionContextType } from './types';

const VehicleSelectionContext = createContext<VehicleSelectionContextType>(
	{} as VehicleSelectionContextType
);

export const useVehicleSelector = () => useContext(VehicleSelectionContext);

export const VehicleSelectorProvider = VehicleSelectionContext.Provider;
