import {
	AuthCredentials,
	BasicAuthChangePassword,
	JWTResult,
	StorefrontError,
	UserSuccessResult
} from '@sdk/lib';
import { getApi, unwrap } from '../utils/helpers';
import { mutationOptions } from '../utils/mutation-options';

export const userMutations = {
	login: mutationOptions<JWTResult, StorefrontError, AuthCredentials>({
		mutationKey: ['user', 'login'],
		mutationFn: payload => unwrap(() => getApi().user.login(payload))
	}),
	passwordUpdate: mutationOptions<UserSuccessResult, StorefrontError, BasicAuthChangePassword>({
		mutationKey: ['user', 'passwordUpdate'],
		mutationFn: payload => unwrap(() => getApi().user.passwordUpdate(payload))
	})
};
