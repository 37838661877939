import Logo from '@assets/logo.svg';
import { Button } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';

const ErrorState = () => {
	const navigate = useNavigate();
	return (
		<div className="flex flex-col justify-center items-center flex-1 min-h-full py-12 px-4  bg-gray-50">
			<div className="flex flex-col justify-center items-center gap-4 max-w-md">
				<img className="w-auto h-10 mx-auto mb-3" src={Logo} alt="Your Company" />
				<h1 className="text-2xl font-semibold text-gray-700">Sorry, something went wrong</h1>
				<p className="text-gray-500 text-lg text-center">
					Refresh the page to try again, if the problem still persists, contact us.
				</p>
				<Button className="my-2" onClick={() => navigate(0)}>
					Try Again
				</Button>
				<Button component={Link} variant="subtle" to="/jobs">
					Return Home
				</Button>
			</div>
		</div>
	);
};

export default ErrorState;
