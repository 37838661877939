import { useRemoveJob } from '@/app/features/jobs/hooks/use-remove-job';
import StandardDialog from '@/app/organisms/standard-dialog';
import { Button } from '@mantine/core';
import type { Job } from '@sdk/lib';
import { isNil } from 'lodash-es';

type DeleteJobDialogProps = {
	siteId: string;
	job?: Job;
	onClose: () => void;
};

const DeleteJobDialog = ({ siteId: sideId, job, onClose }: DeleteJobDialogProps) => {
	const removeJob = useRemoveJob({
		siteId: sideId,
		onSettled: () => {
			onClose();
		}
	});

	const open = !isNil(job);

	const vehicle = job?.vehicle?.variant?.description;

	const onConfirm = () => {
		if (!job) {
			return;
		}
		removeJob({ jobId: job.id });
	};

	return (
		<StandardDialog open={open} onClose={onClose}>
			<StandardDialog.Title>Confirm delete?</StandardDialog.Title>
			<StandardDialog.Content>
				<p className="text-gray-500 text-sm mb-6">
					Are you sure you want to delete this job ({job?.jobNumber}){' '}
					{vehicle ? `for ${vehicle}` : 'without a vehicle'}? This action cannot be undone
				</p>
				<Button className="w-full" color="red" onClick={onConfirm}>
					Confirm
				</Button>
				<Button className="w-full mt-3" variant="outline" onClick={onClose}>
					Cancel
				</Button>
			</StandardDialog.Content>
		</StandardDialog>
	);
};

export default DeleteJobDialog;
