import { isEqual } from 'lodash-es';
import { useEffect, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

/**
 * React hook forms watch() function is optimised for
 * render, not dependency hooks. This hook stabiles
 * the value of the form and returns it.
 */
export const useWatchForm = <T extends FieldValues>(form: UseFormReturn<T>) => {
	const [selection, setSelection] = useState<T | undefined>(form.getValues());

	useEffect(() => {
		const { unsubscribe } = form.watch(value => {
			if (isEqual(value, selection)) {
				return;
			}

			setSelection(value as T);
		});
		return () => unsubscribe();
	}, [form.watch]);

	return selection;
};
