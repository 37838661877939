import { createContext, useContext } from 'react';
import { BadgeSize } from './types';

type BadgeContext = {
	size: BadgeSize;
};

export const BadgeContext = createContext<BadgeContext>({
	size: 'medium'
});

export const useBadge = () => useContext(BadgeContext);
