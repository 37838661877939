export type AnalyticsEventPayload = Record<string, unknown> & { method?: string };

export enum AnalyticsCategories {
	Other = 'Other'
}

export interface EventParams<
	T extends string = string,
	Payload extends AnalyticsEventPayload = Record<string, unknown>
> {
	// category
	c: AnalyticsCategories;
	// eventName, should align with firebase event
	e: T;
	// additional params used in firebase
	params?: Payload;
	// ga event label
	label?: string;
	// ga event value
	value?: number;
}
