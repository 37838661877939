import { InheritableElementProps } from '@/types/utilties';
import { Button } from '@mantine/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledPasswordInput } from '../controlled-password-input';
import { ControlledTextInput } from '../controlled-text-field';

const schema = yup.object({
	email: yup.string().email().required().label('Email'),
	password: yup.string().required().label('Password')
});

export type SignInFormData = yup.InferType<typeof schema>;

export type SignInFormProps = Omit<
	InheritableElementProps<
		'form',
		{
			onSubmit: (data: SignInFormData) => void;
		}
	>,
	'noValidate'
>;

export const SignInForm = ({ onSubmit, ...rest }: SignInFormProps) => {
	const { handleSubmit, control } = useForm<SignInFormData>({
		defaultValues: {
			email: '',
			password: ''
		},
		resolver: yupResolver(schema)
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate {...rest}>
			<div className="space-y-4">
				<ControlledTextInput
					control={control}
					name="email"
					label="Email address"
					type="email"
					placeholder="Email"
					required
				/>
				<ControlledPasswordInput
					control={control}
					name="password"
					label="Password"
					placeholder="Password"
					required
				/>
			</div>
			<Button className="w-full mt-8" type="submit">
				Sign In
			</Button>
		</form>
	);
};
