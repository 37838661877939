import { camelCaseIntoSentence } from '@/app/utils/string';
import { GapcAssemblyPartFitment } from '@sdk/lib';
import { entries } from 'lodash-es';
import { DiagramAssembly } from '../types';

const EXCLUDED = [
	'Required quantity',
	'Production month from',
	'Production month to',
	'Production year from',
	'Production year to'
];

export const diffFitments = (variants: DiagramAssembly[]): DiagramAssembly[] => {
	// check for differences in fitments and attributes
	const fitmentDifferences = diffNotes(variants.map(each => each.fitment ?? []));
	const attributeDifferences = diffNotes(variants.map(each => each.attributes ?? []));

	return variants.map(({ fitment, attributes, ...rest }) => {
		const newFitment = transformNotes(
			fitment?.filter(fitment => fitmentDifferences.has(noteKey(fitment)))
		);
		const newAttributes = transformNotes(
			attributes?.filter(attribute => attributeDifferences.has(noteKey(attribute)))
		);
		return {
			fitment: newFitment,
			attributes: newAttributes,
			...rest
		};
	});
};

const diffNotes = (fitments: GapcAssemblyPartFitment[][]) => {
	const check = new Map<string, number>();
	for (const fitment of fitments) {
		for (const { type, value } of fitment) {
			const key = noteKey({ type, value });
			check.set(key, (check.get(key) ?? 0) + 1);
		}
	}

	return new Set(
		[...entries(check)].filter(([, count]) => count !== fitments.length).map(([key]) => key)
	);
};

const noteKey = ({ type, value }: GapcAssemblyPartFitment) => `${type}:${value}`;

const transformNotes = (notes: GapcAssemblyPartFitment[] | null | undefined) =>
	notes
		?.map(({ type, ...rest }) => ({ type: camelCaseIntoSentence(type), ...rest }))
		?.filter(({ type }) => !EXCLUDED.includes(type));
