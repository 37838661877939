import { BmsService } from '@/sdk/generated';
import { createGetJob } from '../transformers/jobs';
import {
	ApiConfig,
	BmsSyncJobPartsRequest,
	BmsSyncJobPartsResponse,
	StorefrontResponse
} from '../types';
import { recursiveToSnake } from '../utils/casing';
import { execute } from '../utils/execute';

export const createBmsEndpoint = (bmsService: BmsService, config: ApiConfig) => {
	const syncJobParts = (
		payload: BmsSyncJobPartsRequest
	): Promise<StorefrontResponse<BmsSyncJobPartsResponse>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await bmsService.bmsSyncParts(snakePayload);
			return createGetJob(result, config);
		});

	return { syncJobParts };
};
