import { signIn } from '@/app/utils/auth';
import Logo from '@assets/logo.png';
import { withRedirectIfSignedIn } from '@hoc/with-access';
import { useAnalytics } from '@hooks/use-analytics';
import { Alert } from '@mantine/core';
import { SignInForm, SignInFormData } from '@molecules/sign-in-form';
import { ApiError } from '@sdk/lib';
import { userMutations, userQueries } from '@sdk/react';
import * as Sentry from '@sentry/browser';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AuthEvents } from '@utils/analytics/events.ts';

const SignInPage = () => {
	const analytics = useAnalytics();
	const queryClient = useQueryClient();
	const { mutateAsync: login, error } = useMutation({
		...userMutations.login,
		onSuccess: async result => {
			signIn(result.token);
			await queryClient.invalidateQueries({
				queryKey: userQueries.get().queryKey
			});
		}
	});

	const onSubmit = async (data: SignInFormData) => {
		try {
			await login({
				type: 'Basic',
				email: data.email,
				password: data.password
			});
			analytics.logEvent(AuthEvents.login({ email: data.email }));
		} catch (error) {
			if (error instanceof ApiError && error.status >= 400 && error.status < 500) {
				// Debug capture the expected errors for login endpoint
				Sentry.captureException(error, {
					level: 'debug'
				});
			} else {
				console.warn('Unexpected error', error);
				// Catch any unhandled error and send to Sentry
				Sentry.captureException(error);
			}
		}
	};

	return (
		<>
			<div className="flex flex-col justify-center items-center flex-1 min-h-full py-12 px-4 bg-gray-50">
				<div className="px-4 py-8 m-4 bg-white border rounded-md shadow w-full max-w-md">
					<div>
						<img className="w-44 h-auto mx-auto" src={Logo} alt="Your Company" />
						<h2 className="mt-6 text-2xl font-bold leading-9 tracking-tight text-center text-gray-900">
							Sign in to your account
						</h2>
					</div>
					<SignInForm className="mt-10" onSubmit={onSubmit} />
					{error instanceof Error && (
						<Alert color="red" className="mt-4" title="Sign in error">
							Invalid credentials
						</Alert>
					)}
				</div>
			</div>
		</>
	);
};

export default withRedirectIfSignedIn(SignInPage);
