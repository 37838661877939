import { BasicTable } from '@/app/molecules/order-table';
import { JobPart } from '@/sdk/lib';
import { InheritableElementProps } from '@/types/utilties';
import { Button } from '@mantine/core';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { AddItemPayload } from '../../models';
import { draftOrderColumnBuilder, OrderRequestTableItem } from '../../table-builder';
import { createJobPartName } from '../../supply';
import { AddExternalItemModal, AddExternalItemModalData } from '../add-external-item-modal';

type NoSupplyProps = InheritableElementProps<
	'section',
	{
		noSupply: JobPart[];
		onAddItem: (payload: AddItemPayload) => void;
	}
>;

const builder = draftOrderColumnBuilder();
const tableColumns = [
	builder.select(false),
	builder.quantity(),
	builder.name(),
	builder.grade(),
	builder.arrivalAt(),
	builder.price()
];

export const OrderRequestNoSupply = ({ noSupply, onAddItem }: NoSupplyProps) => {
	const [addDetails, setAddDetails] = useState(false);
	const [rowSelection, setRowSelection] = useState({});
	const items = useMemo<OrderRequestTableItem[]>(() => {
		return noSupply.map(item => {
			return {
				id: item.partIdentity,
				quantity: item.quantity,
				order_separately: false,
				grade: null,
				arrival_at: null,
				price: null,
				status: null,
				name: createJobPartName(item)
			};
		});
	}, [noSupply]);

	const table = useReactTable({
		data: items,
		columns: tableColumns,
		state: {
			rowSelection
		},
		enableRowSelection: true,
		enableMultiRowSelection: false,
		onRowSelectionChange: setRowSelection,
		getCoreRowModel: getCoreRowModel()
	});

	const targetIndex = Number(Object.keys(rowSelection)[0]);
	const hasSelection = !isNaN(targetIndex);
	const targetItem = noSupply[targetIndex];

	const defaultValues = useMemo<Partial<AddExternalItemModalData>>(() => {
		if (!targetItem) {
			return {};
		}

		return {
			quantity: targetItem.quantity,
			name: createJobPartName(targetItem),
			mpn: targetItem.mpn,
			brand: targetItem.gapcBrand?.name
		};
	}, [targetItem]);

	if (items.length === 0) {
		return null;
	}

	const onSubmit = (payload: AddItemPayload) => {
		onAddItem(payload);
		setAddDetails(false);
		setRowSelection({});
	};

	return (
		<section className="p-6 border rounded-md">
			<h2 className="text-lg font-semibold text-gray-900">No supply</h2>
			<div className="flex items-center justify-between mt-3">
				<div className="flex items-center gap-2">
					<Button
						variant="default"
						size="xs"
						disabled={!hasSelection}
						onClick={() => setAddDetails(true)}
					>
						Add supplier
					</Button>
				</div>
			</div>
			<BasicTable className="mt-6" table={table} />
			<AddExternalItemModal
				open={addDetails}
				onSubmit={onSubmit}
				onClose={() => setAddDetails(false)}
				defaultValues={defaultValues}
			/>
		</section>
	);
};
