import { InheritableElementProps } from '@/types/utilties';
import { Skeleton } from '@mantine/core';
import { useState } from 'react';

type ImageWithSkeletonProps = InheritableElementProps<
	'img',
	// eslint-disable-next-line @typescript-eslint/ban-types
	{}
>;

const ImageWithSkeleton = ({ onLoad, ...rest }: ImageWithSkeletonProps) => {
	const [loading, setLoading] = useState(true);

	return (
		<Skeleton className="w-fit h-fit" visible={loading}>
			<img
				onLoad={ev => {
					setLoading(false);
					onLoad?.(ev);
				}}
				{...rest}
			/>
		</Skeleton>
	);
};

export default ImageWithSkeleton;
