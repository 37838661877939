export const colors = {
	gray: {
		'50': '#f9fafb',
		'100': '#f3f4f6',
		'200': '#e5e7eb',
		'300': '#d1d5db',
		'400': '#9ca3af',
		'500': '#6b7280',
		'600': '#4b5563',
		'700': '#374151',
		'800': '#1f2937',
		'900': '#111827'
	},
	red: {
		'50': '#fef2f2',
		'100': '#fee2e2',
		'200': '#fecaca',
		'300': '#fca5a5',
		'400': '#f87171',
		'500': '#ef4444',
		'600': '#dc2626',
		'700': '#b91c1c',
		'800': '#991b1b',
		'900': '#7f1d1d'
	},
	orange: {
		'50': '#fff7ed',
		'100': '#ffedd5',
		'200': '#fed7aa',
		'300': '#fdba74',
		'400': '#fb923c',
		'500': '#f97316',
		'600': '#ea580c',
		'700': '#c2410c',
		'800': '#9a3412',
		'900': '#7c2d12'
	},
	yellow: {
		'50': '#fefce8',
		'100': '#fef9c3',
		'200': '#fef08a',
		'300': '#fde047',
		'400': '#facc15',
		'500': '#eab308',
		'600': '#ca8a04',
		'700': '#a16207',
		'800': '#854d0e',
		'900': '#713f12'
	},
	lime: {
		'50': '#f7fee7',
		'100': '#ecfccb',
		'200': '#d9f99d',
		'300': '#bef264',
		'400': '#a3e635',
		'500': '#84cc16',
		'600': '#65a30d',
		'700': '#4d7c0f',
		'800': '#3f6212',
		'900': '#365314'
	},
	green: {
		'50': '#f0fdf4',
		'100': '#dcfce7',
		'200': '#bbf7d0',
		'300': '#86efac',
		'400': '#4ade80',
		'500': '#22c55e',
		'600': '#16a34a',
		'700': '#15803d',
		'800': '#166534',
		'900': '#14532d'
	},
	teal: {
		'50': '#f0fdfa',
		'100': '#ccfbf1',
		'200': '#99f6e4',
		'300': '#5eead4',
		'400': '#2dd4bf',
		'500': '#14b8a6',
		'600': '#0d9488',
		'700': '#0f766e',
		'800': '#115e59',
		'900': '#134e4a'
	},
	cyan: {
		'50': '#ecfeff',
		'100': '#cffafe',
		'200': '#a5f3fc',
		'300': '#67e8f9',
		'400': '#22d3ee',
		'500': '#06b6d4',
		'600': '#0891b2',
		'700': '#0e7490',
		'800': '#155e75',
		'900': '#164e63'
	},
	blue: {
		'50': '#eff6ff',
		'100': '#dbeafe',
		'200': '#bfdbfe',
		'300': '#93c5fd',
		'400': '#60a5fa',
		'500': '#3b82f6',
		'600': '#2563eb',
		'700': '#1d4ed8',
		'800': '#1e40af',
		'900': '#1e3a8a'
	},
	indigo: {
		'50': '#eef2ff',
		'100': '#e0e7ff',
		'200': '#c7d2fe',
		'300': '#a5b4fc',
		'400': '#818cf8',
		'500': '#6366f1',
		'600': '#4f46e5',
		'700': '#4338ca',
		'800': '#3730a3',
		'900': '#312e81'
	},
	violet: {
		'50': '#f5f3ff',
		'100': '#ede9fe',
		'200': '#ddd6fe',
		'300': '#c4b5fd',
		'400': '#a78bfa',
		'500': '#8b5cf6',
		'600': '#7c3aed',
		'700': '#6d28d9',
		'800': '#5b21b6'
	},
	purple: {
		'50': '#faf5ff',
		'100': '#f3e8ff',
		'200': '#e9d5ff',
		'300': '#d8b4fe',
		'400': '#c084fc',
		'500': '#a855f7',
		'600': '#9333ea',
		'700': '#7e22ce',
		'800': '#6b21a8',
		'900': '#581c87'
	},
	pink: {
		'50': '#fdf2f8',
		'100': '#fce7f3',
		'200': '#fbcfe8',
		'300': '#f9a8d4',
		'400': '#f472b6',
		'500': '#ec4899',
		'600': '#db2777',
		'700': '#be185d',
		'800': '#9d174d',
		'900': '#831843'
	},
	rose: {
		'50': '#fff1f2',
		'100': '#ffe4e6',
		'200': '#fecdd3',
		'300': '#fda4af',
		'400': '#fb7185',
		'500': '#f43f5e',
		'600': '#e11d48',
		'700': '#be123c',
		'800': '#9f1239',
		'900': '#881337'
	}
};

export const radius = {
	none: '0px',
	sm: '0.125rem',
	DEFAULT: '0.25rem',
	md: '0.375rem',
	lg: '0.5rem',
	xl: '0.75rem',
	'2xl': '1rem',
	'3xl': '1.5rem',
	full: '9999px'
};

export const screens = {
	sm: '640px',
	md: '768px',
	lg: '1024px',
	xl: '1280px'
};

export const shadows = {
	sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
	DEFAULT: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
	md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
	lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
	xl: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
	'2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
	inner: 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
	none: 'none'
};
