import { RemoveJobRequest, RemoveJobResult } from '@sdk/lib';
import { jobsMutations, jobsQueries } from '@sdk/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAnalytics } from '@hooks/use-analytics';
import { RepairhubEvents } from '@utils/analytics/events.ts';

type UseRemoveJobArgs = {
	siteId: string;
	onSettled?: (
		data: RemoveJobResult | undefined,
		error: Error | null,
		variables: RemoveJobRequest
	) => unknown;
};

export const useRemoveJob = ({ siteId, onSettled }: UseRemoveJobArgs) => {
	const queryClient = useQueryClient();
	const { logEvent } = useAnalytics();

	const { mutate: removeJob } = useMutation({
		...jobsMutations.remove,
		onSuccess: (_data, variables) => {
			logEvent(RepairhubEvents.job_deleted({ job_id: variables.jobId }));
			queryClient.invalidateQueries({
				predicate: query => {
					const searchJobQueryKeys = jobsQueries.search({ repairerSiteIds: [siteId] }).queryKey;

					// ignore the last key / payload
					return searchJobQueryKeys
						.slice(0, searchJobQueryKeys.length - 1)
						.every((key, index) => query.queryKey[index] === key);
				}
			});
		},
		onSettled
	});

	return removeJob;
};
