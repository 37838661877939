import { EventParams } from '@/app/utils/analytics/types';
import { userQueries } from '@sdk/react';
import { useQuery } from '@tanstack/react-query';
import { mixpanel } from '@utils/analytics/mixpanel';
import { isNil } from 'lodash-es';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { useAuth } from '../use-auth';

declare global {
	interface Window {
		mixpanel?: ReturnType<typeof mixpanel>;
	}
}

type AnalyticsContext = {
	mixpanel: ReturnType<typeof mixpanel>;
};

const AnalyticsContext = createContext({} as AnalyticsContext);

export const AnalyticsProvider = ({
	children,
	token,
	apiHost
}: {
	children: ReactNode;
	token?: string;
	apiHost?: string;
}) => {
	const [instance, setInstance] = useState(window.mixpanel);
	const { isAuthenticated } = useAuth();
	const { data, isLoading } = useQuery(userQueries.get());

	useEffect(() => {
		if (!token) {
			return;
		}

		const mixpanelInstance = mixpanel(window, document);
		if (mixpanelInstance) {
			mixpanelInstance.init(token, { debug: false, api_host: apiHost });
		}
		setInstance(mixpanelInstance);
	}, [token]);

	useEffect(() => {
		if (isNil(instance) || isLoading) {
			return;
		}

		// logged in (not logged out yet, has user data, and user is not guest)
		if (isAuthenticated && !isNil(data?.user) && 'account' in data.user) {
			const mixpanelId = instance.getProperty('$user_id');
			// No need to ID a user that's already ID'd
			if (!isNil(mixpanelId)) {
				return;
			}

			const { account } = data.user;
			const values = {
				first_name: account.firstName,
				last_name: account.lastName,
				$email: account.email
			};

			instance.identify(account.id);
			instance.register(values);
			instance.people.set(values);
		} else {
			instance.reset();
		}
	}, [instance, isAuthenticated, isLoading, data?.user]);

	useEffect(() => {
		if (isLoading) {
			return;
		}

		// logged in (not logged out yet, has user data, and user is not guest)
		if (isAuthenticated && !isNil(data?.user) && 'account' in data.user) {
			const { account } = data.user;
			// Set the Sentry user context
			Sentry.setUser({
				id: account.id,
				email: account.email,
				username: [account.firstName ?? '', account.lastName ?? ''].join(' ')
			});

			// Disable Sentry session recording for Partly special accounts
			const emailsToNotRecord = ['robouser@partly.com'];
			if (account.email.endsWith('@partly.com') && emailsToNotRecord.includes(account.email)) {
				const replay = Sentry.getReplay();
				replay?.stop().catch(() => {
					console.log('Error disabling replay');
				});
			}
		} else {
			Sentry.setUser(null);
		}
	}, [isAuthenticated, isLoading, data?.user]);

	return (
		<AnalyticsContext.Provider value={{ mixpanel: instance }}>{children}</AnalyticsContext.Provider>
	);
};

export const useAnalytics = () => {
	const { mixpanel } = useContext(AnalyticsContext);

	const logEvent = ({ params, e, c }: EventParams) => {
		if (isNil(params) || isNil(e)) {
			return;
		}

		mixpanel?.track(e, { ...params, host: window.location?.host });
		// Send event to Sentry as a breadcrumb, so if this transaction is sampled we can see the event
		Sentry.addBreadcrumb({
			category: c,
			message: e,
			data: params,
			level: 'info'
		});
	};

	return {
		logEvent
	};
};
