import { getEnvironment } from '@/environment';
import { AppError } from '@atoms/app-error';
import { createAuthProvider } from '@hooks/use-auth';
import MeasureProvider from '@hooks/use-measure';
import { MantineProvider } from '@mantine/core';
import { createClient } from '@sdk/react';
import * as Sentry from '@sentry/react';
import { MutationCache, QueryCache, QueryClientProvider } from '@tanstack/react-query';
import { mantineCache, mantineTheme } from '@utils/theme';
import { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Toaster } from 'sonner';
import { PageLoader } from './atoms/page-loader';
import { AnalyticsProvider } from './hooks/use-analytics';
import { routes } from './routes';
import { authBus, onAuthChange } from './utils/auth';
import { onError, shouldRetry } from './utils/error';

const AuthProvider = lazy(createAuthProvider);

const { mixpanelKey } = getEnvironment();
const createBrowserRouterWithSentry = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = createBrowserRouterWithSentry(routes);
const storefrontClient = createClient({
	queryCache: new QueryCache({
		onError
	}),
	mutationCache: new MutationCache({
		onError
	}),
	defaultOptions: {
		queries: {
			retry: shouldRetry
		}
	}
});

authBus.subscribe(onAuthChange, state => {
	// Ensure when the user logs out, we clear the cache
	if (!state.payload.isAuthenticated) {
		storefrontClient.client.clear();
	}
});

const App = () => {
	return (
		<MantineProvider emotionCache={mantineCache} theme={mantineTheme}>
			<ErrorBoundary FallbackComponent={AppError}>
				<Suspense fallback={<PageLoader />}>
					<AuthProvider>
						<QueryClientProvider client={storefrontClient.client}>
							<MeasureProvider>
								<AnalyticsProvider apiHost="https://mixpanel.partly.com" token={mixpanelKey}>
									<RouterProvider router={router} />
									<Toaster />
								</AnalyticsProvider>
							</MeasureProvider>
						</QueryClientProvider>
					</AuthProvider>
				</Suspense>
			</ErrorBoundary>
		</MantineProvider>
	);
};

export default App;
