import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { Skeleton } from '@mantine/core';

type JobTableSkeletonProps = InheritableElementProps<'table', object>;

const JobTableSkeleton = ({ className, ...rest }: JobTableSkeletonProps) => {
	return (
		<table className={tlsx('flex-1 w-full', className)} {...rest}>
			<thead className="grid sticky top-0 z-10 w-full bg-white">
				<tr className="w-full flex items-center justify-between py-3 px-10 bg-white">
					<th className="flex items-center w-1/4 text-sm text-gray-600 font-semibold">Vehicle</th>
					<th className="flex items-center w-[12.5%] text-sm text-gray-600 font-semibold">Job</th>
					<th className="flex items-center w-[8.33%] text-sm text-gray-600 font-semibold">
						Status
					</th>
					<th className="flex items-center w-[6.25%] text-sm ml-10 text-gray-600 font-semibold text-end">
						Collision
					</th>
					<th className="flex items-center justify-end w-[6.25%] text-sm text-gray-600 font-semibold text-end">
						Parts
					</th>
					<th className="flex items-center justify-end w-[6.25%] text-sm text-gray-600 font-semibold text-end">
						Order
					</th>
					<th className="w-[6.25%]" />
				</tr>
			</thead>
			<tbody className="grid relative w-full">
				{Array.from({ length: 3 }).map((_, index) => (
					<tr
						key={index}
						className="border-b border-gray-200 w-full min-h-[6rem] flex items-center justify-between py-6 px-10 bg-white"
					>
						<td className="w-1/4 text-sm text-gray-600 space-y-1 font-semibold">
							<Skeleton className="w-[14ch] h-6" />
							<Skeleton className="w-[10ch] h-5" />
						</td>
						<td className="w-[12.5%] space-y-1">
							<Skeleton className="w-[10ch] h-6" />
							<Skeleton className="w-[8ch] h-5" />
						</td>
						<td className="w-[8.33%]">
							<Skeleton className="w-[6ch] h-5" />
						</td>
						<td className="w-[6.25%] ml-10 flex items-center justify-end">
							<Skeleton className="w-[2ch] h-5" />
						</td>
						<td className="w-[6.25%] flex items-center justify-end">
							<Skeleton className="w-[2ch] h-5" />
						</td>
						<td className="w-[6.25%] flex items-center justify-end">
							<Skeleton className="w-[2ch] h-5" />
						</td>
						<td className="w-[6.25%] flex items-center justify-center">
							<Skeleton className="w-[2ch] h-5" />
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default JobTableSkeleton;
