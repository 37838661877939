import { StorefrontError, UserResult } from '@sdk/lib';
import { queryOptions } from '@tanstack/react-query';
import { getApi, unwrap } from '../utils/helpers';

export const userQueries = {
	get: (payload: object = {}) =>
		queryOptions<UserResult, StorefrontError>({
			queryKey: ['user', 'get', payload],
			queryFn: () => unwrap(() => getApi().user.get())
		})
};
