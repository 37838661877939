import {
	GetJobCollisionMapRequest,
	GetJobCollisionMapResult,
	GetJobPartAssembliesTreeRequest,
	GetJobPartAssembliesTreeResult,
	GetJobRequest,
	GetJobResult,
	GetJobSupplyRecommendationsRequest,
	GetJobSupplyRecommendationsResult,
	ListJobPartsRequest,
	ListJobPartsResult,
	SearchJobsRequest,
	SearchJobsResult,
	StorefrontError
} from '@sdk/lib';
import { queryOptions } from '@tanstack/react-query';
import { getApi, unwrap } from '../utils/helpers';

export const jobsQueries = {
	get: (payload: GetJobRequest) =>
		queryOptions<GetJobResult, StorefrontError>({
			queryKey: ['jobs', 'get', payload],
			queryFn: () => unwrap(() => getApi().jobs.get(payload))
		}),
	getCollisionMap: (payload: GetJobCollisionMapRequest) =>
		queryOptions<GetJobCollisionMapResult, StorefrontError>({
			queryKey: ['jobs', 'getCollisionMap', payload],
			queryFn: () => unwrap(() => getApi().jobs.getCollisionMap(payload))
		}),
	getPartAssembliesTree: (payload: GetJobPartAssembliesTreeRequest) =>
		queryOptions<GetJobPartAssembliesTreeResult, StorefrontError>({
			queryKey: ['jobs', 'getPartAssembliesTree', payload],
			queryFn: () => unwrap(() => getApi().jobs.getPartAssembliesTree(payload)),
			refetchOnWindowFocus: false,
			staleTime: 60 * 1000
		}),
	getSupplyRecommendations: (payload: GetJobSupplyRecommendationsRequest) =>
		queryOptions<GetJobSupplyRecommendationsResult, StorefrontError>({
			queryKey: ['jobs', 'listSupplyRecommendations', payload],
			queryFn: () => unwrap(() => getApi().jobs.listSupplyRecommendations(payload))
		}),
	listParts: (payload: ListJobPartsRequest) =>
		queryOptions<ListJobPartsResult, StorefrontError>({
			queryKey: ['jobs', 'listParts', payload],
			queryFn: () => unwrap(() => getApi().jobs.listParts(payload))
		}),
	search: (payload: SearchJobsRequest) =>
		queryOptions<SearchJobsResult, StorefrontError>({
			queryKey: ['jobs', 'search', payload],
			queryFn: () => unwrap(() => getApi().jobs.search(payload))
		})
};
