import { formatPrice } from '@/app/utils/currency';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { flexRender, Table } from '@tanstack/react-table';

type BasicTableProps<T> = InheritableElementProps<
	'table',
	{
		table: Table<T>;
	}
>;

export const BasicTable = <T,>({ table, className, ...rest }: BasicTableProps<T>) => {
	return (
		<table className={tlsx('w-full', className)} {...rest}>
			<thead className="text-left">
				{table.getHeaderGroups().map(headerGroup => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map(header => {
							return (
								<th key={header.id} className="first:pl-0 last:pr-0 p-2.5">
									<>{flexRender(header.column.columnDef.header, header.getContext())}</>
								</th>
							);
						})}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map(row => {
					return (
						<tr key={row.id} className="border-b last:border-none">
							{row.getVisibleCells().map(cell => {
								return (
									<td key={cell.id} className="first:pl-0 last:pr-0 p-2.5">
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

BasicTable.Subtotal = ({ subtotal }: InheritableElementProps<'div', { subtotal: number }>) => (
	<div className="flex items-center justify-end gap-6 mt-4 text-sm font-semibold text-gray-900">
		<span>Sub total</span>
		<span>{subtotal > 0 ? formatPrice(subtotal) : '--'}</span>
	</div>
);
