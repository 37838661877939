import { CSSObject, MantineThemeOverride, Tuple, createEmotionCache } from '@mantine/core';
import { colors, radius, screens, shadows } from './values';

export const mantineCache = createEmotionCache({
	key: 'mantine',
	prepend: false
});

export const mantineTheme: MantineThemeOverride = {
	colors: {
		// Map tailwind 50-950 to mantine 1-10
		blue: Object.values(colors.blue) as Tuple<string, 10>,
		cyan: Object.values(colors.cyan) as Tuple<string, 10>,
		gray: Object.values(colors.gray) as Tuple<string, 10>,
		indigo: Object.values(colors.indigo) as Tuple<string, 10>,
		orange: Object.values(colors.orange) as Tuple<string, 10>,
		pink: Object.values(colors.pink) as Tuple<string, 10>,
		red: Object.values(colors.red) as Tuple<string, 10>,
		yellow: Object.values(colors.yellow) as Tuple<string, 10>,
		green: Object.values(colors.green) as Tuple<string, 10>,
		violet: Object.values(colors.violet) as Tuple<string, 10>,
		lime: Object.values(colors.lime) as Tuple<string, 10>,
		teal: Object.values(colors.teal) as Tuple<string, 10>
	},
	// Map tailwind radius to mantine radius equivalents
	radius: {
		xs: radius.sm,
		sm: radius.DEFAULT,
		md: radius.md,
		lg: radius.lg,
		xl: radius.xl
	},
	cursorType: 'pointer',
	defaultRadius: 'md',
	breakpoints: {
		sm: screens.sm,
		md: screens.md,
		lg: screens.lg,
		xl: screens.xl
	},
	primaryColor: 'blue',
	shadows: {
		xs: shadows.sm,
		sm: shadows.DEFAULT,
		md: shadows.md,
		lg: shadows.lg,
		xl: shadows.xl
	},
	focusRingStyles: {
		// focus styles applied to components that are based on Input
		// styled are added with &:focus selector
		inputStyles: theme => ({
			outline: `2px solid ${theme.colors[theme.primaryColor][5]}`,
			outlineOffset: -1
		})
	},
	// Match default font family to tailwind
	fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
	components: {
		Input: {
			styles() {
				return {
					input: {
						// Should match tailwindScreens.sm
						'@media (max-width: 640px)': {
							// Required for mobile screens to
							// not zoom.
							fontSize: 16
						},
						borderColor: colors.gray[300]
					}
				};
			}
		},
		PasswordInput: {
			styles() {
				return {
					innerInput: {
						'@media (max-width: 640px)': {
							// Required for mobile screens to
							// not zoom.
							fontSize: 16
						}
					}
				};
			}
		},
		Select: {
			styles(theme) {
				return {
					item: {
						'&:hover': {
							backgroundColor: theme.colors.gray[1]
						},
						'&[data-selected]': {
							backgroundColor: theme.colors[theme.primaryColor][1],
							color: theme.colors[theme.primaryColor][9]
						},
						'&[data-selected]:hover': {
							backgroundColor: theme.colors[theme.primaryColor][1],
							color: theme.colors[theme.primaryColor][9]
						}
					}
				};
			}
		},
		Button: {
			styles(theme, _, context) {
				const root: CSSObject = {
					fontWeight: 500,
					'&:active': {
						// Remove default down animation
						transform: 'none',
						...(context.variant === 'default' && {
							backgroundColor: theme.colors.gray[2]
						})
					},
					...(context.variant === 'default' && {
						border: `1px solid ${theme.colors.gray[3]}`,
						color: theme.colors.gray[7]
					})
				};

				return {
					root
				};
			}
		},
		ActionIcon: {
			styles(theme, _, context) {
				const root: CSSObject = {
					fontWeight: 500,
					'&:active': {
						// Remove default down animation
						transform: 'none',
						...(context.variant === 'default' && {
							backgroundColor: theme.colors.gray[2]
						})
					},
					...(context.variant === 'default' && {
						border: `1px solid ${theme.colors.gray[3]}`,
						color: theme.colors.gray[7]
					})
				};

				return {
					root
				};
			}
		},
		Checkbox: {
			styles(theme) {
				return {
					input: {
						borderColor: theme.colors.gray[3]
					}
				};
			}
		},
		Radio: {
			styles(theme) {
				return {
					radio: {
						borderColor: theme.colors.gray[3]
					}
				};
			}
		},
		Pagination: {
			styles(theme) {
				return {
					control: {
						borderColor: theme.colors.gray[3]
					}
				};
			}
		}
	}
};
