import { BasicTable } from '@/app/molecules/order-table';
import { isDefined } from '@/sdk/lib/utils/object';
import { Button, Textarea } from '@mantine/core';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { memo, useState } from 'react';
import { useOrderRequestTableItems } from '../../hooks/use-order-request-table-items';
import { AddItemPayload, OrderRequestModel } from '../../models';
import { draftOrderColumnBuilder } from '../../table-builder';
import { OrderRequestSection } from '../order-request-section';
import { AddExternalItemModal } from '../add-external-item-modal';

type DraftOrderReuqestProps = {
	model: OrderRequestModel;
	onChange: (model: OrderRequestModel) => void;
	onAddItem: (payload: AddItemPayload) => void;
};

const builder = draftOrderColumnBuilder();
const tableColumns = [
	builder.select(),
	builder.quantity(),
	builder.name(),
	builder.grade(),
	builder.arrivalAt(),
	builder.price()
];

export const DraftOrderRequest = memo(({ model, onChange, onAddItem }: DraftOrderReuqestProps) => {
	const [rowSelection, setRowSelection] = useState({});
	const [addItem, setAddItem] = useState(false);
	const [showNotes, setShowNotes] = useState(isDefined(model.estimator_notes));
	const { items, subtotal } = useOrderRequestTableItems(model.items);

	const table = useReactTable({
		data: items,
		columns: tableColumns,
		state: {
			rowSelection
		},
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		getCoreRowModel: getCoreRowModel()
	});

	const onRemoveSelected = () => {
		const selectedKeys = Object.keys(rowSelection).map(key => Number(key));
		if (selectedKeys.length === 0) {
			return;
		}

		const itemIds = selectedKeys.map(key => items[key].id);
		const existingItems = model.items.filter(item => !itemIds.includes(item.local_id));

		onChange({
			...model,
			items: existingItems
		});

		setRowSelection({});
	};

	const onSubmit = (payload: AddItemPayload) => {
		onAddItem(payload);
		setAddItem(false);
	};

	return (
		<OrderRequestSection>
			<OrderRequestSection.Content>
				<OrderRequestSection.Title>{model.vendor.Partner.name}</OrderRequestSection.Title>
				<div className="flex items-center justify-between mt-3">
					<div className="flex items-center gap-2">
						<Button variant="default" size="xs" onClick={() => setAddItem(true)}>
							Add item
						</Button>
						{!showNotes && (
							<Button variant="default" size="xs" onClick={() => setShowNotes(true)}>
								Attach note
							</Button>
						)}
					</div>
					{Object.keys(rowSelection).length > 0 && (
						<Button variant="outline" size="xs" color="red" onClick={onRemoveSelected}>
							Remove
						</Button>
					)}
				</div>
				<BasicTable className="mt-3" table={table} />
				<BasicTable.Subtotal subtotal={subtotal} />
				{showNotes && (
					<Textarea
						label="Notes for supplier"
						className="max-w-xl mt-4"
						value={model.estimator_notes ?? ''}
						onChange={e => onChange({ ...model, estimator_notes: e.target.value })}
					/>
				)}
			</OrderRequestSection.Content>
			<AddExternalItemModal
				open={addItem}
				onClose={() => setAddItem(false)}
				onSubmit={onSubmit}
				defaultValues={{ supplier_id: model.vendor.Partner.id }}
			/>
		</OrderRequestSection>
	);
});
