import { ListingGrade } from '@sdk/lib';
import { sortBy } from 'lodash-es';

export const formatGradeRange = (grades: ListingGrade[]) => {
	const listingGradeSorting: Record<ListingGrade, number> = {
		'0': 0,
		A: 1,
		B: 2,
		C: 3,
		D: 4
	};

	if (grades.length === 0) {
		return '--';
	}
	if (grades.length === 1 && grades[0] === '0') {
		return `New`;
	}

	if (grades.length === 1) {
		return `Grade ${grades[0]}`;
	}

	const sortedGrades = sortBy([...grades], grade => listingGradeSorting[grade]);

	if (sortedGrades[0] === '0') {
		return `New and Grade ${
			sortedGrades.length <= 2 ? sortedGrades[1] : `${sortedGrades.at(1)} - ${sortedGrades.at(-1)}`
		}`;
	}

	return `Grade ${sortedGrades.at(0)} - ${sortedGrades.at(-1)}`;
};

export const localiseGrade = (grade: ListingGrade) => {
	switch (grade) {
		case '0':
			return 'New';
		case 'A':
			return 'Used A';
		case 'B':
			return 'Used B';
		case 'C':
			return 'Used C';
		case 'D':
			return 'Used D';
		default:
			return '--';
	}
};
