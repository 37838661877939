import { NormalizedResponse_for_SingularItemPayload_for_String_and_Null } from '@sdk/generated/models/NormalizedResponse_for_SingularItemPayload_for_String_and_Null';
import { NormalizedResponse_for_SingularItemPayload_for_Nullable_User_and_UsersGetAditionalRaw } from '@/sdk/generated';
import { JWTResult, UserResult } from '../types';

type JWTResponseRaw = NormalizedResponse_for_SingularItemPayload_for_String_and_Null;
type UserInfoResponseRaw =
	NormalizedResponse_for_SingularItemPayload_for_Nullable_User_and_UsersGetAditionalRaw;

export const createJWTResponse = (result: JWTResponseRaw): JWTResult => {
	return { token: result.payload.item };
};

export const createUserResult = (result: UserInfoResponseRaw): UserResult => {
	const response = result.payload.item;
	if (!response) {
		return {
			user: null,
			business_entities: []
		};
	}

	if ('Guest' in response) {
		return {
			user: {
				guest: {
					id: response.Guest.id
				}
			},
			business_entities: []
		};
	}

	const business_entities = Object.values(result.payload.business_entities ?? {});

	return {
		user: {
			account: {
				id: response.Account.id,
				email: response.Account.email,
				firstName: response.Account.first_name,
				lastName: response.Account.last_name
			}
		},
		business_entities
	};
};
