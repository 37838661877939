import { ListingPricingRaw, ListingRaw } from '../../generated';
import {
	BaseListing,
	KitListing,
	Listing,
	ProductListing,
	ProductVariant,
	VariableProductListing
} from '../types';
import { Context } from '../utils/context';
import { getId } from '../utils/parsing';
import { createGapcAttributes } from './attributes';
import { createBaseGapcProperties, createGapcBrand } from './gapc';
import { createMeasurements } from './measurement';
import { createVariantSupplies } from './supplies';

export const createListingPricing = (pricing: ListingPricingRaw) => ({
	min: pricing.min,
	max: pricing.max,
	displayMax: pricing.display_max ?? null,
	displayMin: pricing.display_min ?? null,
	currency: pricing.currency ?? null
});

const createBaseListing = (
	listing: Omit<ListingRaw, 'kind'>,
	seoKeywords: string[] | null = null,
	ctx: Context
): BaseListing => {
	const gapcAttributes = createGapcAttributes(listing.gapc_attributes ?? [], ctx);
	const properties = createBaseGapcProperties(listing.gapc_properties ?? [], ctx);
	const pricing = createListingPricing(listing.pricing);
	const base: BaseListing = {
		id: listing.id,
		sku: listing.sku ?? null,
		description: listing.description ?? null,
		seoKeywords: seoKeywords ?? null,
		pricing,
		measurement: createMeasurements(listing.measurement),
		name: listing.name,
		gapcPartId: listing.gapc_part_id ?? null,
		images: [],
		tags: [],
		availableGapcPositions: [],
		mpn: listing.mpn ?? null,
		isOe: listing.is_oe ?? false,
		isUniversal: listing.is_universal ?? false,
		gapcAttributes,
		fit: null,
		mainImage: null,
		availability: listing.availability,
		externalId: null,
		gapcBrand: null,
		externalUrl: null,
		...properties
	};

	const defaultImage = ctx.config.listing?.defaultImage ?? null;
	const srcImages = listing.images.length > 0 ? listing.images : defaultImage ? [defaultImage] : [];

	base.images = srcImages;
	base.mainImage = srcImages[0] ?? null;

	if (listing.gapc_brand_id) {
		base.gapcBrand = createGapcBrand(listing.gapc_brand_id, ctx);
	}

	return base;
};

export const createListing = (value: ListingRaw, seoKeyword: string[] = [], ctx: Context) => {
	const base = createBaseListing(value, seoKeyword, ctx);

	if (value.kind === 'Kit') {
		const products: (ProductListing | VariableProductListing)[] = value.products
			.map(product => {
				const base = createBaseListing(product, seoKeyword, ctx);
				return {
					...base,
					kind: product.kind
				};
			})
			.filter((product): product is ProductListing | VariableProductListing => !!product);

		const kitListing: KitListing = {
			...base,
			kind: 'Kit',
			products
		};

		return kitListing;
	}

	if (value.kind === 'VariableProduct') {
		const variants: ProductVariant[] = value.variants
			.map(product => {
				const base = createBaseListing(product, seoKeyword, ctx);
				return {
					...base,
					kitId: product.kit_id ?? null,
					productId: product.product_id ?? null
				};
			})
			.filter((product): product is ProductVariant => !!product);

		const variableProductListing: VariableProductListing = {
			...base,
			kind: 'VariableProduct',
			kitId: value.kit_id ?? null,
			variants
		};

		return variableProductListing;
	}

	if (value.kind === 'Product') {
		const supplies = createVariantSupplies(value.supplies, ctx);
		const productListing: ProductListing = {
			...base,
			kind: 'Product',
			kitId: value.kit_id ?? null,
			productId: value.product_id ?? null,
			grades: value.grades,
			supplies
		};

		return productListing;
	}

	// We don't want to break if we add an additional listing type
	// that codegen doesn't know about.
	return null;
};

export const createListings = (items: string[] = [], ctx: Context): Listing[] =>
	items
		.map(key => {
			const id = getId(key);
			if (!id) {
				return null;
			}

			const value = ctx.resources.get({ path: 'listings', id });
			if (!value) {
				return null;
			}

			return createListing(value, undefined, ctx);
		})
		.filter((value): value is Listing => !!value);
