import { QueryClient, QueryClientConfig, dehydrate } from '@tanstack/react-query';
export type DehydratedProp = {
	dehydratedState: unknown;
};

export type StorefrontClient = {
	dehydrate: () => DehydratedProp;
	client: QueryClient;
};

export const getDehydratedProp = (client: QueryClient): DehydratedProp => ({
	dehydratedState: dehydrate(client)
});

export const createClient = (config?: QueryClientConfig): StorefrontClient => {
	const client = new QueryClient(config);
	return {
		dehydrate: () => getDehydratedProp(client),
		client
	};
};
