import { DateRangeRaw, GapcAttributeDataTypesRaw, JobStatusRaw } from '@/sdk/generated';
import { ApiError as _ApiError } from '@sdk/generated/core/ApiError';
import { Merge } from 'type-fest';
import { BusinessEntity } from '../generated/models/BusinessEntity';

// Config

export type ApiConfig = {
	webKey: string;
	basePath: string;
	currencyCode?: string;
	search?: ApiSearchConfig;
	vehicle?: ApiVehicleConfig;
	listing?: ApiListingConfig;
	userToken?: UserToken;
};

export type UserToken = {
	kind: 'guest' | 'account';
	token: string;
};

export type ApiSearchConfig = {
	vehicleGroupingProperties: UvdbPropertyKey[];
};

export type ApiVehicleConfig = {
	/**
	 * Optional field to provide user-defined names for `UvdbProperty` keys.
	 * This can be used to enhance readability or to add internationalization support.
	 *
	 * By default, `UvdbProperty` keys are split based on case.
	 * @example EngineDesignation -> Engine Designation
	 * @example TransmissionMfrCode -> Transmission Mfe Code
	 *
	 * Using `uvdbPropertyAliases`, you can provide alternative names.
	 * @example EngineDesignation -> Engine Code
	 * @example TransmissionMfrCode -> Transmission Code
	 */
	propertyAliases?: UvdbPropertyAliases;

	/** The target region for UVDB lookups */
	uvdbRegionId?: string;
};

export type ApiListingConfig = {
	/**
	 * Optional field to provide a default image
	 * for when a listing doesn't have any images.
	 */
	defaultImage?: Image;
};

// Error

export class ApiError extends Error {
	public readonly url: string;

	public readonly status: number;

	public readonly statusText: string;

	public readonly body: any;

	constructor(error: _ApiError, options?: ErrorOptions) {
		super(error.message, options);

		this.name = 'ApiError';
		this.url = error.url;
		this.status = error.status;
		this.statusText = error.statusText;
		this.body = error.body;
	}
}

export class ParseError extends Error {
	constructor(message: string, options?: ErrorOptions) {
		super(message, options);
	}
}

export type StorefrontError = ApiError | ParseError;

export type StorefrontResponseSuccess<T> = {
	data: T;
	error: null;
};

export type StorefrontResponseFailure = {
	data: null;
	error: StorefrontError;
};

export type StorefrontResponse<T> = StorefrontResponseSuccess<T> | StorefrontResponseFailure;

// Conflict resolution

export type IfExists = 'replace_all' | 'replace' | 'ignore' | 'abort' | { update: unknown };

export type WithIfExists<T, Exists extends IfExists> = T & {
	if?: {
		exists: Exists;
	};
};

export type IfNotExists = 'ignore' | 'abort';

export type WithIfNotExists<T, NotExists extends IfNotExists> = T & {
	if?: {
		not_exists: NotExists;
	};
};

// Listings

export type Listing = KitListing | ProductListing | VariableProductListing;

export type BaseListing = BaseGapcProperties & {
	id: string;
	sku: string | null;
	description: string | null;
	seoKeywords: string[] | null;
	pricing: ListingPricing;
	gapcPartId: string | null;
	name: string;
	mainImage: Image | null;
	images: Image[];
	externalId: ExternalListingId | null;
	externalUrl: string | null;
	tags: string[];
	mpn: string | null;
	isOe: boolean;
	isUniversal: boolean;
	gapcBrand: GapcBrand | null;
	gapcAttributes: GapcAttributeValue[];
	measurement: Measurement | null;
	fit: FitContext | null;
	availableGapcPositions: GapcPosition[];
	availability: Availability;
};

export type ListingImage = {
	thumb: string;
	large: string;
};

export type ListingGrade = '0' | 'A' | 'B' | 'C' | 'D';

export type KitListing = BaseListing & {
	kind: 'Kit';
	products: (VariableProductListing | ProductListing)[];
};

export type ProductListing = {
	kind: 'Product';
	grades: ListingGrade[];
	supplies: VariantSupply[];
} & BaseListing &
	ProductVariant;

export type ProductVariant = Omit<BaseListing, 'kind'> & {
	kitId: string | null;
	productId: string | null;
};

export type VariableProductListing = BaseListing & {
	kind: 'VariableProduct';
	variants: ProductVariant[];
	kitId: string | null;
};

export type FitContext = {
	result: FitResult;
	fitmentNotes: string | null;
};

export type ListingGetFitmentsRequest = {
	listingId: ListingLookupKey;
	cursor?: string;
	limit?: number;
};

export type GetListingRequest = {
	listingId: ListingLookupKey;
};

export type GetManyListingsRequest = {
	listingIds: string[];
};

export type ListingGetFitmentsResult = {
	fitments: GapcFitment[];
	pagination: Pagination;
};

export type ListingFitmentCheckRequest = {
	listingId: ListingLookupKey;
	vehicleFilter: VehicleFilter;
};

export type ListingLookupKey = string | ExternalListingId | ExactListingId;

export type ExternalListingId = {
	externalId: string;
	variantId: string | null;
};

export type ExactListingId =
	| {
			variantId: string;
	  }
	| {
			productId: string;
			variantId: string;
	  }
	| {
			kitId: string;
	  }
	| {
			kitId: string;
			variantId: string;
	  }
	| {
			kitId: string;
			productId: string;
			variantId: string;
	  };

export type ListingFitmentCheckResult = {
	fit: FitResult;
	confidence: number | null;
	fitmentNote: string | null;
	requiredProperties: GapcFitmentCheckRequiredProperty[];
	reason: string | null;
	/**
	 * The possible fitting positions for the given vehicle filter for this part, only meaningful when fitment is Fit or NeedInfo
	 */
	availableGapcPositions: GapcPosition[];
	/**
	 * The possible fitting fitments for the given vehicle filters, only meaningful when fitment is Fit or NeedInfo
	 */
	fitments: Array<GapcFitment>;
};

export type ListingGetInterchangesRequest = {
	listingId: ListingLookupKey;
	cursor?: string;
	limit?: number;
};
export type SearchListingsRequiredUvdbPropertiesResult = {
	properties: GapcFitmentCheckRequiredProperty[];
};

export type SearchUvdbVehicleDefinitionsAggregationsResult = {
	aggregations: UvdbVehicleDefinitionAggregations;
};

export type SearchUvdbVehicleDefinitionsResult = {
	vehicleDefinitions: UvdbVehicleDefinition<UvdbProperties>[];
	uvdbProperties: UvdbProperties;
	cursor: Pagination;
	contextToken: string | null;
	vin: string | null;
};

export type SearchVehiclePayload = ChassisNumber | VrmSearch;

export type ChassisNumber = {
	chassisNumber: string;
};

export type VrmSearch = {
	vrm: {
		plateNumber: string;
		state?: string | null;
	};
};

export type SearchVehiclesResult = {
	chassisNumber: string | null;
	vehicleConfigurations: VehicleConfiguration[];
};

export type VehicleConfiguration = {
	token: string | null;
	uvdbProperties: UvdbProperties;
	properties: OemVehicleProperty[];
};

export type OemVehicleProperty = {
	type: string;
	value: string | number;
};

export type SearchListingsResult = {
	pagination: Pagination;
	listings: Listing[];
	meta: SearchListingsMetadata;
	categories: ListingCategories;
	gapcProperties: Record<string, GapcProperty>;
};

export type SearchListingsMetadata = {
	gapcAttributes: MetadataAttributeGroup[];
	gapcBrands: MetadataAttribute<string>[];
	gapcPartTypes: MetadataAttribute<string>[];
	gapcPositions: GapcPosition[];
	tags: MetadataAttribute<string>[];
};

export type MetadataAttributeGroup = {
	id: string;
	name: string;
	dataType: GapcAttributeDataTypes;
	attributes: MetadataAttribute<GapcAttributeVal>[];
};

export type MetadataAttribute<T> = {
	id: string;
	value: T;
	count: number;
};

export type FederatedSearchRequest = {
	keyword: string;
};

export type FederatedSearchResult = {
	listings: Listing[];
	partTypes: GapcPartType[];
	brands: GapcBrand[];
	vehicleGroups: UvdbVehicleDefinitionGroup[];
};

export type ListingCategories = {
	gapcPartTypes: GapcPartType[];
};

// Commerce listings

export type GetBestSellersRequest = {
	top: number;
};

// Gapc
export type GapcAttributeDataTypes = GapcAttributeDataTypesRaw;

export type GapcAttributeVal = string | boolean | number | [number, number];

export type GapcAttribute = GapcBase & {
	dataType: GapcAttributeDataTypes;
};

export type GapcAssemblyPartFitment = {
	type: string;

	value: any;
};

export type GapcAssemblyPartAttribute = {
	type: string;

	value: any;
};

export type BaseGapcAssemblyPart = {
	attributes: GapcAssemblyPartAttribute[];
	fitment: GapcAssemblyPartFitment[];
	quantity?: number | null;
};

export type NormalizedGapcAssemblyPart = BaseGapcAssemblyPart & {
	gapcPartIdentity?: GapcPartIdentity | null;
};

export type GapcAssemblyPart = BaseGapcAssemblyPart & {
	description?: string | null;
	partIdentity?: string | null;
	mpn?: string;
	gapcBrandId?: string;
};

export type GapcProperty =
	| ({ kind: 'Brand' } & GapcBrand)
	| ({ kind: 'Attribute' } & GapcAttribute)
	| ({ kind: 'PartType' } & GapcPartType)
	| ({ kind: 'Category' } & GapcCategory)
	| ({ kind: 'Other' } & GapcBase);

export type GapcAttributeValue = {
	id: string;
	gapcProperty: GapcBase;
	dataType: GapcAttributeDataTypes;
	value: GapcAttributeVal;
};

export type GapcBase = {
	id: string;
	name: string;
};

export type GapcBrand = GapcBase & {
	isOem: boolean;
};

export type GapcPartType = GapcBase & {
	categorizations: {
		subcategory: GapcSubcategory;
		category: GapcCategory;
	}[];
	uvdbPropertyKeys: UvdbPropertyKey[];
	aliases: string[];
};

export type BaseGapcProperties = {
	gapcPositions: GapcPosition[];
	gapcPartType: GapcPartType | null;
	gapcSubcategory: GapcSubcategory | null;
	gapcCategory: GapcCategory | null;
};

export type GapcPart = BaseGapcProperties & {
	// part Identity is compose of brandId+mpn, not to confuse with part id
	partIdentity: string;
	mpn: string;
	isOe?: boolean;
	isUniversal?: boolean;
	gapcParentId?: string | null;
	gapcBrand: GapcBrand | null;
	gapcAttributes: GapcAttributeValue[];
	measurement?: Measurement | null;
	availableGapcPositions?: string[];
};

export type GapcDiagram = {
	id: string;
	name: string;
	code: string;
	fitment: GapcAssemblyPartFitment[];
	image: {
		thumb: string;
		large: string;
	};
	partSlots: GapcDiagramPartSlot[];
};

export type GapcAssembly = {
	gapcDiagrams: GapcDiagram[];
	id: string;
	name: string;
	part?: GapcAssemblyPart | null;
};

export type GapcDiagramPartSlot = {
	id: string;
	code: string;
	hotspots: GapcDiagramHotSpot[];
	parts: GapcAssemblyPart[];
	segments: GapcDiagramSegmentPolygon[];
	assemblies: string[];
};

export type GapcDiagramHotSpot = {
	x1Px: number;
	x2Px: number;
	y1Px: number;
	y2Px: number;
};

export type GapcDiagramSegmentPolygon = {
	vectors: GapcDiagramPolygonVector[];
};

export type GapcDiagramPolygonVector = {
	x: number;
	y: number;
};

export type GapcPosition = GapcBase;

export type GapcSubcategory = GapcBase;

export type GapcCategory = GapcBase & {
	vehicleTypeId: string;
	vehicleType: UvdbProperty | null;
};

export type GapcFitment = {
	id: string;
	uvdbVehicleDefinition: UvdbVehicleDefinition<UvdbProperties> | null;
	restrictions: UvdbAggregateProperties;
	gapcPosition: GapcPosition | null;
	fitmentNote: string | null;
};

// Uvdb

export type UvdbBase = {
	id: string;
	name: string;
};

/**
 * Unique uvdb properties keyed by their type.
 */
export type UvdbAggregateProperties = Partial<Record<UvdbPropertyKey, UvdbProperty>>;
export type UvdbAggregateMultipleProperties = Partial<
	Record<UvdbMultiplePropertyKey, UvdbProperty[]>
>;
/**
 * Uvdb propterties keyed by their type where multiple values
 * of the same type are expanded into an array.
 */
export type UvdbProperties = Merge<UvdbAggregateProperties, UvdbAggregateMultipleProperties>;

const uvdbMultipleValueKeys = [
	'UvdbModel',
	'UvdbSubmodel',
	'UvdbBodyType',
	'UvdbBodyNumDoors',
	'UvdbSeries',
	'UvdbEngineDesignation',
	'UvdbMfrBodyCode'
] as const;

export type UvdbMultiplePropertyKey = (typeof uvdbMultipleValueKeys)[number];

export function isMultipleUvdbPropertyKey(value: string): value is UvdbMultiplePropertyKey {
	return uvdbMultipleValueKeys.includes(value as UvdbMultiplePropertyKey);
}

export type UvdbPropertyKey =
	| UvdbMultiplePropertyKey
	| 'UvdbAspiration'
	| 'UvdbBedLength'
	| 'UvdbBedType'
	| 'UvdbBrakeAbs'
	| 'UvdbBrakeSystem'
	| 'UvdbCylinderHeadType'
	| 'UvdbDriveType'
	| 'UvdbEngineBase'
	| 'UvdbEnginePowerOutput'
	| 'UvdbEngineVersion'
	| 'UvdbEngineDefinition'
	| 'UvdbFuelDeliverySubtype'
	| 'UvdbFuelDeliveryType'
	| 'UvdbFuelSystemControlType'
	| 'UvdbFuelSystemDesign'
	| 'UvdbFuelType'
	| 'UvdbIgnitionSystemType'
	| 'UvdbMake'
	| 'UvdbRegion'
	| 'UvdbRestriction'
	| 'UvdbSteeringSide'
	| 'UvdbSteeringSystem'
	| 'UvdbSteeringType'
	| 'UvdbTransmissionControlType'
	| 'UvdbTransmissionElecControl'
	| 'UvdbTransmissionMfrCode'
	| 'UvdbTransmissionNumSpeeds'
	| 'UvdbTransmissionType'
	| 'UvdbValves'
	| 'UvdbVehicleDefinition'
	| 'UvdbVehicleSubtype'
	| 'UvdbVehicleType'
	| 'UvdbEngineVin'
	| 'UvdbWheel'
	| 'UvdbWheelBase'
	| 'UvdbWheelConfig'
	| 'UvdbYearFrom'
	| 'UvdbYearTo'
	| 'UvdbYear' // production year
	| 'UvdbMonthFrom'
	| 'UvdbMonthTo'
	| 'UvdbMonth' // production month
	| 'UvdbEngineManufacturer'
	| 'UvdbTransmissionManufacturer'
	| 'UvdbFrontSpringType'
	| 'UvdbRearSpringType'
	| 'UvdbFrontBrakeType'
	| 'UvdbRearBrakeType'
	| 'UvdbEngine';

export type GapcPropertyKey =
	| 'GapcBrand'
	| 'GapcCategory'
	| 'GapcPartCode'
	| 'GapcPartType'
	| 'GapcPosition'
	| 'GapcSubcategory'
	| 'GapcTyre'
	| 'GapcWheel'
	| 'GapcAttribute';

export type UvdbProperty = UvdbBase & {
	type: UvdbPropertyKey;
};

export type UvdbGeneratedEngine = UvdbBase & {
	properties: UvdbProperty[];
};

export type GapcFitmentCheckRequiredProperty = {
	id: UvdbPropertyKey;
	name: string;
	options: GapcFitmentCheckRequiredOption[];
};

export type GapcFitmentCheckRequiredOption = UvdbBase | UvdbVehicleDefinition<UvdbProperties>;

export type UvdbVehicleDefinitionAggregation<T extends UvdbBase = UvdbBase> = {
	key: UvdbPropertyKey;
	options: T[];
};

export type UvdbVehicleDefinitionAggregations = {
	UvdbVehicleType: UvdbVehicleDefinitionAggregation<UvdbProperty> | null;
	UvdbMake: UvdbVehicleDefinitionAggregation<UvdbProperty> | null;
	UvdbModel: UvdbVehicleDefinitionAggregation<UvdbProperty> | null;
	UvdbYear: UvdbVehicleDefinitionAggregation<UvdbProperty> | null;
	UvdbSeries: UvdbVehicleDefinitionAggregation<UvdbProperty> | null;
	UvdbSubmodel: UvdbVehicleDefinitionAggregation<UvdbProperty> | null;
	UvdbEngine: UvdbVehicleDefinitionAggregation<UvdbGeneratedEngine> | null;
	UvdbVehicleDefinition: UvdbVehicleDefinitionAggregation<
		UvdbVehicleDefinition<UvdbProperties>
	> | null;
};

export type UvdbVehicleDefinitionGroup = {
	name: string;
	definitions: UvdbVehicleDefinition<UvdbProperties>[];
};

export type UvdbVehicleDefinition<T> = UvdbBase & {
	properties: T;
};

// Cart

export type SupplyInventory = {
	location: SupplyLocation | null;
	availability: Availability;
};

export type VariantSupply = {
	offerId: string;
	vendor: SupplyVendor | null;
	inventory: SupplyInventory[];
	price: number;
	displayPrice: number | null;
	grade: ListingGrade | null;
	availability: Availability;
};

export type Cart = {
	id: string;
	items: CartItem[];
	createdAt: string | null; // Date class cannot be serialized on front end
	updatedAt: string | null;
};

export type CartResult = {
	cart: Cart | null;
};

export type CartItem = {
	id: string;
	quantity: number;
	offer: SupplyItemOffer;
	price: CartSubtotalPrice;
	grade: ListingGrade | null;
	availability: Availability;
	shipping: ShippingTime | null;
	notes: string | null;
	updatedAt: string | null;
	context: PartSelectionContexts | null;
};

export type CartSubtotalPrice = {
	currency: string;
	price: number;
	displayPrice: number | null;
};

export type CartItemUpsert = {
	quantity: number;
	offer: CartItemOfferUpsert;
	notes?: string | null;
	context?: PartSelectionContextInsert[];
};

export type PartSelectionContextInsert = {
	description?: string | null;
	gapcBrandId?: string;
	mpn?: string;
	gapcPartTypeId?: string;
	gapcPositionId?: string | null;
};

export type CartItemInsert = {
	quantity: number;
	offer: CartItemOfferInsert;
	notes?: string | null;
	context?: PartSelectionContextInsert[];
};

export type CartItemOfferUpsert =
	| {
			type: 'Product';
			listingId: string;
			offerId: string;
	  }
	| {
			type: 'Kit';
			listingId: string;
			offerIds: string[];
	  };

export type CartItemOfferInsert =
	| {
			type: 'Product';
			listingId: string;
			offerId: string;
	  }
	| {
			type: 'Kit';
			listingId: string;
			offerIds: string[];
	  };

export type SupplyItemOffer =
	| {
			type: 'Product';
			listing: Listing;
			offerId: string;
	  }
	| {
			type: 'Kit';
			listing: Listing;
			offerIds: string[];
	  };

export type CartUpsertItemRequest = {
	cartId: string;
	items?: CartItemUpsert[];
};

export type JobCartItemUpsertRequest = {
	jobId: string;
	cartId: string;
	items?: CartItemUpsert[];
};

export type JobCartItemInsertManyRequest = WithIfExists<
	{
		jobId: string;
		cartId: string;
		items?: CartItemInsert[];
	},
	'replace_all' | 'abort'
>;

export type CartTagline = 'Fastest' | 'Cheapest';
export type SupplyTagline = 'Fastest' | 'Cheapest';

export type CartRecommendation = {
	taglines: CartTagline[];
	cart: Cart;
	summary: CartSummary;
};

export type CartSummary = {
	grades: ListingGrade[];
	shipping: ShippingTime | null;
	price: CartSubtotalPrice | null;
};

/* eslint-disable-next-line */
export type CartsGetRequest = {};

export type CartsCheckoutRequest = {
	cartId: string;
};

export type CartsListShippingOptionsRequest = {
	cartId: string;
	address: ShippingAddressSearch;
};

export type CheckoutResult = {
	External: string;
};

export type ShippingAddressSearch = {
	city: string;
	state: string;
	country: string;
	postalCode: string;
};

export type SupplyLocation = {
	id: string;
	name: string;
	vendor: SupplyVendor;
};

export type SupplyVendor = {
	id: string;
	name: string;
};

export type ShippingOption = {
	cost: number;
	description?: string | null;
	additionalDescription?: string | null;
};

// Jobs

export type JobStatus = 'Active' | 'Completed' | 'Ordered' | 'Deleted';

export type Job = {
	repairerOrgId: string;
	repairerSiteId: string;
	id: string;
	jobNumber: string | null;
	claimNumber: string | null;
	createdAt: number;
	completedAt: number | null;
	deliveryBeforeAt: number | null;
	// This is only temporary, it should be
	// a UvdbVehicleDefinition<UvdbProperty>
	vehicle: JobVehicle | null;
	collisions: CollisionAreaSeverity[];
	addedPartsCount: number;
	cartItemsCount: number;
	status: JobStatus;
	bmsIntegrated: boolean;
};

export type JobVehicle = {
	plateState?: string | null;
	plateNumber?: string | null;
	chassisNumber?: string | null;
	variant?: {
		token?: string | null;
		description: string;
		uvdbProperties: string[];
	};
};

export type SearchJobsResult = {
	pagination: Pagination;
	jobs: Job[];
};

export type CreateJobResult = {
	job: Job;
};

export type GetJobResult = {
	job: Job;
};

export type UpsertJobResult = {
	job: Job;
};

export type UpsertJobVehicleResult = {
	job: Job;
};

export type UpsertJobCollisionsResult = {
	job: Job;
};

export type SearchJobsRequest = {
	repairerSiteIds: Array<string>;
	onlyOwned?: boolean;
	keyword?: string | null;
	status?: JobStatusRaw | null;
	createdDateRange?: DateRangeRaw | null;
	completedDateRange?: DateRangeRaw | null;
	cursor?: string | null;
	limit?: number;
};

export type CreateJobRequest = {
	vehicle: VehicleFilter;
};

export type GetJobRequest = {
	jobId: string;
};

export type ListJobOrdersRequest = {
	jobId: string;
};

export type SubsequentJobOrderRequest = {
	jobId: string;
};

export type SubsequentJobOrderResult = {
	job: Job;
};

export type InsertJobOrdersRequest = {
	jobId: string;
	vendorNotes?: Record<string, string> | null;
	deliveryDate: number;
};

export type ListJobOrdersResult = {
	job: Job;
	orders: Order[];
};

export type InsertJobOrdersResult = {
	job: Job;
	orders: Order[];
};

export type BmsSyncJobPartsRequest = {
	jobId: string;
};

export type BmsSyncJobPartsResponse = {
	job: Job;
};

export type OrderStatus = 'Ordered' | 'Failed';

export type OrderItemStatus = 'Ordered';

export type PriceSnapshot = {
	currency: string;
	price: number;
};

export type OrderSummary = {
	purchasePrice: PriceSnapshot;
	localPrice: PriceSnapshot;
	shippingTime: ShippingTime | null;
	grades: ListingGrade[];
};

export type ShippingAddress = {
	city: string | null;
	countryCode: number | null;
	postcode: string | null;
	state: string | null;
	streetName: string | null;
	streetNumber: string | null;
	suburb: string | null;
	unit: string | null;
	geo: GeoLocation | null;
};

export type ShippingPurchase = {
	shippingPrice: PriceSnapshot;
	shippingAddress: ShippingAddress;
	billingAddress: ShippingAddress;
	shippingInstructions: string | null;
};

export type GapcPropertySnapshot<T = string> = {
	id: string;
	name: T;
};

export type OrderVariantListing = {
	kitId: string | null;
	productId: string | null;
	id: string;
	name: string;
	sku: string | null;
	tags: Array<string>;
	gapcBrand: GapcPropertySnapshot | null;
	mpn: string | null;
	isOe: boolean;
	isUniversal: boolean;
	partType: GapcPropertySnapshot | null;
	availableGapcPositions: GapcPropertySnapshot[];
};

export type ProductOrderOffer = {
	type: 'Product';
	listing: OrderVariantListing;
	offerId: string;
};

export type OrderKitListing = OrderVariantListing & {
	products: Array<OrderVariantListing>;
};

export type KitOrderOffer = {
	type: 'Kit';
	listing: OrderKitListing;
	offerIds: string[];
};

export type OrderOffer = ProductOrderOffer | KitOrderOffer;

export type OrderItem = {
	id: string;
	quantity: number;
	status: OrderItemStatus;
	offer: OrderOffer;
	grade: ListingGrade | null;
	shippingTime: ShippingTime | null;
	purchasePrice: PriceSnapshot;
	context: PartSelectionContexts | null;
};

export type Order = {
	id: string;
	groupId: string;
	items: OrderItem[];
	status: OrderStatus;
	vendor: SupplyVendor;
	summary: OrderSummary;
	shipping: ShippingPurchase;
	deliveryDate: number;
	createdAt: number;
	notes: string | null;
	updatedAt: number | null;
};

export type RemoveJobRequest = {
	jobId: string;
};

export type RemoveJobResult = {
	// Deleting a job returns nothing, if the
	// response is sent then the job was deleted.
	success: true;
};
export type UpsertJobRequest = {
	repairerSiteId: string;
	jobId?: string | null;
	jobNumber?: string | null;
	claimNumber?: string | null;
};

export type UpsertJobVehicleRequest = {
	job_id: string;
	vehicle: JobVehicle;
};

export type UpsertJobCollisionsRequest = {
	jobId: string;
	collisions: CollisionAreaSeverity[];
};

export type GetJobCollisionMapRequest = {
	jobId: string;
};

export type GetJobPartRecommendationsRequest = {
	jobId: string;
};

export type GetJobPartAssembliesTreeRequest = {
	jobId: string;
};

export type GetJobCartRecommendationsRequest = {
	jobId: string;
};

export type GetJobSupplyRecommendationsRequest = {
	jobId: string;
};

export type GetJobPartAssembliesTreeResult = {
	job: Job;
	tree: PartAssembliesTree;
};

export type GetJobCartRecommendationsResult = {
	recommendations: CartRecommendation[];
	noSupply: JobPart[];
};

export type SupplyRecommendation = {
	taglines: SupplyTagline[];
	offers: string[];
};

export type GetJobSupplyRecommendationsResult = {
	parts: JobPartSupplies[];
	recommendations: SupplyRecommendation[];
	noSupply: JobPart[];
	offers: Record<string, PartSupplyOffer>;
	supplyHashId: string | null;
};

export type PartSupplyOffer = {
	id: string;
	gapcParts: GapcPart[];
	vendor: SupplyVendor;
	offer: SupplyItemOffer;
	price: CartSubtotalPrice;
	grade: ListingGrade | null;
	availability: Availability;
	shipping: ShippingTime | null;
};

export type JobPartSupplies = {
	part: JobPart;
	supplies: PartSupplyOffer[];
};

export type GetJobCollisionMapResult = {
	collisionMap: CollisionMap;
};

export type ListJobPartsRequest = {
	jobId: string;
};

export type ListJobPartsResult = {
	parts: JobPart[];
};

export type UpsertJobPartsRequest = {
	jobId: string;
	parts: JobPartInput[];
};

export type JobPartInput = {
	quantity: number;
	description?: string | null;
	partSlot?: null | {
		gapcPartTypeId: string;
		gapcPositionId?: string | null;
	};
	assemblyIds: string[];
} & GapcPartIdentity;

export type JobPart = {
	quantity: number;
	partIdentity: string;
	mpn: string;
	gapcBrand: GapcBrand | null;
	description: string | null;
	partSlot: PartSlot | null;
	assemblyIds: string[];
};

export type UpsertJobPartsResult = {
	job: Job;
	parts: JobPart[];
};

export type PartSlot = {
	gapcPosition?: GapcPosition | null;
	gapcPartType?: GapcPartType | null;
};

export type PartSlotIds = {
	gapcPositionId?: string | null;
	gapcPartTypeId?: string | null;
};

export type ListingKinds = 'Kit' | 'Product' | 'VariableProduct';

export type PartSupplySummary = {
	availability: Availability;
	listings: Listing[];
	images: ListingImage[];
	listingTypes: ListingKinds[];
	partNumbers: string[];
	price: ListingPricing | null;
	shipping: ShippingTime | null;
	grades: ListingGrade[];
};

export type GetJobCartRequest = {
	jobId: string;
};

export type JobCartResult = {
	summary: CartSummary;
	cart: Cart;
};

export type CollisionMap = {
	id: string;
	imageUrl: string;
	description: string;
	collisionAreas: CollisionArea[];
};

export type CollisionAreaSeverity = {
	id: string;
	severity: 'Light' | 'Medium' | 'Heavy';
	hcas?: string[];
};

export type CollisionArea = {
	id: string;
	name: string;
	partSlotIds: PartSlotIds[];
	hcas: CollisionHca[];
};

export type CollisionHca = {
	id: string;
	name: string;
	partSlotIds: PartSlotIds[];
};

export type PartSelectionContexts = PartSelectionContext[];

export type PartSelectionContext = {
	description: string | null;
	gapcBrand: GapcBrand | null;
	mpn: string | null;
	gapcPartType: GapcPartType | null;
	gapcPosition: GapcPosition | null;
};

export type PartAssembliesTree = {
	assemblies: PartAssembly[];
};

export type PartAssembly = {
	id: string;
	description: string;
	partSlot?: PartSlot | null;
	part?: PartAssemblyInfo | null;
	diagrams?: GapcDiagram[] | null;
	assemblyType: 'human_centric' | 'part';
	subAssemblies: PartSubAssembly[];

	attributes?: GapcAssemblyPartAttribute[] | null;
	fitment?: GapcAssemblyPartFitment[] | null;
	confidence?: number | null;
	quantityRequired?: number | null;
	supply?: PartSupplySummary | null;
};

export type PartAssemblyInfo = {
	partIdentity: string;
	gapcBrandId: string;
	brand?: GapcBrand | null;
	mpn: string;
};

export type PartSubAssembly =
	| { kind: 'single'; assembly: PartAssembly }
	| { kind: 'variants'; variants: PartAssembly[] };

// Misc

export type GeoLocation = {
	latitude: number;
	longitude: number;
};

export type Pagination = {
	nextCursor: string | null;
	previousCursor: string | null;
	total: number;
	hasTooManyTotal: boolean;
};

export type FitResult = 'Fit' | 'NotFit' | 'NeedInfo' | 'NotAvailable';

export type Image = {
	thumb: string;
	large: string;
};

export type UvdbPropertyAliases = Partial<Record<UvdbPropertyKey, string>>;

export type Measurement = {
	widthMm: number | null;
	heightMm: number | null;
	lengthMm: number | null;
	weightG: number | null;
};

export type ListingPricing = {
	min: number;
	max: number;
	displayMin?: number | null;
	displayMax?: number | null;
	currency: string;
};

export type Availability = 'InStock' | 'OutOfStock' | 'NoSupply';

export type ShippingTime = {
	eta?: string | null;
	arrival_time?: number | null;
	business_days?: number | null;
};

export type VehicleFilter = {
	/**
	 * eg. VLC
	 */
	plateState?: string | null;
	/**
	 * eg. eg. XXXXX
	 */
	plateNumber?: string | null;
	/**
	 * Can be VIN or Frame Number
	 */
	chassisNumber?: string | null;
	configurations?: string[];
	uvdbVehicleDefinitions?: string[];
	contextToken?: string | null;
};

// Users

export type AuthCredentials = {
	type: 'Basic';
	email: string;
	password: string;
};

export type JWTResult = {
	token: string;
};

export type RegistrationInfo = {
	firstName: string;
	lastName: string;
	authentication: AuthCredentials;
};

export type RemoveUserRequest = {
	userId: string;
};

export type UserResult = {
	user: User | null;
	business_entities: BusinessEntity[];
};

export type Guest = {
	id: string;
};

export type User =
	| {
			account: Account;
	  }
	| {
			guest: Guest;
	  };

export type Account = {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
};

export type BasicAuthChangePassword = {
	oldPassword: string;
	newPassword: string;
};

export type UserSuccessResult = {
	success: boolean;
};

// garage

export type GarageVehicle = {
	id: string;
	uvdbProperties: UvdbAggregateProperties;
	uvdbVehicleDefinitions: string[];
	plateState: string | null;
	plateNumber: string | null;
};

export type Garage = {
	activeVehicleId: string | null;
	vehicles: Record<string, GarageVehicle> | null;
};

export type UpdateVehicleRequest = {
	id: string;
	payload: GarageVehicleUpsert;
};

export type InsertVehicleRequest = GarageVehicleUpsert;

export type GarageVehicleUpsert = {
	uvdbProperties: string[];
	uvdbVehicleDefinitions: string[];
	plateNumber?: string | null;
	plateState?: string | null;
};

export type GarageVehicleRemoveRequest = {
	id: string;
};

export type SetActiveVehicleRequest = {
	id?: string | null;
};

// wishlist

export type WishList = {
	listings: Listing[];
};

export type WishListSuccessResult = {
	success: boolean;
};

export type WishListItemPayload = {
	id: string;
};

export type GapcPartIdentity = {
	mpn: string;
	gapcBrandId: string;
};
