import { ApiError } from '@sdk/lib';
import { signOut } from '../auth';

export const shouldRetry = (failureCount: number, error: Error) => {
	const is4xx = error instanceof ApiError && error.status >= 400 && error.status <= 499;
	const allowedAttempts = is4xx ? 1 : 3;

	return failureCount <= allowedAttempts;
};

export const onError = (error: Error) => {
	if (error instanceof ApiError) {
		if (error.status === 401) {
			signOut();
		}
	}
};
