import { memo } from 'react';
import { match } from '@/types/match';
import { draft_order } from '@/sdk/reflect/reflect';
import { AddItemPayload, OrderRequestModel } from '../../models';
import { DraftOrderRequest } from '../draft-order-request';
import { FinalisedOrderRequest } from '../finalised-order-request';
import { ProcessedOrderRequest } from '../processed-order-request';
import { ProcessingOrderRequest } from '../processing-order-request';
import { CancelledOrderRequest } from '../cancelled-order-request';

type OrderRequestBuilderProps = {
	model: OrderRequestModel;
	onChange: (model: OrderRequestModel | undefined) => void;
	onCancel: (model: OrderRequestModel) => void;
	onAddItem: (payload: AddItemPayload) => void;
};

export const OrderRequestBuilder = memo(
	({ model, onChange, onCancel, onAddItem }: OrderRequestBuilderProps) => {
		const internalOnChange = (model: OrderRequestModel) => {
			if (model.items.length === 0) {
				onChange(undefined);
				return;
			}

			onChange(model);
		};

		return match<draft_order.DraftOrderStatus, React.ReactNode>(
			model.status,
			{
				Draft: () => (
					<DraftOrderRequest model={model} onChange={internalOnChange} onAddItem={onAddItem} />
				),
				Processing: () => <ProcessingOrderRequest model={model} onCancel={() => onCancel(model)} />,
				Processed: () => (
					<ProcessedOrderRequest
						model={model}
						onChange={internalOnChange}
						onCancel={() => onCancel(model)}
					/>
				),
				Cancelled: () => <CancelledOrderRequest model={model} />,
				Finalised: () => <FinalisedOrderRequest model={model} />
			},
			() => null
		);
	}
);
