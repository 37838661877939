import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { PlusIcon } from '@heroicons/react/24/solid';
import { Control, Controller } from 'react-hook-form';
import { CollisionAreaData, CollisionAreaFormData, CollisionSeverity } from './types';

type ControlledCollisionAreaSelectionProps = Omit<
	InheritableElementProps<
		'button',
		{
			id: string;
			name: string;
			control: Control<CollisionAreaFormData>;
		}
	>,
	'onChange'
>;

export const ControlledCollisionAreaSelection = ({
	id,
	name,
	control,
	...rest
}: ControlledCollisionAreaSelectionProps) => {
	return (
		<Controller
			control={control}
			name={id}
			render={({ field }) => (
				<CollisionAreaSelection
					id={id}
					name={name}
					severity={field.value?.severity}
					readonly={field.value?.readonly}
					onChange={severity => {
						if (!severity) {
							return field.onChange(null);
						}
						const event: CollisionAreaData = {
							id,
							severity
						};
						field.onChange(event);
					}}
					{...rest}
				/>
			)}
		/>
	);
};

type CollisionAreaSelectionProps = InheritableElementProps<
	'button',
	{
		readonly?: boolean;
		id: string;
		name: string;
		severity?: CollisionSeverity;
		onChange: (severity: CollisionSeverity | undefined) => void;
	}
>;

export const CollisionAreaSelection = ({
	className,
	id,
	name,
	severity,
	readonly,
	onChange,
	...rest
}: CollisionAreaSelectionProps) => {
	const onClick = () => {
		if (readonly) {
			return;
		}

		switch (severity) {
			case 'Light':
				onChange('Medium');
				break;
			case 'Medium':
				onChange('Heavy');
				break;
			case 'Heavy':
				onChange(undefined);
				break;
			default:
				onChange('Light');
				break;
		}
	};

	return (
		<button
			type="button"
			data-testid={`collision-area-${id}`}
			aria-disabled={readonly ? 'true' : undefined}
			className={tlsx(
				'absolute rounded-full z-10 flex items-center justify-center select-none cursor-pointer w-6 h-6',
				{ 'cursor-disabled': readonly },
				className
			)}
			onClick={onClick}
			value={id}
			{...rest}
		>
			{severity ? (
				<div
					className={tlsx(
						'z-10 px-2.5 py-1 rounded-full text-white text-sm capitalize w-fit absolute transition-all select-none',
						{ 'bg-blue-600': severity === 'Light' },
						{ 'bg-yellow-600': severity === 'Medium' },
						{ 'bg-red-600': severity === 'Heavy' },
						{ 'bg-gray-400': readonly }
					)}
				>
					{severity}
				</div>
			) : (
				<PlusIcon className="w-6 p-1 bg-white ring-1 ring-blue-600 rounded-full text-blue-600" />
			)}
			<span className="sr-only select-none">Select position {name}</span>
		</button>
	);
};
