import { isDefined } from '@/app/utils/common';
import { decodeGapcPartIdentityKey, JobPart, PartSlotIds } from '@/sdk/lib';
import { isNil } from 'lodash-es';
import { v4 } from 'uuid';
import { DiagramAssemblyResources, PartsFormData, PartsSelection } from '../types';

export const createInitialPartsFormData = (
	parts: JobPart[],
	resources: DiagramAssemblyResources
): PartsFormData => {
	const res = parts
		.flatMap((part, order) => {
			const assemblies = part.assemblyIds.map(id => resources.assemblies.get(id)).filter(isDefined);

			const assembly = resources.parts.get(part.partIdentity)?.at(0);

			if (assemblies.length > 0) {
				const quantity = Math.floor(part.quantity / assemblies.length);
				return assemblies.map(({ id, description, partSlotIds, hcas }) =>
					createSelectionFromJobPart(
						id,
						{ ...part, quantity },
						description,
						id,
						partSlotIds,
						hcas,
						order
					)
				);
			}
			if (!isNil(assembly)) {
				return [
					createSelectionFromJobPart(
						assembly.id,
						part,
						assembly.description,
						assembly.id,
						assembly.partSlotIds,
						assembly.hcas,
						order
					)
				];
			}
			return [
				createSelectionFromJobPart(
					v4(),
					part,
					part.description ?? 'Unknown part',
					null,
					null,
					[],
					order
				)
			];
		})
		.filter(isDefined);

	return Object.fromEntries(res);
};

const createSelectionFromJobPart = (
	id: string,
	part: JobPart,
	description: string,
	assemblyId: string | null,
	partSlotIds: PartSlotIds | null,
	hcas: string[],
	order: number
): [string | null, PartsSelection] => {
	return [
		id,
		{
			...decodeGapcPartIdentityKey(part.partIdentity),
			quantity: part.quantity,
			description,
			partSlotIds,
			assemblyId,
			hcas,
			order
		}
	];
};
