import { tlsx } from '@/app/utils/tw-merge';
import { jobsQueries } from '@/sdk/react';
import { Transition } from '@headlessui/react';
import { useQueries } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';

type PageParams = {
	jobId: string;
};

const PartInterpretationLoaderPage = () => {
	const { jobId } = useParams<PageParams>();
	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	const [{ isFetching: isAssembliesLoading }, { isLoading: isPartsLoading }, { data }] = useQueries(
		{
			queries: [
				jobsQueries.getPartAssembliesTree({ jobId }),
				jobsQueries.listParts({ jobId }),
				jobsQueries.get({ jobId })
			]
		}
	);

	const isLoading = useMemo(
		() => isAssembliesLoading || isPartsLoading,
		[isAssembliesLoading, isPartsLoading]
	);

	return (
		<>
			{!isLoading && <Outlet />}
			<Transition
				as="div"
				show={isLoading}
				className="absolute inset-0 flex flex-col items-center justify-center gap-6 z-[90] bg-gray-50"
				leave="transition-opacity duration-500 delay-150"
				leaveFrom="opacity-100"
				leaveTo="opacity-5"
			>
				<div className="w-80 h-1 rounded-xl bg-gray-300 overflow-hidden flex items-center justify-start">
					<span
						className={tlsx('h-full bg-blue-600 w-full', {
							'animate-slow-progress': isLoading,
							'animate-fast-progress': !isLoading
						})}
					/>
				</div>
				<span className="text-xs font-medium text-gray-500">
					Identifying replacement parts
					{!isNil(data?.job.vehicle?.variant?.description) &&
						` for a ${data.job.vehicle.variant.description}`}
				</span>
			</Transition>
		</>
	);
};

export default PartInterpretationLoaderPage;
