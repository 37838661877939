import { shipping } from '@/sdk/reflect/reflect';

const countryCodeToName: { [key: number]: string } = {
	// todo: this is not a long term solution
	512: 'New Zealand'
};

export const formatAddress = (address: shipping.ShippingAddress): string => {
	const addressParts: string[] = [];

	// Build the street address part
	const streetAddress = (() => {
		if (address.unit && address.street_number && address.street_name) {
			return `${address.unit}/${address.street_number} ${address.street_name}`;
		}
		if (address.unit && address.street_name) {
			return `${address.unit}/${address.street_name}`;
		}
		if (address.street_number && address.street_name) {
			return `${address.street_number} ${address.street_name}`;
		}
		if (address.street_name) {
			return address.street_name;
		}
		return '';
	})();

	if (streetAddress) {
		addressParts.push(streetAddress);
	}

	// Add the suburb, if it exists
	if (address.suburb) {
		addressParts.push(address.suburb);
	}

	// Build the city, state, postcode part
	const cityStatePostcode = (() => {
		if (address.city && address.state && address.postcode) {
			return `${address.city}, ${address.state} ${address.postcode}`;
		}
		if (address.city && address.state) {
			return `${address.city}, ${address.state}`;
		}
		if (address.city && address.postcode) {
			return `${address.city}, ${address.postcode}`;
		}
		if (address.city) {
			return address.city;
		}
		return '';
	})();

	if (cityStatePostcode) {
		addressParts.push(cityStatePostcode);
	}

	// Convert country code to country name
	const countryName = address.country_code ? countryCodeToName[address.country_code] : undefined;

	// Add the country name, if it exists
	if (countryName) {
		addressParts.push(countryName);
	}

	// Join all parts with a comma and a space
	return addressParts.join(', ');
};
