import {
	fuzzyIncludes,
	fuzzyMatchScore,
	fuzzyStartsWith,
	upperToCapitalize
} from '@/app/utils/string';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ReactComponent as DoorIcon } from '@assets/parts/door-icon.svg';
import { CloseButton, Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import {
	ChevronRightIcon,
	MagnifyingGlassIcon,
	MapIcon,
	TagIcon,
	XMarkIcon
} from '@heroicons/react/24/outline';
import { Loader } from '@mantine/core';
import { Command } from 'cmdk';
import { compact, sortBy, uniq, uniqBy } from 'lodash-es';
import { ElementRef, Fragment, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { CategoryTree, CategoryTreeLeaf, DiagramAssembly } from '../../types';
import { categoryLeaves } from '../../utils';
import { assemblyClassificationSortKey, assemblyHcaSortKey } from '../../utils/sort';

type PartsSpotlightProps = InheritableElementProps<
	'div',
	{
		categories: CategoryTree[];
		other: CategoryTreeLeaf;
		open: boolean;
		search: string;
		actions: {
			view: (action: SetStateAction<boolean>) => void;
			filter: (query: string) => void;
			jump: {
				category: (categoryId: string) => void;
				diagram: (categoryId: string, diagramId: string) => void;
				part: (categoryId: string, diagramId: string, partSlotId: string) => void;
			};
			add: (assembly: DiagramAssembly) => void;
		};
	}
>;

export const PartsSpotlight = ({
	categories,
	other,
	open,
	search,
	actions
}: PartsSpotlightProps) => {
	const ref = useRef<ElementRef<'input'>>(null);
	const [value, setValue] = useState('');
	const [query, setQuery] = useState('');
	const [debouncedQuery, { isPending }] = useDebounce(query, 150);

	const leaves = useMemo(() => {
		const leaves = categories.flatMap(categoryLeaves);
		leaves.push(other);
		return leaves;
	}, [categories]);

	const { diagrams, parts } = useMemo(() => {
		const diagrams = uniqBy(
			leaves.flatMap(({ id, diagrams }) => diagrams.map(diagram => ({ category: id, diagram }))),
			({ diagram }) => diagram.id
		);
		const parts = uniqBy(
			diagrams
				.flatMap(({ category, diagram }) =>
					diagram.partSlots.map(partSlot => ({ category, diagram, partSlot }))
				)
				.flatMap(({ partSlot, ...rest }) =>
					partSlot.assemblies.map(assembly => ({ ...rest, partSlot: partSlot.id, assembly }))
				)
				.map(({ diagram, assembly, ...rest }) => ({
					...rest,
					diagram,
					assembly: {
						...assembly,
						hcas: assembly.hcas.length > 0 ? assembly.hcas : diagram.hcas
					}
				})),
			({ assembly }) => assembly.id
		);

		return {
			diagrams,
			parts
		};
	}, [categories]);

	const results = useMemo(() => {
		// todo (vincent) figure out what's the most likely parts to show up first
		if (!debouncedQuery) {
			return {
				categories: [],
				diagrams: [],
				parts: []
			};
		}

		const matchingCategories = sortBy(
			leaves.filter(({ description }) => fuzzyIncludes(description, debouncedQuery)),
			category => fuzzyMatchScore(category.description, debouncedQuery),
			category => category.description
		);

		const matchingDiagrams = sortBy(
			diagrams.filter(
				({ diagram }) =>
					fuzzyIncludes(diagram.description, debouncedQuery) ||
					fuzzyStartsWith(diagram.code.replaceAll('-', ''), debouncedQuery.replaceAll('-', ''))
			),
			({ diagram }) =>
				fuzzyStartsWith(diagram.code.replaceAll('-', ''), debouncedQuery.replaceAll('-', '')),
			({ diagram }) => fuzzyMatchScore(diagram.description, debouncedQuery),
			({ diagram }) => diagram.description
		);

		const matchingParts = sortBy(
			parts.filter(
				({ assembly }) =>
					fuzzyIncludes(assembly.description, debouncedQuery) ||
					fuzzyStartsWith(assembly.part.mpn.replaceAll('-', ''), debouncedQuery.replaceAll('-', ''))
			),
			({ assembly }) => fuzzyMatchScore(assembly.description, debouncedQuery),
			({ assembly }) => assemblyClassificationSortKey(assembly),
			({ assembly }) => assemblyHcaSortKey(assembly)
		);

		return {
			categories: matchingCategories.slice(0, 2),
			diagrams: matchingDiagrams.slice(0, 2),
			parts: matchingParts.slice(0, 3)
		};
	}, [categories, diagrams, parts, debouncedQuery]);

	const suggestions = useMemo(() => {
		if (!debouncedQuery) {
			return [];
		}

		const suggestions = compact(
			sortBy(
				parts.filter(
					({ assembly }) =>
						fuzzyStartsWith(assembly.description, debouncedQuery) ||
						fuzzyStartsWith(assembly.part.mpn, debouncedQuery)
				),
				({ assembly }) => assemblyClassificationSortKey(assembly),
				({ assembly }) => assemblyHcaSortKey(assembly)
			).map(({ assembly }) => assembly.partSlot?.gapcPartType?.name)
		);
		return uniq([
			upperToCapitalize(debouncedQuery),
			...sortBy(uniq(suggestions), suggestion => suggestion).slice(0, 2)
		]);
	}, [parts, debouncedQuery]);

	const selected = useMemo(() => {
		const [prefix, ...rest] = value.split(':');
		const id = rest.join(':');
		switch (prefix) {
			case 'suggestion': {
				const suggestion = suggestions.find(suggestion => suggestion === id);
				if (suggestion) {
					return { kind: 'suggestion' as const, suggestion };
				}
				return null;
			}
			case 'part': {
				const part = results.parts.find(({ assembly }) => assembly.id === id);
				if (part) {
					return { kind: 'part' as const, ...part };
				}
				return null;
			}
			case 'diagram': {
				const diagram = results.diagrams.find(({ diagram }) => diagram.id === id);
				if (diagram) {
					return { kind: 'diagram' as const, ...diagram };
				}
				return null;
			}
			case 'category': {
				const category = results.categories.find(category => category.id === id);
				if (category) {
					return { kind: 'category' as const, category };
				}
				return null;
			}
			default:
				return null;
		}
	}, [value, suggestions, results]);

	// keyboard shotcuts
	useEffect(() => {
		const shortcut = (e: KeyboardEvent) => {
			if (!selected) {
				return;
			}
			const cmdkey = e.metaKey || e.ctrlKey;
			const shiftKey = e.shiftKey;
			if (!cmdkey || !shiftKey) {
				return;
			}
			switch (e.key.toLowerCase()) {
				case 'j': {
					if (selected.kind === 'part') {
						e.preventDefault();
						actions.add(selected.assembly);
						actions.jump.part(selected.category, selected.diagram.id, selected.partSlot);
						actions.view(false);
						setQuery('');
						return;
					}
					break;
				}
				case 'k': {
					if (selected.kind === 'part' || selected.kind === 'diagram') {
						e.preventDefault();
						actions.jump.diagram(selected.category, selected.diagram.id);
						actions.view(false);
						setQuery('');
						return;
					}
					break;
				}
				case 'l': {
					if (selected.kind === 'part' || selected.kind === 'diagram') {
						e.preventDefault();
						actions.jump.category(selected.category);
						actions.view(false);
						setQuery('');
						return;
					}
					if (selected.kind === 'category') {
						e.preventDefault();
						actions.jump.category(selected.category.id);
						actions.view(false);
						setQuery('');
						return;
					}
					return;
				}
			}
		};
		document.addEventListener('keydown', shortcut);

		return () => document.removeEventListener('keydown', shortcut);
	}, [selected]);

	useEffect(() => {
		const shortcut = (e: KeyboardEvent) => {
			if (e.key === '/') {
				e.preventDefault();
				actions.view(prev => !prev);
			}
		};
		document.addEventListener('keydown', shortcut);

		return () => document.removeEventListener('keydown', shortcut);
	}, []);

	return (
		<>
			<div
				role="button"
				className={tlsx(
					'w-full flex items-center px-4 py-2 rounded-full bg-gray-100  max-w-lg lg:max-w-xl xl:max-w-2xl cursor-pointer',
					{
						'bg-white ring-1 ring-gray-200': search.length > 0
					}
				)}
				onClick={() => {
					actions.view(true);
					setQuery(search);
				}}
			>
				<MagnifyingGlassIcon className="w-4 h-4 mr-2" />
				{search ? (
					<>
						<span className="flex-1 text-start text-sm font-medium text-gray-900 truncate">
							Search: {search}
						</span>
						<button
							type="button"
							className="text-sm font-medium text-red-600"
							onClick={e => {
								e.stopPropagation();
								actions.filter('');
							}}
						>
							Remove
						</button>
					</>
				) : (
					<>
						<span className="flex-1 text-start text-sm font-medium text-gray-500 truncate">
							Search for parts, diagrams, and categories
						</span>
					</>
				)}
			</div>

			{/* Spotlight search */}
			<Transition appear show={open} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-50"
					initialFocus={ref}
					onClose={() => {
						actions.view(false);
						setQuery(search);
					}}
				>
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-100"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black/25 backdrop-blur-[1px]" />
					</TransitionChild>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex justify-center p-4 text-center">
							<TransitionChild
								as={Fragment}
								enter="ease-out duration-100"
								enterFrom="opacity-0 scale-95 -translate-y-5"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-100"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95 -translate-y-5"
							>
								<DialogPanel className="flex flex-col w-full max-w-lg lg:max-w-xl xl:max-w-2xl transform overflow-hidden rounded-3xl bg-white p-4 text-left align-middle shadow-xl transition-all">
									<Command
										shouldFilter={false}
										// always have the first command.item to be immediately available via ⏎ so that search query itself is always immediately selectable
										defaultValue=""
										value={value}
										onValueChange={setValue}
									>
										<div className="w-full flex items-center gap-4 px-2">
											<div className="flex items-center gap-3 flex-1 w-full py-2 px-4 rounded-full border">
												<MagnifyingGlassIcon className="w-4 h-4 text-gray-600" />
												<Command.Input
													className="outline-none w-full text-sm"
													data-autofocus
													placeholder="Search for parts, diagrams, and categories"
													value={query}
													onValueChange={setQuery}
												/>
												{isPending() ? (
													<Loader variant="dots" size="xs" />
												) : (
													query.length > 0 && (
														<button
															type="button"
															className="text-sm font-medium text-gray-500"
															onClick={() => setQuery('')}
														>
															Clear
														</button>
													)
												)}
											</div>
											<CloseButton className="p-1 rounded hover:bg-gray-100 active:bg-gray-100">
												<XMarkIcon className="w-4 h-4" />
											</CloseButton>
										</div>

										<Command.List>
											{suggestions.length > 0 && (
												<Command.Group
													className="mt-4"
													heading={
														<span className="text-xs font-semibold text-gray-800 px-2">
															Searching for
														</span>
													}
												>
													<div className="flex items-center w-full flex-wrap gap-3 mt-1 px-2">
														{suggestions.map(suggestion => (
															<Command.Item
																role="button"
																key={suggestion}
																value={`suggestion:${suggestion}`}
																className="flex items-center w-fit py-2 px-4 rounded-full border text-sm cursor-pointer text-gray-600 active:bg-gray-100 data-[selected=true]:bg-gray-100"
																onPointerOver={() => setValue(`suggestion:${suggestion}`)}
																onSelect={() => {
																	actions.filter(suggestion);
																	actions.view(false);
																	setQuery('');
																}}
															>
																{suggestion}
															</Command.Item>
														))}
													</div>
												</Command.Group>
											)}

											{/* Parts */}
											<Command.Group
												className={tlsx('mt-6', { hidden: results.parts.length === 0 })}
												heading={
													<span className="text-xs font-semibold text-gray-800 px-2">Parts</span>
												}
											>
												{results.parts.map(({ category, diagram, partSlot, assembly }) => (
													<Command.Item
														key={assembly.id}
														value={`part:${assembly.id}`}
														role="button"
														className="flex items-center w-full p-2 gap-3 rounded-lg cursor-pointer active:bg-gray-100 data-[selected=true]:bg-gray-100"
														onPointerOver={() => setValue(`part:${assembly.id}`)}
														onSelect={() => {
															actions.jump.part(category, diagram.id, partSlot);
															actions.view(false);
															setQuery('');
														}}
													>
														<div className="grid place-items-center p-2 rounded-lg bg-gray-100 active:bg-gray-200 data-[selected=true]:bg-gray-200">
															<DoorIcon className="w-6 h-6" />
														</div>
														<div className="flex flex-col items-start gap-1 flex-1">
															<span className="text-sm">{assembly.description}</span>

															<div className="flex items-center flex-wrap gap-0.5 w-full mt-0.5 empty:hidden">
																<span className="text-xs text-gray-500 mr-0.5">Part:</span>
																{assembly.hcas.length > 0 ? (
																	assembly.hcas.map((hca, index) => (
																		<Fragment key={`${hca}-${index}`}>
																			<span className="text-xs text-gray-500">{hca}</span>
																			<ChevronRightIcon className="w-2.5 h-2.5 last:hidden" />
																		</Fragment>
																	))
																) : (
																	<span className="text-xs text-gray-500">Other diagrams</span>
																)}
															</div>
														</div>
													</Command.Item>
												))}
											</Command.Group>

											{/* Diagrams */}
											<Command.Group
												className={tlsx('mt-6', { hidden: results.diagrams.length === 0 })}
												heading={
													<span className="text-xs font-semibold text-gray-800 px-2">Diagrams</span>
												}
											>
												{results.diagrams.map(({ category, diagram }) => (
													<Command.Item
														key={diagram.id}
														value={`diagram:${diagram.id}`}
														role="button"
														className="flex items-center w-full p-2 gap-3 rounded-lg cursor-pointer active:bg-gray-100 data-[selected=true]:bg-gray-100"
														onPointerOver={() => setValue(`diagram:${diagram.id}`)}
														onSelect={() => {
															actions.jump.diagram(category, diagram.id);
															actions.view(false);
															setQuery('');
														}}
													>
														<div className="grid place-items-center p-2 rounded-lg bg-gray-100 active:bg-gray-200 data-[selected=true]:bg-gray-200">
															<TagIcon className="w-6 h-6" />
														</div>
														<div className="flex flex-col items-start gap-1 flex-1">
															<span className="text-sm">
																{diagram.description} ({diagram.code})
															</span>

															<div className="flex items-center flex-wrap gap-0.5 w-full mt-0.5 empty:hidden">
																<span className="text-xs text-gray-500 mr-0.5">Diagram:</span>
																{diagram.hcas.length > 0 ? (
																	diagram.hcas.map((hca, index) => (
																		<Fragment key={`${hca}-${index}`}>
																			<span className="text-xs text-gray-500">{hca}</span>
																			<ChevronRightIcon className="w-2.5 h-2.5 last:hidden" />
																		</Fragment>
																	))
																) : (
																	<span className="text-xs text-gray-500">Other diagrams</span>
																)}
															</div>
														</div>
													</Command.Item>
												))}
											</Command.Group>

											{/* Categories */}
											<Command.Group
												className={tlsx('mt-6', { hidden: results.categories.length === 0 })}
												heading={
													<span className="text-xs font-semibold text-gray-800 px-2">
														Categories
													</span>
												}
											>
												{results.categories.map(category => (
													<Command.Item
														key={category.id}
														value={`category:${category.id}`}
														role="button"
														className="flex items-center w-full p-2 gap-3 rounded-lg cursor-pointer active:bg-gray-100 data-[selected=true]:bg-gray-100"
														onPointerOver={() => setValue(`category:${category.id}`)}
														onSelect={() => {
															actions.jump.category(category.id);
															actions.view(false);
															setQuery('');
														}}
													>
														<div className="grid place-items-center p-2 rounded-lg bg-gray-100 active:bg-gray-200 data-[selected=true]:bg-gray-200">
															<MapIcon className="w-6 h-6" />
														</div>
														<div className="flex flex-col items-start gap-1 flex-1">
															<span className="text-sm">{category.description}</span>

															<div className="flex items-center flex-wrap gap-0.5 w-full mt-0.5 empty:hidden">
																<span className="text-xs text-gray-500 mr-0.5">
																	{category.hcas.length === 0 ? 'Category' : 'Category:'}
																</span>
																{category.hcas.length > 0 &&
																	category.hcas.map((hca, index) => (
																		<Fragment key={`${hca}-${index}`}>
																			<span className="text-xs text-gray-500">{hca}</span>
																			<ChevronRightIcon className="w-2.5 h-2.5 last:hidden" />
																		</Fragment>
																	))}
															</div>
														</div>
													</Command.Item>
												))}
											</Command.Group>
										</Command.List>
									</Command>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};
