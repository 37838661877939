import { sdk } from '@/sdk/reflect';
import { api_error, draft_order_handler, order_handler } from '@/sdk/reflect/reflect';
import { queryOptions } from '@tanstack/react-query';
import { getConfig } from '../config';

export const queries = {
	draft_orders: {
		list: (payload: draft_order_handler.DraftOrdersListRequest) =>
			queryOptions<draft_order_handler.exp.DraftOrderListResponse, api_error.RepairerServerError>({
				queryKey: ['draft-orders', 'list', payload],
				queryFn: async () => {
					const { data, error } = await sdk(getConfig()).draft_orders.list(payload);
					if (error) {
						throw error;
					}

					return data;
				}
			})
	},
	orders: {
		list: (payload: order_handler.OrdersListRequest) =>
			queryOptions<order_handler.exp.OrdersListResponse, api_error.RepairerServerError>({
				queryKey: ['orders', 'list', payload],
				queryFn: async () => {
					const { data, error } = await sdk(getConfig()).orders.list(payload);
					if (error) {
						throw error;
					}

					return data;
				}
			})
	}
};
