import StandardDialog from '@/app/organisms/standard-dialog';
import { Button } from '@mantine/core';
import { ReactNode, createContext, useContext, useEffect, useRef } from 'react';
import { Blocker, BlockerFunction, useBlocker } from 'react-router-dom';

type UnsavedChangesContextProps = {
	setShouldBlock: (shouldBlock: boolean | BlockerFunction) => void;
};

const UnsavedChangesContext = createContext({} as UnsavedChangesContextProps);

export const useUnsavedChanges = (shouldBlock: boolean | BlockerFunction) => {
	const { setShouldBlock } = useContext(UnsavedChangesContext);

	useEffect(() => setShouldBlock(shouldBlock), [shouldBlock]);
};

type UnsavedChangesDialogProps = {
	blocker: Blocker;
	resetShouldBlock: () => void;
};

export const UnsavedChangesDialog = ({ blocker, resetShouldBlock }: UnsavedChangesDialogProps) => {
	return (
		<StandardDialog open={blocker.state === 'blocked'} onClose={() => blocker.reset?.()}>
			<StandardDialog.Title>Unsaved changes</StandardDialog.Title>
			<StandardDialog.Content>
				<p className="mb-6 text-sm text-gray-500">
					You have unsaved changes. Are you sure you want to leave this page?
				</p>
				<Button
					className="w-full"
					color="red"
					onClick={() => {
						blocker.proceed?.();
						resetShouldBlock();
					}}
				>
					Confirm
				</Button>
			</StandardDialog.Content>
		</StandardDialog>
	);
};

export const UnsavedChangesProvider = ({ children }: { children: ReactNode | null }) => {
	// using ref instead of a state
	// - because shouldBlock (boolean / function) is only checked during navigation (not a normal state)
	// - because shouldBlock is a function, it will cause way too many re-render
	const shouldBlock = useRef<boolean | BlockerFunction>(false);

	// There should only be 1 blocker for the entire application (prevent blocker to be stuck on "blocked" state and other issues)
	const blocker = useBlocker(({ currentLocation, nextLocation, ...rest }) => {
		if (currentLocation.pathname === nextLocation.pathname) {
			return false;
		}
		if (typeof shouldBlock.current === 'boolean') {
			return shouldBlock.current;
		}
		return shouldBlock.current({ currentLocation, nextLocation, ...rest });
	});

	const setShouldBlock = (newShouldBlock: boolean | BlockerFunction) => {
		shouldBlock.current = newShouldBlock;
	};

	return (
		<UnsavedChangesContext.Provider value={{ setShouldBlock }}>
			<UnsavedChangesDialog blocker={blocker} resetShouldBlock={() => setShouldBlock(false)} />
			{children}
		</UnsavedChangesContext.Provider>
	);
};
