import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';

export type ChatBubbleProps = InheritableElementProps<
	'div',
	{
		label: string;
		notes: string;
	}
>;

export const ChatBubble = ({ label, notes, className, ...rest }: ChatBubbleProps) => {
	return (
		<div className={tlsx('bg-gray-100 p-3 rounded-md', className)} {...rest}>
			<div className="text-sm font-medium text-gray-900">{label}</div>
			<p className="text-sm text-gray-900">{notes}</p>
		</div>
	);
};
