import { RequiredProperty } from '@/types/utilties';
import { TextInput, TextInputProps } from '@mantine/core';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

export type ControlledTextInputProps<T extends FieldValues> = Omit<
	TextInputProps,
	'name' | 'helperText' | 'error' | 'defaultValue'
> &
	RequiredProperty<UseControllerProps<T>, 'control'>;

export const ControlledTextInput = <T extends FieldValues>({
	control,
	name,
	defaultValue,
	rules,
	shouldUnregister,
	...fieldRest
}: ControlledTextInputProps<T>) => {
	const {
		fieldState: { error: controlError },
		field: controlRest
	} = useController({
		name,
		control,
		defaultValue,
		rules,
		shouldUnregister
	});

	return <TextInput error={controlError?.message} {...fieldRest} {...controlRest} />;
};
