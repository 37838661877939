import { InheritableElementProps } from '@/types/utilties';
import { ReactComponent as COM1Outline } from '@assets/vehicle-outline/COM1.svg';
import { ReactComponent as COM10Outline } from '@assets/vehicle-outline/COM10.svg';
import { ReactComponent as COM11Outline } from '@assets/vehicle-outline/COM11.svg';
import { ReactComponent as COM12Outline } from '@assets/vehicle-outline/COM12.svg';
import { ReactComponent as COM13Outline } from '@assets/vehicle-outline/COM13.svg';
import { ReactComponent as COM2Outline } from '@assets/vehicle-outline/COM2.svg';
import { ReactComponent as COM3Outline } from '@assets/vehicle-outline/COM3.svg';
import { ReactComponent as COM4Outline } from '@assets/vehicle-outline/COM4.svg';
import { ReactComponent as COM5Outline } from '@assets/vehicle-outline/COM5.svg';
import { ReactComponent as COM6Outline } from '@assets/vehicle-outline/COM6.svg';
import { ReactComponent as COM7Outline } from '@assets/vehicle-outline/COM7.svg';
import { ReactComponent as COM8Outline } from '@assets/vehicle-outline/COM8.svg';
import { ReactComponent as COM9Outline } from '@assets/vehicle-outline/COM9.svg';
import { ReactNode } from 'react';

export type VehicleOutlineProps = InheritableElementProps<
	'svg',
	{
		collisionMapId: string;
	}
>;

type OutlineComponent = (
	props: React.SVGProps<SVGSVGElement> & {
		title?: string | undefined;
	}
) => ReactNode;

const outlines: Record<string, OutlineComponent> = {
	COM1: COM1Outline,
	COM2: COM2Outline,
	COM3: COM3Outline,
	COM4: COM4Outline,
	COM5: COM5Outline,
	COM6: COM6Outline,
	COM7: COM7Outline,
	COM8: COM8Outline,
	COM9: COM9Outline,
	COM10: COM10Outline,
	COM11: COM11Outline,
	COM12: COM12Outline,
	COM13: COM13Outline
};

const VehicleOutline = ({ collisionMapId, ...rest }: VehicleOutlineProps) => {
	const Outline = outlines[collisionMapId];
	if (!Outline) {
		return null;
	}

	return <Outline {...rest} />;
};

export default VehicleOutline;
