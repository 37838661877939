import { sdk } from '@/sdk/reflect';
import { api_error, draft_order_handler, order_handler } from '@/sdk/reflect/reflect';
import { getConfig } from '../config';
import { mutationOptions } from '../utils/mutation-options';

export const mutations = {
	draft_orders: {
		ingest: mutationOptions<
			draft_order_handler.exp.DraftOrderIngestResponse,
			api_error.RepairerServerError,
			draft_order_handler.DraftOrderIngestRequest
		>({
			mutationKey: ['draft-orders', 'ingest'],
			mutationFn: async payload => {
				const { data, error } = await sdk(getConfig()).draft_orders.ingest(payload);
				if (error) {
					throw error;
				}

				return data;
			}
		})
	},
	orders: {
		update: mutationOptions<
			order_handler.exp.OrdersUpdateResponse,
			api_error.RepairerServerError,
			order_handler.OrdersUpdateRequest
		>({
			mutationKey: ['orders', 'update'],
			mutationFn: async payload => {
				const { data, error } = await sdk(getConfig()).orders.update(payload);
				if (error) {
					throw error;
				}

				return data;
			}
		})
	}
};
