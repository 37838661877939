/**
 * Get a new instance of `URLSearchParams` for operating
 *
 * @param search the current url search params
 */
export const getSearchParams = (search: string): URLSearchParams => {
	return new URLSearchParams(search);
};

/**
 * Get a list of values from the current search params
 *
 * @param key the key to search for corresponding values
 * @param params an instance of `URLSearchParams`
 * @param separator an optional separator to apply to values returned by the key
 *
 * @example "?category=shoes,socks" -> ["shoes", "socks"]
 * @example "?search=this+is+a+search+term" -> ["this is a search term"]
 */
export const getKeyValues = (
	key: string,
	params: URLSearchParams,
	separator?: string
): string[] => {
	if (!params.has(key)) {
		return [];
	}

	return getValues(params.get(key), separator);
};

/**
 * Get a list of values from the search param value
 *
 * @param value the value of the search param
 * @param separator an optional separator to split on
 */
export const getValues = (value: string | null, separator?: string): string[] => {
	if (!value) {
		return [];
	}

	if (!separator) {
		return [value];
	}

	return value.split(separator);
};

/**
 * Get the text value of the query at a specific key
 *
 * @param search the current url search params
 * @param key the key to search for corresponding values
 */
export const getSearchKeyValue = (search: string, key: string): string | null => {
	const searchParams: URLSearchParams = getSearchParams(search);
	return searchParams.get(key);
};

/**
 * Find the index of value in the match values
 *
 * @param value the value to search for
 * @param values the list of values extracted from search params
 */
const getIndex = (value: string, values: string[]): number => {
	return values.indexOf(value);
};

/**
 * Get new search params string with new value set.
 *
 * *Note*: Setting an empty string will remove the query param.
 *
 * @param search the current url search params
 * @param key the key to set a new values
 * @param value the value to set on the key
 *
 * @example "?search=boots" -> "?search=shoes"
 */
export const getSetSearchParams = (
	search: string,
	key: string,
	value: string | string[],
	seperator?: string
): URLSearchParams => {
	const searchParams: URLSearchParams = getSearchParams(search);
	if (value.length === 0) {
		searchParams.delete(key);
	} else if (Array.isArray(value)) {
		const values = value.join(seperator);
		searchParams.set(key, values);
	} else {
		searchParams.set(key, value);
	}

	return searchParams;
};

/**
 * Apply side effects to the search params
 * by removing any present keys.
 *
 * Sometimes when you set / change a search param it should
 * invalidate other search params.
 *
 * @param sideEffects the list of keys to remove
 * @param searchParams the params to operate on
 * @returns
 */
export const applySideEffects = (searchParams: URLSearchParams, sideEffects: string[] = []) => {
	const mutableParams = new URLSearchParams(searchParams);
	sideEffects.forEach(sideEffect => {
		mutableParams.delete(sideEffect);
	});

	return mutableParams;
};

/**
 * Get new search params string with added value
 *
 * @param search the current url search params
 * @param key the key to add a new values
 * @param value the value to add onto the key
 * @param separator an optional separator to split new values on
 */
export const getAddSearchParams = (
	search: string,
	key: string,
	value: string,
	separator?: string
): URLSearchParams => {
	const searchParams: URLSearchParams = getSearchParams(search);
	const values: string[] = getKeyValues(key, searchParams, separator);
	if (values.length === 0) {
		searchParams.set(key, value);
		return searchParams;
	}

	const index: number = getIndex(value, values);
	if (index > -1) {
		return searchParams;
	}

	values.push(value);
	const newValues: string = values.join(separator);
	searchParams.set(key, newValues);

	return searchParams;
};

/**
 * Get new search params string with removed values
 *
 * @param search the current url search params
 * @param key the key to remove a value
 * @param value the value to remove from the key
 * @param separator an optional separator to split multiple values
 */
export const getRemoveSearchParams = (
	search: string,
	key: string,
	value: string,
	separator?: string
): URLSearchParams => {
	const searchParams: URLSearchParams = getSearchParams(search);
	const values: string[] = getKeyValues(key, searchParams, separator);
	if (values.length === 0) {
		return searchParams;
	}

	if (!value) {
		searchParams.delete(key);
		return searchParams;
	}

	const index: number = getIndex(value, values);
	if (index === -1) {
		return searchParams;
	}

	values.splice(index, 1);
	if (values.length === 0) {
		searchParams.delete(key);
	} else {
		const newValues: string = values.join(separator);
		searchParams.set(key, newValues);
	}

	return searchParams;
};

/**
 * Get whether or not a value is present under the given
 * key
 *
 * @param search the current url search params
 * @param key the key to search for active value
 * @param value the value to search for under the key
 * @param separator an optional separator to split multiple values
 */
export const getSearchParamActive = (
	search: string,
	key: string,
	value: string,
	separator?: string
) => {
	const searchParams: URLSearchParams = getSearchParams(search);
	const values: string[] = getKeyValues(key, searchParams, separator);
	if (values.length === 0) {
		return false;
	}

	const index: number = getIndex(value, values);
	if (index > -1) {
		return true;
	}

	return false;
};

/**
 * Convenience method to `add` or `remove` a value at a specific
 * key.
 *
 * @param search the current url search params
 * @param key the key to toggle for active value
 * @param value the value to toggle for under the key
 * @param separator an optional separator to split multiple values
 */
export const getToggleSearchParams = (
	search: string,
	key: string,
	value: string,
	separator?: string
) => {
	if (getSearchParamActive(search, key, value, separator)) {
		return getRemoveSearchParams(search, key, value, separator);
	}
	return getAddSearchParams(search, key, value, separator);
};
