import { uniq, intersection, find } from 'lodash-es';

const collisionMapAreaLocations: Record<string, Record<string, string>> = {
	COM1: {
		UCA1: 'top-0 left-[15%]',
		UCA2: 'top-[-4%] left-[50%] -translate-x-1/2',
		UCA3: 'top-0 right-[15%] origin-top-right',
		UCA4: 'top-[13%] left-[10%]',
		UCA5: 'top-[13%] left-[50%] -translate-x-1/2',
		UCA6: 'top-[13%] right-[10%] origin-top-right',
		UCA7: 'top-[48%] left-[10%]',
		UCA8: 'top-[35%] left-[50%] -translate-x-1/2',
		UCA9: 'top-[48%] right-[10%] origin-top-right',
		UCA13: 'bottom-[20%] left-[10%] origin-bottom-left',
		UCA11: 'top-[61%] left-[50%] -translate-x-1/2',
		UCA20: 'bottom-[20%] right-[10%] origin-bottom-right',
		UCA17: 'bottom-[12%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA22: 'bottom-0 left-[15%] origin-bottom-left',
		UCA23: 'bottom-[-3%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA24: 'bottom-0 right-[15%] origin-bottom-right'
	},
	COM2: {
		UCA1: 'top-0 left-[15%]',
		UCA2: 'top-[-4%] left-[50%] -translate-x-1/2',
		UCA3: 'top-0 right-[15%] origin-top-right',
		UCA4: 'top-[13%] left-[10%]',
		UCA5: 'top-[13%] left-[50%] -translate-x-1/2',
		UCA6: 'top-[13%] right-[10%] origin-top-right',
		UCA7: 'top-[47%] left-[10%]',
		UCA8: 'top-[35%] left-[50%] -translate-x-1/2',
		UCA9: 'top-[47%] right-[10%] origin-top-right',
		UCA10: 'bottom-[30%] left-[10%] origin-bottom-left',
		UCA12: 'bottom-[30%] right-[10%] origin-bottom-right',
		UCA13: 'bottom-[10%] left-[10%] origin-bottom-left',
		UCA11: 'top-[61%] left-[50%] -translate-x-1/2',
		UCA20: 'bottom-[10%] right-[10%] origin-bottom-right',
		UCA17: 'bottom-[12%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA22: 'bottom-0 left-[15%] origin-bottom-left',
		UCA23: 'bottom-[-3%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA24: 'bottom-0 right-[15%] origin-bottom-right'
	},
	COM3: {
		UCA1: 'top-0 left-[15%]',
		UCA2: 'top-[-4%] left-[50%] -translate-x-1/2',
		UCA3: 'top-0 right-[15%] origin-top-right',
		UCA4: 'top-[12%] left-[10%]',
		UCA5: 'top-[12%] left-[50%] -translate-x-1/2',
		UCA6: 'top-[12%] right-[10%] origin-top-right',
		UCA7: 'top-[40%] left-[10%]',
		UCA8: 'top-[30%] left-[50%] -translate-x-1/2',
		UCA9: 'top-[40%] right-[10%] origin-top-right',
		UCA13: 'bottom-[20%] left-[10%] origin-bottom-left',
		UCA11: 'top-[61%] left-[50%] -translate-x-1/2',
		UCA20: 'bottom-[20%] right-[10%] origin-bottom-right',
		UCA15: 'bottom-[12%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA16: 'bottom-[3%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA22: 'bottom-0 left-[15%] origin-bottom-left',
		UCA23: 'bottom-[-4%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA24: 'bottom-0 right-[15%] origin-bottom-right'
	},
	COM4: {
		UCA1: 'top-0 left-[15%]',
		UCA2: 'top-[-4%] left-[50%] -translate-x-1/2',
		UCA3: 'top-0 right-[15%] origin-top-right',
		UCA4: 'top-[13%] left-[10%]',
		UCA5: 'top-[13%] left-[50%] -translate-x-1/2',
		UCA6: 'top-[13%] right-[10%] origin-top-right',
		UCA7: 'top-[42%] left-[10%]',
		UCA8: 'top-[30%] left-[50%] -translate-x-1/2',
		UCA9: 'top-[42%] right-[10%] origin-top-right',
		UCA10: 'bottom-[35%] left-[10%] origin-bottom-left',
		UCA11: 'top-[54%] left-[50%] -translate-x-1/2',
		UCA12: 'bottom-[35%] right-[10%] origin-bottom-right',
		UCA13: 'bottom-[17%] left-[10%] origin-bottom-left',
		UCA15: 'bottom-[18%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA20: 'bottom-[17%] right-[10%] origin-bottom-right',
		UCA16: 'bottom-[6%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA22: 'bottom-0 left-[15%] origin-bottom-left',
		UCA23: 'bottom-[-3%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA24: 'bottom-0 right-[15%] origin-bottom-right'
	},
	COM5: {
		UCA1: 'top-0 left-[15%]',
		UCA2: 'top-[-4%] left-[50%] -translate-x-1/2',
		UCA3: 'top-0 right-[15%] origin-top-right',
		UCA4: 'top-[13%] left-[10%]',
		UCA5: 'top-[13%] left-[50%] -translate-x-1/2',
		UCA6: 'top-[13%] right-[10%] origin-top-right',
		UCA7: 'top-[42%] left-[10%]',
		UCA8: 'top-[30%] left-[50%] -translate-x-1/2',
		UCA9: 'top-[42%] right-[10%] origin-top-right',
		UCA10: 'bottom-[32%] left-[10%] origin-bottom-left',
		UCA11: 'top-[54%] left-[50%] -translate-x-1/2',
		UCA12: 'bottom-[32%] right-[10%] origin-bottom-right',
		UCA13: 'bottom-[17%] left-[10%] origin-bottom-left',
		UCA17: 'bottom-[7%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA20: 'bottom-[17%] right-[10%] origin-bottom-right',
		UCA22: 'bottom-0 left-[15%] origin-bottom-left',
		UCA23: 'bottom-[-3%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA24: 'bottom-0 right-[15%] origin-bottom-right'
	},
	COM6: {
		UCA1: 'top-0 left-[15%]',
		UCA2: 'top-[-4%] left-[50%] -translate-x-1/2',
		UCA3: 'top-0 right-[15%] origin-top-right',
		UCA4: 'top-[13%] left-[10%]',
		UCA5: 'top-[13%] left-[50%] -translate-x-1/2',
		UCA6: 'top-[13%] right-[10%] origin-top-right',
		UCA7: 'top-[45%] left-[10%]',
		UCA8: 'top-[33%] left-[50%] -translate-x-1/2',
		UCA9: 'top-[45%] right-[10%] origin-top-right',
		UCA11: 'top-[65%] left-[50%] -translate-x-1/2',
		UCA13: 'bottom-[17%] left-[10%] origin-bottom-left',
		UCA17: 'bottom-[7%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA20: 'bottom-[17%] right-[10%] origin-bottom-right',
		UCA22: 'bottom-0 left-[15%] origin-bottom-left',
		UCA23: 'bottom-[-3%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA24: 'bottom-0 right-[15%] origin-bottom-right'
	},
	COM7: {
		UCA1: 'top-0 left-[15%]',
		UCA2: 'top-[-4%] left-[50%] -translate-x-1/2',
		UCA3: 'top-0 right-[15%] origin-top-right',
		UCA4: 'top-[13%] left-[10%]',
		UCA5: 'top-[13%] left-[50%] -translate-x-1/2',
		UCA6: 'top-[13%] right-[10%] origin-top-right',
		UCA7: 'top-[42%] left-[10%]',
		UCA8: 'top-[31%] left-[50%] -translate-x-1/2',
		UCA9: 'top-[42%] right-[10%] origin-top-right',
		UCA10: 'bottom-[35%] left-[10%] origin-bottom-left',
		UCA11: 'top-[60%] left-[50%] -translate-x-1/2',
		UCA12: 'bottom-[35%] right-[10%] origin-bottom-right',
		UCA13: 'bottom-[13%] left-[10%] origin-bottom-left',
		UCA17: 'bottom-[7%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA20: 'bottom-[13%] right-[10%] origin-bottom-right',
		UCA22: 'bottom-0 left-[15%] origin-bottom-left',
		UCA23: 'bottom-[-3%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA24: 'bottom-0 right-[15%] origin-bottom-right'
	},
	COM8: {
		UCA1: 'top-0 left-[15%]',
		UCA2: 'top-[-4%] left-[50%] -translate-x-1/2',
		UCA3: 'top-0 right-[15%] origin-top-right',
		UCA4: 'top-[13%] left-[10%]',
		UCA5: 'top-[13%] left-[50%] -translate-x-1/2',
		UCA6: 'top-[13%] right-[10%] origin-top-right',
		UCA7: 'top-[42%] left-[10%]',
		UCA8: 'top-[31%] left-[50%] -translate-x-1/2',
		UCA9: 'top-[42%] right-[10%] origin-top-right',
		UCA11: 'top-[47%] left-[50%] -translate-x-1/2',
		UCA15: 'top-[58%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA14: 'bottom-[20%] left-[10%] origin-bottom-left',
		UCA18: 'bottom-[20%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA21: 'bottom-[20%] right-[10%] origin-bottom-right',
		UCA19: 'bottom-[2%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA22: 'bottom-[-3%] left-[15%] origin-bottom-left',
		UCA23: 'bottom-[-4%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA24: 'bottom-[-3%] right-[15%] origin-bottom-right'
	},
	COM9: {
		UCA1: 'top-0 left-[15%]',
		UCA2: 'top-[-4%] left-[50%] -translate-x-1/2',
		UCA3: 'top-0 right-[15%] origin-top-right',
		UCA4: 'top-[13%] left-[10%]',
		UCA5: 'top-[13%] left-[50%] -translate-x-1/2',
		UCA6: 'top-[13%] right-[10%] origin-top-right',
		UCA7: 'top-[40%] left-[10%]',
		UCA8: 'top-[31%] left-[50%] -translate-x-1/2',
		UCA9: 'top-[40%] right-[10%] origin-top-right',
		UCA10: 'top-[55%] left-[10%] origin-bottom-left',
		UCA11: 'top-[47%] left-[50%] -translate-x-1/2',
		UCA12: 'top-[55%] right-[10%] origin-bottom-right',
		UCA15: 'top-[65%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA14: 'bottom-[16%] left-[10%] origin-bottom-left',
		UCA18: 'bottom-[16%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA21: 'bottom-[16%] right-[10%] origin-bottom-right',
		UCA19: 'bottom-[2%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA22: 'bottom-[-3%] left-[15%] origin-bottom-left',
		UCA23: 'bottom-[-4%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA24: 'bottom-[-3%] right-[15%] origin-bottom-right'
	},
	COM10: {
		UCA1: 'top-0 left-[15%]',
		UCA2: 'top-[-4%] left-[50%] -translate-x-1/2',
		UCA3: 'top-0 right-[15%] origin-top-right',
		UCA4: 'top-[12%] left-[10%]',
		UCA5: 'top-[6%] left-[50%] -translate-x-1/2',
		UCA6: 'top-[12%] right-[10%] origin-top-right',
		UCA7: 'top-[30%] left-[10%]',
		UCA8: 'top-[18%] left-[50%] -translate-x-1/2',
		UCA9: 'top-[30%] right-[10%] origin-top-right',
		UCA25: 'top-[50%] left-[10%]',
		UCA11: 'top-[55%] left-[50%] -translate-x-1/2',
		UCA26: 'top-[50%] right-[10%] origin-top-right',
		UCA13: 'bottom-[18%] left-[10%] origin-bottom-left',
		UCA17: 'bottom-[6%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA20: 'bottom-[18%] right-[10%] origin-bottom-right',
		UCA22: 'bottom-[-3%] left-[15%] origin-bottom-left',
		UCA23: 'bottom-[-4%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA24: 'bottom-[-3%] right-[15%] origin-bottom-right'
	},
	COM11: {
		UCA1: 'top-0 left-[15%]',
		UCA2: 'top-[-4%] left-[50%] -translate-x-1/2',
		UCA3: 'top-0 right-[15%] origin-top-right',
		UCA4: 'top-[12%] left-[10%]',
		UCA5: 'top-[6%] left-[50%] -translate-x-1/2',
		UCA6: 'top-[12%] right-[10%] origin-top-right',
		UCA7: 'top-[30%] left-[10%]',
		UCA8: 'top-[18%] left-[50%] -translate-x-1/2',
		UCA9: 'top-[30%] right-[10%] origin-top-right',
		UCA10: 'top-[50%] left-[10%]',
		UCA11: 'top-[55%] left-[50%] -translate-x-1/2',
		UCA12: 'top-[50%] right-[10%] origin-top-right',
		UCA13: 'bottom-[18%] left-[10%] origin-bottom-left',
		UCA17: 'bottom-[6%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA20: 'bottom-[18%] right-[10%] origin-bottom-right',
		UCA22: 'bottom-[-3%] left-[15%] origin-bottom-left',
		UCA23: 'bottom-[-4%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA24: 'bottom-[-3%] right-[15%] origin-bottom-right'
	},
	COM12: {
		UCA1: 'top-0 left-[15%]',
		UCA2: 'top-[-4%] left-[50%] -translate-x-1/2',
		UCA3: 'top-0 right-[15%] origin-top-right',
		UCA7: 'top-[20%] left-[10%]',
		UCA8: 'top-[11%] left-[50%] -translate-x-1/2',
		UCA9: 'top-[20%] right-[10%] origin-top-right',
		UCA25: 'top-[50%] left-[10%]',
		UCA11: 'top-[55%] left-[50%] -translate-x-1/2',
		UCA26: 'top-[50%] right-[10%] origin-top-right',
		UCA13: 'bottom-[18%] left-[10%] origin-bottom-left',
		UCA17: 'bottom-[6%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA20: 'bottom-[18%] right-[10%] origin-bottom-right',
		UCA22: 'bottom-[-3%] left-[15%] origin-bottom-left',
		UCA23: 'bottom-[-4%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA24: 'bottom-[-3%] right-[15%] origin-bottom-right'
	},
	COM13: {
		UCA1: 'top-0 left-[15%]',
		UCA2: 'top-[-4%] left-[50%] -translate-x-1/2',
		UCA3: 'top-0 right-[15%] origin-top-right',
		UCA7: 'top-[20%] left-[10%]',
		UCA8: 'top-[11%] left-[50%] -translate-x-1/2',
		UCA9: 'top-[20%] right-[10%] origin-top-right',
		UCA10: 'top-[50%] left-[10%]',
		UCA11: 'top-[55%] left-[50%] -translate-x-1/2',
		UCA12: 'top-[50%] right-[10%] origin-top-right',
		UCA13: 'bottom-[18%] left-[10%] origin-bottom-left',
		UCA17: 'bottom-[6%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA20: 'bottom-[18%] right-[10%] origin-bottom-right',
		UCA22: 'bottom-[-3%] left-[15%] origin-bottom-left',
		UCA23: 'bottom-[-4%] left-[50%] -translate-x-1/2 origin-bottom-left',
		UCA24: 'bottom-[-3%] right-[15%] origin-bottom-right'
	}
};

export const getCollisionAreaLocation = (collisionMapId: string, collisionAreaId: string) =>
	collisionMapAreaLocations[collisionMapId]?.[collisionAreaId] ?? '';

const collisionMapSupportedBodyStyles: Record<
	string,
	{ UvdbBodyNumDoors: string[]; UvdbBodyType: string[] }
> = {
	COM1: {
		UvdbBodyType: ['UBOT125'],
		UvdbBodyNumDoors: ['UBND14', 'UBND17']
	},
	COM2: {
		UvdbBodyType: ['UBOT125'],
		UvdbBodyNumDoors: ['UBND15', 'UBND16']
	},
	COM3: {
		UvdbBodyType: ['UBOT120'],
		UvdbBodyNumDoors: ['UBND14', 'UBND17']
	},
	COM4: {
		UvdbBodyType: ['UBOT118'],
		UvdbBodyNumDoors: ['UBND15', 'UBND16']
	},
	COM5: {
		UvdbBodyType: ['UBOT123'],
		UvdbBodyNumDoors: ['UBND15', 'UBND16']
	},
	COM6: {
		UvdbBodyType: ['UBOT121'],
		UvdbBodyNumDoors: ['UBND14', 'UBND17']
	},
	COM7: {
		UvdbBodyType: ['UBOT121'],
		UvdbBodyNumDoors: ['UBND15', 'UBND16']
	},
	COM8: {
		UvdbBodyType: ['UBOT122', 'UBOT109', 'UBOT108', 'UBOT107', 'UBOT133'],
		UvdbBodyNumDoors: ['UBND14']
	},
	COM9: {
		UvdbBodyType: ['UBOT122', 'UBOT109', 'UBOT108', 'UBOT107', 'UBOT133'],
		UvdbBodyNumDoors: ['UBND15', 'UBND17']
	},
	COM10: {
		UvdbBodyType: ['UBOT129'],
		UvdbBodyNumDoors: ['UBND14']
	},
	COM11: {
		UvdbBodyType: ['UBOT129'],
		UvdbBodyNumDoors: ['UBND15', 'UBND16', 'UBND17']
	},
	COM12: {
		UvdbBodyType: ['UBOT129'],
		UvdbBodyNumDoors: ['UBND14']
	},
	COM13: {
		UvdbBodyType: ['UBOT129'],
		UvdbBodyNumDoors: ['UBND15', 'UBND16', 'UBND17']
	}
};

export const getSupportedBodyNumDoors = (bodyTypeIds: string[]): string[] => {
	const collMapsBodyStyles = Object.values(collisionMapSupportedBodyStyles);

	// if no specific body type, return all supported body num doors
	const supportedBodyStylesForBodyType =
		bodyTypeIds.length > 0
			? collMapsBodyStyles.filter(v => !!intersection(bodyTypeIds, v.UvdbBodyType).length)
			: collMapsBodyStyles;

	return uniq(supportedBodyStylesForBodyType.flatMap(v => v.UvdbBodyNumDoors));
};

export const getSupportedBodyTypes = (numDoorsIds: string[]): string[] => {
	const collMapsBodyStyles = Object.values(collisionMapSupportedBodyStyles);

	// if no specific body num doors, return all supported body types
	const supportedBodyStylesForBodyNumDoors =
		numDoorsIds.length > 0
			? collMapsBodyStyles.filter(v => !!intersection(numDoorsIds, v.UvdbBodyNumDoors).length)
			: collMapsBodyStyles;

	return uniq(supportedBodyStylesForBodyNumDoors.flatMap(v => v.UvdbBodyType));
};

// Check if CM exists for this bodyTypeId.
// If numDoorsIds is not empty - also check that bodyTypeId is available with these num doors values
export const isSupportedBodyType = (bodyTypeId: string, numDoorsIds: string[]): boolean => {
	const collMapsBodyStyles = Object.values(collisionMapSupportedBodyStyles);

	return !!collMapsBodyStyles.find(
		v =>
			v.UvdbBodyType.includes(bodyTypeId) &&
			(!numDoorsIds.length || intersection(v.UvdbBodyNumDoors, numDoorsIds).length)
	);
};

export const isSupportedNumDoorsValue = (numDoorsId: string): boolean => {
	return !!find(collisionMapSupportedBodyStyles, v => v.UvdbBodyNumDoors.includes(numDoorsId));
};
