import { EmptyState } from '@/app/atoms/empty-state';
import JobNumberLabel from '@/app/features/vehicle/components/job-number-label';
import VehicleResultsForm, {
	VehicleResultsFormData
} from '@/app/features/vehicle/components/vehicle-results-form';
import { useVehicleSearch } from '@/app/features/vehicle/hooks/use-vehicle-search';
import { withSignedIn } from '@/app/hoc/with-access';
import { useAnalytics } from '@/app/hooks/use-analytics';
import { useSearchQuery } from '@/app/hooks/use-search-param';
import { RepairhubEvents } from '@/app/utils/analytics/events';
import { ALL_SITES_ID } from '@/config';
import { getEnvironmentKey } from '@/environment';
import { ReactComponent as VehicleIcon } from '@assets/vehicle.svg';
import { Card, Skeleton } from '@mantine/core';
import { SearchVehiclePayload } from '@sdk/lib';
import { jobsMutations } from '@sdk/react';
import { useMutation } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const VALID_MAKES = [
	'AUDI',
	'HYUNDAI',
	'LEXUS',
	'MAZDA',
	'MITSUBISHI',
	'SKODA',
	'SUBARU',
	'SUZUKI',
	'TOYOTA',
	'VOLKSWAGEN'
];

const VehicleResultsPage = () => {
	const navigate = useNavigate();
	const { logEvent } = useAnalytics();
	const { mutateAsync: upsertJobVehicle } = useMutation(jobsMutations.upsertVehicle);

	const siteIdParam = useSearchQuery('siteId', { defaultValues: [ALL_SITES_ID] });
	const siteId = siteIdParam.values.at(0)!;

	const [searchParams] = useSearchParams();
	const jobId = searchParams.get('jobId');
	const jobNumber = searchParams.get('jobNumber');
	const claimNumber = searchParams.get('claimNumber');
	const chassisNumber = searchParams.get('chassisNumber');
	const plateNumber = searchParams.get('plateNumber');
	const plateState = searchParams.get('plateState');

	const { state } = useLocation();
	const vehicleSearchParams = useMemo((): SearchVehiclePayload => {
		if (!isNil(state?.vehicleSearchParams)) {
			return state.vehicleSearchParams;
		}

		if (!isNil(plateNumber)) {
			return { vrm: { plateNumber, state: plateState } };
		}
		if (!isNil(chassisNumber)) {
			return { chassisNumber };
		}

		return {} as SearchVehiclePayload;
	}, [chassisNumber, plateNumber, state]);

	const { vehicles, chassis, aggregates, isLoading } = useVehicleSearch(vehicleSearchParams);

	const onVehicleSubmit = async (data: VehicleResultsFormData) => {
		if (!jobId) {
			return;
		}

		const { vehicleId, uvdbProperties, label } = data;
		let plateNumber;
		if ('vrm' in vehicleSearchParams) {
			plateNumber = vehicleSearchParams.vrm.plateNumber;
		}

		const vehicle = {
			chassisNumber: chassis,
			plateNumber,
			variant: {
				uvdbProperties,
				description: label,
				token: vehicleId
			}
		};

		await upsertJobVehicle({
			job_id: jobId,
			vehicle
		});

		logEvent(
			RepairhubEvents.job_vehicle_upserted({
				job_id: jobId,
				plateNumber,
				chassisNumber: chassis ?? undefined,
				variant: { description: label }
			})
		);

		navigate(`/job/${jobId}/collision`);
	};

	const isValidMake = vehicles.some(({ properties }) => {
		const environment = getEnvironmentKey();
		if (environment !== 'production') {
			// We only want to block in a production environment
			return true;
		}

		const make = properties.find(property => property.type === 'Make');
		if (!make) {
			return false;
		}

		return VALID_MAKES.includes(make.value.toString().toUpperCase());
	});

	if (!Object.keys(vehicleSearchParams).length || !jobId || !jobNumber) {
		return <Navigate to={`/jobs/create?siteId=${siteId}`} replace />;
	}

	return (
		<div className="space-y-2">
			<JobNumberLabel
				siteId={siteId}
				jobId={jobId}
				jobNumber={jobNumber}
				claimNumber={claimNumber ?? ''}
			/>
			<div>
				{isLoading ? (
					<>
						<dt className="mb-1 text-sm font-medium">Vehicle</dt>
						<Card withBorder>
							<Skeleton className="w-[20ch] h-6" />
							<Skeleton className="mt-1 w-[30ch] h-5" />
						</Card>
					</>
				) : vehicles.length === 0 ? (
					<EmptyState>
						<EmptyState.Icon as={VehicleIcon} />
						<EmptyState.Title>No vehicles found</EmptyState.Title>
						<EmptyState.Description>
							Make sure you have entered your vehicle correctly
						</EmptyState.Description>
					</EmptyState>
				) : !isValidMake ? (
					<EmptyState>
						<EmptyState.Icon as={VehicleIcon} />
						<EmptyState.Title>Non-Toyota vehicle not supported</EmptyState.Title>
						<EmptyState.Description>
							We currently only support jobs for Toyota vehicles. Other makes will be supported in
							the near future.
						</EmptyState.Description>
					</EmptyState>
				) : (
					<VehicleResultsForm
						siteId={siteId}
						chassis={chassis ?? undefined}
						vehicles={vehicles}
						aggregates={aggregates}
						onSubmit={onVehicleSubmit}
					/>
				)}
			</div>
		</div>
	);
};

export default withSignedIn(VehicleResultsPage);
