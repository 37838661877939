import React, { Suspense } from 'react';

type WithSuspenseProps = {
	Component: React.FunctionComponent;
	Fallback: React.FunctionComponent;
};

export const WithSuspense = ({ Component, Fallback }: WithSuspenseProps) => {
	return (
		<Suspense fallback={<Fallback />}>
			<Component />
		</Suspense>
	);
};
