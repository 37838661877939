import VehicleOutline from '@/app/atoms/vehicle-outline';
import { getCollisionAreaLocation } from '@/app/utils/collision-map';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { CollisionArea } from '@sdk/lib';
import { ReactNode, useMemo } from 'react';

type CollisionAreaWithLocation = CollisionArea & {
	location: string;
};

type VehicleCollisionAreasOutlineProps = InheritableElementProps<
	'div',
	{
		collisionMapId: string;
		collisionAreas: CollisionArea[];
		renderArea: (area: CollisionAreaWithLocation) => ReactNode;
	}
>;

const VehicleCollisionAreasOutline = ({
	collisionMapId,
	collisionAreas,
	className,
	renderArea,
	...rest
}: VehicleCollisionAreasOutlineProps) => {
	const areas = useMemo(
		() =>
			collisionAreas.map(
				({ id, name, hcas, partSlotIds }): CollisionAreaWithLocation => ({
					id,
					name,
					hcas,
					location: getCollisionAreaLocation(collisionMapId, id),
					partSlotIds: [...partSlotIds, ...hcas.flatMap(hca => hca.partSlotIds)]
				})
			),
		[collisionAreas, collisionMapId]
	);

	return (
		<div className={tlsx('relative p-6', className)} {...rest}>
			<VehicleOutline className="w-full h-full" collisionMapId={collisionMapId} />
			<div className="absolute inset-6">{areas.map(props => renderArea(props))}</div>
		</div>
	);
};

export default VehicleCollisionAreasOutline;
