import { formatSubtotal } from '@/app/utils/currency';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { ActionIcon, Button } from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';

type SupplySummaryToolbarProps = InheritableElementProps<
	'div',
	{
		isExanded?: boolean;
		totalParts: number;
		totalCost: number;
		onViewRequest: () => void;
		onSave: () => void;
		onClose: () => void;
	}
>;

export const SupplySummaryToolbar = forwardRef(
	(
		{
			className,
			totalCost,
			totalParts,
			isExanded,
			onViewRequest,
			onSave,
			onClose,
			...rest
		}: SupplySummaryToolbarProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		if (isExanded) {
			return (
				<div className={tlsx('flex items-center justify-between', className)} {...rest} ref={ref}>
					<ActionIcon onClick={onClose} variant="subtle" aria-label="Close slider" size="lg">
						<ChevronDownIcon className="w-5 h-5" />
					</ActionIcon>
					<Button onClick={onSave} variant="default">
						Save
					</Button>
				</div>
			);
		}

		const subtotal = formatSubtotal({ currency: 'GBP', price: totalCost, displayPrice: null });

		return (
			<div className={tlsx('flex items-center justify-between', className)} {...rest} ref={ref}>
				<div className="flex items-center gap-4">
					<dl className="flex gap-4 text-sm text-gray-600">
						<div className="flex items-center gap-2">
							<dt className="font-medium text-gray-900">Quantity:</dt>
							<dd>{totalParts}</dd>
						</div>
					</dl>
				</div>
				<div className="flex items-center gap-4">
					<dl className="flex items-center justify-between gap-2 font-medium text-gray-900 text-md">
						<dt>Order total:</dt>
						<dd>{subtotal}</dd>
					</dl>
					<Button onClick={onSave} variant="default">
						Save
					</Button>
					<Button onClick={onViewRequest}>View order requests</Button>
				</div>
			</div>
		);
	}
);
