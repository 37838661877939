import * as Sentry from '@sentry/react';
import ErrorState from '../error-state';

type ErrorStateBoundaryProps = Omit<Sentry.ErrorBoundaryProps, 'fallback'>;

const ErrorStateBoundary = (props: ErrorStateBoundaryProps) => {
	return <Sentry.ErrorBoundary fallback={<ErrorState />} {...props} />;
};

export default ErrorStateBoundary;
