import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { SupplyVendor } from '@sdk/lib';
import { memo } from 'react';
import { JobPartItemModel, JobPartItemSelection } from '../../models';
import { JobPartItem } from './subcomponents';

export type SupplySelectionFormProps = InheritableElementProps<
	'div',
	{
		jobParts: JobPartItemModel[];
		suppliers: SupplyVendor[];
		onOfferSelect: (prev: JobPartItemSelection | null, next: JobPartItemSelection) => void;
	}
>;

export const PartSupplySelector = memo(
	({ jobParts, suppliers, onOfferSelect, ...rest }: SupplySelectionFormProps) => {
		return (
			<div {...rest}>
				<div className="w-full p-2 overflow-x-auto">
					<SupplySelectionHeaders suppliers={suppliers} />
					<ul className="mt-2 space-y-4">
						{jobParts.map((part, index) => (
							<JobPartItem
								key={part.identity}
								item={part}
								className={index !== jobParts.length - 1 ? 'border-b pb-3' : ''}
								onChange={onOfferSelect}
							/>
						))}
					</ul>
				</div>
			</div>
		);
	}
);

type SupplySelectionProps = InheritableElementProps<
	'div',
	{
		suppliers: SupplyVendor[];
	}
>;

// Due to the nature of how the parts / suppliers are displayed, we need
// to have a separate component for the headers. Each radio group then
// uses their vendor id to aria-labelby the corosponding header.
//
// Note: the container scrolls horizontally, which is why we have set widths.
// If you change these, make sure it aligns with the radio groups.
const SupplySelectionHeaders = memo(({ suppliers, className, ...rest }: SupplySelectionProps) => (
	<div className={tlsx('flex text-sm font-semibold text-gray-900', className)} {...rest}>
		<div className="flex-shrink-0 mr-4 w-80">Parts</div>
		{suppliers.map(supplier => (
			<div key={supplier.id} className="flex-shrink-0 w-56 mr-2" id={`supplier-${supplier.id}`}>
				{supplier.name}
			</div>
		))}
	</div>
));
