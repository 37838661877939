import { AnalyticsCategories, AnalyticsEventPayload, EventParams } from './types';

type PayloadSchema<P> = {
	_schema: 'schema';
	__payload?: P;
};

// helper just for typing
export function schema<P extends AnalyticsEventPayload>(): PayloadSchema<P> {
	return {
		_schema: 'schema'
	};
}

export interface EventCreator<
	Type extends string,
	Payload extends AnalyticsEventPayload = Record<string, never>
> {
	(payload: Payload): EventParams<Type, Payload>;
}

function registerAnalyticsEventType<
	Type extends string,
	Payload extends AnalyticsEventPayload = Record<string, never>
>(eventType: Type, _payloadSchema?: PayloadSchema<Payload>): EventCreator<Type, Payload> {
	const factory = (payload: Payload): EventParams<Type, Payload> => {
		const e: EventParams<Type, Payload> = {
			e: eventType,
			c: AnalyticsCategories.Other,
			params: payload
		};

		return e;
	};
	factory.eventType = eventType;
	return factory;
}

type AnalyticsGroup<T extends string, C extends Record<string, EventCreator<any, any>>> = {
	[key in keyof C]: C[key];
} & { name: T } & {
	registerEvent<Type extends string, Payload extends AnalyticsEventPayload = Record<string, never>>(
		eventType: Type,
		_payloadSchema?: PayloadSchema<Payload>
	): AnalyticsGroup<
		T,
		C & {
			[key in Type]: EventCreator<`[${T}] ${Type}`, Payload>;
		}
	>;
};

/* eslint-disable-next-line */
export function analyticsGroup<T extends string>(name: T): AnalyticsGroup<T, {}> {
	return {
		name,
		registerEvent<Type extends string, Payload extends AnalyticsEventPayload>(
			eventType: Type,
			_payloadSchema?: PayloadSchema<Payload>
		) {
			(this as any)[eventType] = registerAnalyticsEventType(
				`[${name}] ${eventType}`,
				_payloadSchema
			);
			return this as any;
		}
	};
}
