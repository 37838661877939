import { DefaultError, UseMutationOptions } from '@tanstack/react-query';

// This emulates the queryOptions function from @tanstack/react-query
// to provide type safety for useMutation

export const mutationOptions = <
	TData = unknown,
	TError = DefaultError,
	TVariables = void,
	TContext = unknown
>(
	options: UseMutationOptions<TData, TError, TVariables, TContext>
) => {
	return options;
};
