import { isEqual } from 'lodash-es';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { VehicleSelectorProvider } from './context.ts';
import { Input } from './input.tsx';
import {
	VehicleSelectionContext,
	VehicleSelectorFormProps,
	VehicleSelectorFormSelection
} from './types.ts';

export const VehicleSelectorForm = ({
	data,
	onChange,
	children,
	...rest
}: VehicleSelectorFormProps) => {
	const lastState = useRef<VehicleSelectorFormSelection | null>(null);
	const formRef = useRef<HTMLFormElement | null>(null);

	const { control, watch, formState, reset } = useForm<VehicleSelectorFormSelection>({});

	const fieldState = watch();

	useEffect(() => {
		if (isEqual(lastState.current, fieldState)) {
			return;
		}

		lastState.current = fieldState;

		onChange(fieldState);
		reset(fieldState);
	}, [fieldState]);

	const context = useMemo<VehicleSelectionContext>(() => ({ data, control }), [data, control]);

	return (
		<VehicleSelectorProvider value={context}>
			<form noValidate {...rest} ref={formRef}>
				{children(formState)}
			</form>
		</VehicleSelectorProvider>
	);
};

VehicleSelectorForm.Input = Input;
