import { Select } from '@mantine/core';
import { UvdbPropertyAliases } from '@sdk/lib';
import { lowerCase } from 'lodash-es';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useVehicleSelector } from './context.ts';
import { InputItem, InputProps } from './types.ts';
import { createInputItems, getValueFromSuid, splitToWords } from './utils.ts';

const PROPERTY_ALIASES: UvdbPropertyAliases = {
	UvdbTransmissionMfrCode: 'Transmission Code',
	UvdbEngineDesignation: 'Engine Code',
	UvdbBodyNumDoors: 'Number of Doors',
	UvdbYear: 'Year of Production',
	UvdbMonth: 'Month of Production'
};

export const Input = ({ field, rules, ...rest }: InputProps) => {
	const { data, control } = useVehicleSelector();

	const label = PROPERTY_ALIASES[field] ?? splitToWords(field);

	const options = useMemo<InputItem[]>(() => createInputItems(data, field), [field, data]);

	return (
		<Controller
			control={control}
			name={field}
			rules={rules}
			render={({ field: { onChange, value, ...controlRest } }) => (
				<Select
					data-field={field}
					label={label}
					value={value?.id ?? ''}
					data={options}
					placeholder={`Select ${lowerCase(label)}`}
					disabled={false}
					clearable
					onChange={id => {
						const value = getValueFromSuid(field, id, data);
						onChange(value);
					}}
					{...controlRest}
					{...rest}
				/>
			)}
		/>
	);
};
