import { extend } from '@react-three/fiber';
import { ColorReplaceMaterial } from './color-replacement';

extend({ ColorReplaceMaterial });

declare module '@react-three/fiber' {
	interface ThreeElements {
		colorReplaceMaterial: typeof ColorReplaceMaterial;
	}
}
