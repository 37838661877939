import { ApiError as _ApiError } from '../../generated';
import { ApiError, ParseError, StorefrontResponse } from '../types';

/**
 * Execute method and return a `StorefrontResponse` with the result or error.
 *
 * @param method method that returns data of type `T`.
 */
export const execute = async <T>(method: () => Promise<T>): Promise<StorefrontResponse<T>> => {
	try {
		const data = await method();
		return {
			data,
			error: null
		};
	} catch (error) {
		// Error occurred in the API client
		if (error instanceof _ApiError) {
			const apiError = new ApiError(error);
			return {
				data: null,
				error: apiError
			};
		}

		// Error occurred while transforming the response data
		const parseError = new ParseError('Failed to parse data', { cause: error });
		return {
			data: null,
			error: parseError
		};
	}
};
