import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ActionIcon, NumberInput, NumberInputHandlers, NumberInputProps } from '@mantine/core';
import { ForwardedRef, forwardRef, useRef } from 'react';

type InputStepperProps = Omit<
	InheritableElementProps<'div', NumberInputProps>,
	'children' | 'hideControls' | 'handlersRef'
>;

const baseStyle =
	'w-8 h-auto bg-transparent border-none  hover:bg-gray-200 focus:outline-none focus:bg-gray-200';

export const InputStepper = forwardRef(
	({ className, ...rest }: InputStepperProps, ref: ForwardedRef<HTMLInputElement>) => {
		const handlers = useRef<NumberInputHandlers>();

		return (
			<div
				className={tlsx(
					'flex bg-gray-100 rounded-md focus-within:ring-2 focus-within:ring-theme-600',
					className
				)}
			>
				<ActionIcon
					variant="default"
					disabled={rest.disabled}
					onClick={() => handlers.current?.decrement()}
					className={tlsx(baseStyle, 'rounded-r-none')}
				>
					-
				</ActionIcon>
				<NumberInput
					styles={{
						input: {
							border: 'none',
							backgroundColor: 'transparent',
							textAlign: 'center',
							':focus': {
								outline: 'none'
							}
						}
					}}
					className="flex-1 h-full borer-none"
					ref={ref}
					hideControls
					handlersRef={handlers}
					{...rest}
				/>
				<ActionIcon
					variant="default"
					disabled={rest.disabled}
					onClick={() => handlers.current?.increment()}
					className={tlsx(baseStyle, 'rounded-l-none')}
				>
					+
				</ActionIcon>
			</div>
		);
	}
);
