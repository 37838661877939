import { useMemo } from 'react';
import { formatSelectionContextReflect } from '@/app/utils/part';
import { order } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { OrderTableItem } from '../../table-builder';

export type OrderSelection = Record<string, order.OrderItemStatus>;

export const useOrderTableItems = (
	modelItems: order.exp.OrderItem[],
	selection?: OrderSelection
) => {
	const items = useMemo<OrderTableItem[]>(() => {
		const newItems = modelItems.flatMap(item => {
			let name = '';
			if (item.buyable.type === 'external') {
				name = item.buyable.description;
			} else {
				name = formatSelectionContextReflect(item.context);
			}

			const status = selection?.[item.id] ?? item.status;

			const price = match<order.OrderItemStatus, number>(status, {
				arrived: () => item.price * item.quantity,
				pending: () => item.price * item.quantity,
				returned: ({ quantity }) => {
					return item.price * (item.quantity - quantity);
				}
			});

			return {
				id: item.id,
				name,
				quantity: item.quantity,
				return_policy: item.return_policy,
				grade: item.grade,
				status,
				price
			};
		});

		return newItems.sort((a, b) => a.name.localeCompare(b.name));
	}, [modelItems, selection]);

	const subtotal = useMemo(() => {
		return items.reduce((acc, item) => {
			const safePrice = item.price ?? 0;
			const price = match<order.OrderItemStatus, number>(item.status, {
				arrived: () => safePrice * item.quantity,
				pending: () => safePrice * item.quantity,
				returned: ({ quantity }) => {
					return safePrice * (item.quantity - quantity);
				}
			});

			return acc + price;
		}, 0);
	}, [items]);

	return { items, subtotal };
};
