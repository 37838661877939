import { capitalize } from 'lodash-es';

export const camelCaseIntoSentence = (word: string) =>
	capitalize(word.match(/($[a-z])|[A-Z][^A-Z]+/g)?.join(' ') ?? word);

export const upperToCapitalize = (word: string) => word.split(' ').map(capitalize).join(' ');

const lowercasedWithoutWhiteSpaces = (src: string) =>
	src.toLowerCase().replaceAll(' ', '').replaceAll('\n', '').replaceAll('\t', '');

export const fuzzyIncludes = (src: string | null | undefined, query: string) =>
	src ? lowercasedWithoutWhiteSpaces(src).includes(lowercasedWithoutWhiteSpaces(query)) : false;

export const fuzzyStartsWith = (src: string | null | undefined, query: string) =>
	src ? lowercasedWithoutWhiteSpaces(src).startsWith(lowercasedWithoutWhiteSpaces(query)) : false;

export const fuzzyMatchScore = (src: string | null | undefined, query: string) => {
	if (!src) {
		return 0;
	}

	const s = lowercasedWithoutWhiteSpaces(src);
	const q = lowercasedWithoutWhiteSpaces(query);

	// position score
	const position = s.indexOf(q);

	if (position === -1) {
		return 1;
	}

	// frequncy score
	const frequency = s.split(q).length - 1;

	return position - frequency;
};

const ngrams = (word: string, n = 2) => {
	const ngrams = new Set<string>();
	for (let i = 0; i < word.length - 1; i++) {
		ngrams.add(word.slice(i, i + n));
	}
	return ngrams;
};

const ngramSimilarity = (word1: string, word2: string, n = 2) => {
	const ngrams1 = ngrams(word1, n);
	const ngrams2 = ngrams(word2, n);

	const intersection = new Set([...ngrams1].filter(x => ngrams2.has(x)));
	return intersection.size / Math.max(ngrams1.size, ngrams2.size);
};

export const similarity = (src: string | null | undefined, query: string) => {
	if (!src) {
		return 0;
	}
	const source = lowercasedWithoutWhiteSpaces(src);
	const search = lowercasedWithoutWhiteSpaces(query);

	// if exact match, highest score
	if (search === source) {
		return 1.0;
	}
	// if search is just a prefix,
	if (source.startsWith(search)) {
		return search.length / source.length;
	}

	return ngramSimilarity(source, search, search.length - 1);
};
