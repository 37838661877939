import { MeasurementRaw } from '@/sdk/generated';
import { Measurement } from '../types';

export const createMeasurements = (
	measurement: MeasurementRaw | null | undefined
): Measurement | null =>
	measurement
		? {
				widthMm: measurement.width_mm ?? null,
				heightMm: measurement.height_mm ?? null,
				lengthMm: measurement.length_mm ?? null,
				weightG: measurement.weight_g ?? null
			}
		: null;
