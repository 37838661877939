import { GapcAttributeValueRaw } from '@/sdk/generated';
import { GapcAttributeValue } from '../types';
import { Context } from '../utils/context';

export const createGapcAttribute = (
	attribute: GapcAttributeValueRaw,
	ctx: Context
): GapcAttributeValue | null => {
	const gapcProperty = ctx.resources.get({
		path: 'gapc_properties',
		id: attribute.gapc_attribute_id
	});
	if (!gapcProperty) {
		return null;
	}

	if (gapcProperty.kind !== 'Attribute') {
		// Should never happen
		return null;
	}

	return {
		id: attribute.id,
		gapcProperty: {
			id: gapcProperty.id,
			name: gapcProperty.name
		},
		dataType: gapcProperty.data_type,
		value: attribute.value
	};
};

export const createGapcAttributes = (
	attributes: GapcAttributeValueRaw[],
	ctx: Context
): GapcAttributeValue[] => {
	return (
		attributes
			?.map(attribute => createGapcAttribute(attribute, ctx))
			.filter((item): item is GapcAttributeValue => !!item)
			.sort((a, b) => a.gapcProperty.name.localeCompare(b.gapcProperty.name)) ?? []
	);
};
