import { useScrollOffset } from '@/app/hooks/use-scroll-offset';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { useDeferredValue } from 'react';
import { RootTemplate } from '../root-template';

export const PageTemplate = ({
	children,
	className,
	...rest
}: InheritableElementProps<'div', object>) => {
	return (
		<div className={tlsx('p-6 flex flex-1 flex-col', className)} {...rest}>
			{children}
		</div>
	);
};

const Header = ({
	children,
	borderOnScroll = false,
	className,
	...rest
}: InheritableElementProps<'div', { borderOnScroll?: boolean }>) => {
	const offset = useScrollOffset();
	const deferredOffset = useDeferredValue(offset);

	return (
		<RootTemplate.Header>
			<div
				className={tlsx(
					'py-4 px-6 bg-white border-b border-transparent transition-colors relative',
					{ 'border-gray-200': !borderOnScroll || (borderOnScroll && deferredOffset > 0) },
					className
				)}
				{...rest}
			>
				{children}
			</div>
		</RootTemplate.Header>
	);
};

// Just for convience and consistency
PageTemplate.Header = Header;
