import { shaderMaterial as _shaderMaterial } from '@react-three/drei';
import { ReactThreeFiber, ShaderMaterialProps } from '@react-three/fiber';
import { ShaderMaterial } from 'three';

type ShaderAdditionalProps = Parameters<typeof _shaderMaterial>[0];
type WebGLSourceString = `${string}void main()${string}`;

export type ShaderMaterialNode<Props extends ShaderAdditionalProps> = ReactThreeFiber.Object3DNode<
	ShaderMaterial & Props,
	ShaderMaterialProps & Props
>;

export const shaderMaterial = <Props extends ShaderAdditionalProps>(
	defaultValue: Props,
	vertexShader: WebGLSourceString,
	fragmentShader: WebGLSourceString,
	onInit?: (material?: ShaderMaterial) => void
) => {
	const material = _shaderMaterial(defaultValue, vertexShader, fragmentShader, onInit);

	return material as ShaderMaterialNode<Props>;
};
