import { order } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { memo } from 'react';
import { AcknowledgedOrder } from '../acknowledged-order';
import { CancelledOrder } from '../cancelled-order';
import { PendingOrder } from '../pending-order';

type OrderRequestBuilderProps = {
	jobId: string;
	model: order.exp.Order;
};

export const OrderBuilder = memo(({ jobId, model }: OrderRequestBuilderProps) => {
	// We want a unique key that only updates if
	// there was a change in the model. This ensures that
	// the form default values are created.
	const key = `${model.id}.${model.updated_at ?? model.created_at}`;

	return match<order.OrderStatus, React.ReactNode>(
		model.status,
		{
			ordered: () => <PendingOrder key={key} model={model} />,
			acknowledged: () => <AcknowledgedOrder key={key} jobId={jobId} model={model} />,
			cancelled: () => <CancelledOrder key={key} model={model} />
		},
		() => null
	);
});
