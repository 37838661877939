import { useEffect, useState } from 'react';

export const useScrollOffset = () => {
	const [scrollOffset, setScrollOffset] = useState(0);

	useEffect(() => {
		const onScroll = () => {
			setScrollOffset(window.pageYOffset);
		};

		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	return scrollOffset;
};
