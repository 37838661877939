import { useSuspenseQuery } from '@tanstack/react-query';
import { ElementRef, ReactEventHandler, useEffect, useState } from 'react';

type ImageSizes = {
	width: number;
	height: number;
	naturalWidth: number;
	naturalHeight: number;
};

export const useImageDimensions = () => {
	const [imageElement, setImageElement] = useState<ElementRef<'img'> | null>(null);
	const [imageSizes, setImageSizes] = useState<Record<string, ImageSizes>>({});

	const onLoad: ReactEventHandler<HTMLImageElement> = ev => {
		setImageElement(ev.currentTarget);
	};

	useEffect(() => {
		if (!imageElement) {
			return;
		}

		const onResize = () => {
			const size: ImageSizes = {
				width: imageElement.width,
				height: imageElement.height,
				naturalWidth: imageElement.naturalWidth,
				naturalHeight: imageElement.naturalHeight
			};
			setImageSizes(prev => ({ ...prev, [imageElement.src]: size }));
		};

		onResize();

		// Listen if the image itself changes without any change to the screensize (scrollbar appearing)
		const observer = new ResizeObserver(onResize);
		observer.observe(imageElement);

		// Listen if the window / screen got resized
		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
			observer.disconnect();
		};
	}, [imageElement, setImageSizes]);

	return {
		imageSizes,
		onLoad
	};
};

// Same stuff but use suspense instead and doesn't rely on the image being in the dom
export const useImageSizes = (src: string | undefined | null) => {
	const { data: imageSizes } = useSuspenseQuery({
		queryKey: ['image-dimensions', src ?? null],
		queryFn: async () => {
			if (!src) {
				return null;
			}
			const img = new Image();
			return await new Promise<ImageSizes>(res => {
				img.onload = () => {
					const size: ImageSizes = {
						width: img.width,
						height: img.height,
						naturalWidth: img.naturalWidth,
						naturalHeight: img.naturalHeight
					};
					res(size);
				};
				img.src = src;
			});
		}
	});
	return imageSizes;
};
