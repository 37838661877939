import {
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';

type MeasureKey = 'navigation-bar' | 'tab-bar';

type MeasureContext = {
	measurements: Map<MeasureKey, DOMRectReadOnly>;
	setMeasurements: (key: MeasureKey, value: DOMRectReadOnly) => void;
};

const MeasureContext = createContext<MeasureContext>(
	// Liest, but we provide the values before render
	{} as MeasureContext
);

export const useMeasurement = (key: MeasureKey) => {
	const context = useContext(MeasureContext);
	const trackRef = useRef<HTMLElement | null>(null);

	const value = useMemo(() => context.measurements.get(key), [context.measurements]);

	useEffect(() => {
		if (!trackRef.current) {
			return;
		}

		const observer = new ResizeObserver(entries => {
			if (entries.length === 0) {
				return;
			}

			const boundingRect = entries[0].target.getBoundingClientRect();
			context.setMeasurements(key, boundingRect);
		});

		observer.observe(trackRef.current);
		return () => observer.disconnect();
	}, []);

	return {
		value,
		trackRef
	};
};

const MeasureProvider = ({ children }: PropsWithChildren<unknown>) => {
	const [measurements, _setMeasurements] = useState<Map<MeasureKey, DOMRectReadOnly>>(new Map());

	const setMeasurements = (key: MeasureKey, value: DOMRectReadOnly) => {
		_setMeasurements(val => {
			const mutatable = new Map(val);
			mutatable.set(key, value);
			return mutatable;
		});
	};

	return (
		<MeasureContext.Provider value={{ measurements, setMeasurements }}>
			{children}
		</MeasureContext.Provider>
	);
};

export default MeasureProvider;
