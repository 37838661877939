import StandardDialog from '@/app/organisms/standard-dialog';
import { encodeGapcPartIdentityKey } from '@/sdk/lib';
import { InheritableElementProps } from '@/types/utilties';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextInput } from '@mantine/core';
import { isNil } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DiagramAssembly, DiagramAssemblyResources } from '../../types';

const schema = yup.object({
	mpn: yup.string().required().label('Mpn'),
	description: yup.string().label('Description'),
	id: yup.string().nullable().label('Assembly ID')
});

export type PartsCustomPartData = yup.InferType<typeof schema>;

type PartsCustomPartProps = InheritableElementProps<
	'div',
	{
		open: boolean;
		gapcBrandId: string;
		resources: DiagramAssemblyResources;
		initialDescription?: string;
		onSubmit: (data: PartsCustomPartData & { assembly?: DiagramAssembly | null }) => void;
		onClose: () => void;
	}
>;

export const PartsCustomPart = ({
	className,
	open,
	gapcBrandId,
	resources,
	initialDescription,
	onSubmit,
	onClose,
	...rest
}: PartsCustomPartProps) => {
	const { control, watch, handleSubmit, reset } = useForm<PartsCustomPartData>({
		defaultValues: {
			description: initialDescription ?? '',
			id: null,
			mpn: ''
		},
		resolver: yupResolver(schema),
		shouldUnregister: true
	});

	const mpn = watch('mpn');

	const assembly = useMemo(() => {
		if (!mpn) {
			return null;
		}
		const identity = encodeGapcPartIdentityKey({ gapcBrandId, mpn });
		return resources.parts.get(identity)?.at(0);
	}, [mpn]);

	const submit = (data: PartsCustomPartData) => {
		onSubmit({ ...data, id: assembly?.id, assembly });
		reset();
	};

	useEffect(() => {
		reset({
			description: initialDescription ?? '',
			id: null,
			mpn: ''
		});
	}, [initialDescription]);

	return (
		<StandardDialog className={className} open={open} onClose={onClose}>
			<StandardDialog.Title>Add custom part</StandardDialog.Title>
			<StandardDialog.Content className="w-full min-w-[32rem] max-w-2xl">
				<div {...rest}>
					<div className="space-y-4">
						<Controller
							control={control}
							name="mpn"
							render={({ field: { value, ...field } }) => (
								<TextInput
									label="Part Number"
									placeholder="Mpn"
									className="col-span-4"
									required
									value={value ?? ''}
									{...field}
								/>
							)}
						/>
						<Controller
							control={control}
							name="description"
							render={({ field: { value, ...field } }) => (
								<TextInput
									label="Part name"
									placeholder="Part name"
									className="w-full"
									required
									disabled={!isNil(assembly)}
									value={assembly?.description ?? value ?? ''}
									{...field}
								/>
							)}
						/>
					</div>
					<div className="flex items-center justify-end gap-3 mt-12">
						<Button variant="default" type="button" onClick={onClose}>
							Cancel
						</Button>
						<Button type="button" onClick={handleSubmit(submit)}>
							Add part
						</Button>
					</div>
				</div>
			</StandardDialog.Content>
		</StandardDialog>
	);
};
