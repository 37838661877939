import { queries } from '@/sdk/react/queries';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { draft_order } from '@/sdk/reflect/reflect';
import { getLatestDate, restoreOrderRequestModel, sanitiseOrderId } from '../../order-request';
import { DraftOrderSelection } from '../../models';

export const useBackgroundDraftOrderRefetch = (
	jobId: string,
	form: UseFormReturn<DraftOrderSelection>
) => {
	const selection = form.watch();

	const isProcessing = (status: draft_order.DraftOrderStatus): boolean => {
		return typeof status === 'object' && 'Processing' in status;
	};

	// Work out if we need to background refetch, transitions
	// processing orders in processed.
	const isEnabled = useMemo(() => {
		return Object.values(selection.draft_orders).some(item => isProcessing(item.status));
	}, [selection]);

	const { data } = useQuery({
		...queries.draft_orders.list({ job_id: jobId }),
		enabled: isEnabled,
		refetchInterval: 30_000
	});

	useEffect(() => {
		const processedOrders = data?.draft_orders.filter(order => order.status === 'Processed');
		const dates: Date[] = [];
		for (const order of processedOrders ?? []) {
			// Any remote order (has an order.id) will be keyed in selection
			// under local id. Local id is a sanitised version of the order id
			// when remote, or a uuid when only exists locally.
			const localOrderId = sanitiseOrderId(order.id);

			// We only want to update items that are currently stored
			// as processing.
			if (!isProcessing(selection.draft_orders[localOrderId].status)) {
				continue;
			}

			for (const item of order.items) {
				dates.push(item.arrival_at ? new Date(item.arrival_at) : new Date());
			}

			const model = restoreOrderRequestModel(order);
			form.setValue(`draft_orders.${model.local_id}`, model);
		}

		// We need to update the latest delivery date
		// from the processed orders.
		if (dates.length) {
			const latestDate = getLatestDate(dates);
			form.setValue('delivery_date', latestDate);
		}
	}, [data]);
};
