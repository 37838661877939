import { updateConfig } from '@sdk/react';
import { JWTPayload, decodeJwt } from 'jose';
import { isString } from 'lodash-es';
import { EventBus, createEventDefinition } from 'ts-bus';

const ACCESS_TOKEN = 'access-token';

export type AuthTokens = {
	accessToken: string | null;
};

export type AuthState = {
	isAuthenticated: boolean;
	userId?: string;
};

export const authBus = new EventBus();
export const onAuthChange = createEventDefinition<AuthState>()('auth.signIn');

export const resolveAuth = async (): Promise<AuthState> => {
	const tokens = getAuthTokens();
	if (!tokens.accessToken) {
		// Todo: in future, attempt to extend via
		// refresh token.

		return {
			isAuthenticated: false
		};
	}

	const jwt = decodeJwt(tokens.accessToken);

	const isAuthenticated = isTokenValid(jwt);
	if (isAuthenticated) {
		setSdkToken(tokens.accessToken);
	}

	const userId = getUserId(jwt);

	return {
		isAuthenticated,
		userId
	};
};

const isTokenValid = (jwt: JWTPayload): boolean => {
	if (!jwt.exp) {
		return false;
	}

	const nowInSeconds = new Date().getTime() / 1000;
	return jwt.exp >= nowInSeconds;
};

const getUserId = (jwt: JWTPayload): string | undefined => {
	if ('id' in jwt && isString(jwt.id)) {
		return jwt.id;
	}

	return undefined;
};

export const signIn = (token: string) => {
	const userId = getUserId(decodeJwt(token));

	localStorage.setItem(ACCESS_TOKEN, token);
	setSdkToken(token);
	authBus.publish(onAuthChange({ isAuthenticated: true, userId }));
};

export const signOut = () => {
	localStorage.removeItem(ACCESS_TOKEN);
	setSdkToken(undefined);
	authBus.publish(onAuthChange({ isAuthenticated: false }));
};

const getAuthTokens = (): AuthTokens => {
	const accessToken = localStorage.getItem(ACCESS_TOKEN);
	return {
		accessToken
	};
};

const setSdkToken = (token: string | undefined) => {
	if (!token) {
		updateConfig({ userToken: undefined });
	} else {
		updateConfig({ userToken: { kind: 'account', token } });
	}
};
