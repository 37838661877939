import { useAuthenticatedUser } from '@/app/common/hooks/use-me';
import StandardDialog, { StandardDialogProps } from '@/app/organisms/standard-dialog';
import { createMoney } from '@/app/utils/currency';
import { ListingGrade } from '@/sdk/lib';
import { isDefined } from '@/sdk/lib/utils/object';
import { draft_order } from '@/sdk/reflect/reflect';
import { InheritableElementProps } from '@/types/utilties';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, NumberInput, SegmentedControl, Select, TextInput } from '@mantine/core';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { AddItemPayload, DraftOrderItemModel } from '../../models';

const schema = yup.object({
	supplier_id: yup.string().required().label('Supplier'),
	quantity: yup.number().required().min(1).label('Quantity'),
	name: yup.string().required().label('Part mame'),
	grade: yup.string().label('Grade'),
	price: yup.number().label('Price'),
	sku: yup.string().label('SKU'),
	brand: yup.string().label('SKU'),
	mpn: yup.string().label('Part number'),
	identity: yup.string()
});

const grades: { value: ListingGrade; label: string }[] = [
	{ label: 'New', value: '0' },
	{ label: 'Used A', value: 'A' },
	{ label: 'Used B', value: 'B' },
	{ label: 'Used C', value: 'C' },
	{ label: 'Used D', value: 'D' }
];

export type AddExternalItemModalData = yup.InferType<typeof schema>;

export type AddExternalItemModalProps = Omit<
	InheritableElementProps<
		'form',
		{
			defaultValues?: Partial<AddExternalItemModalData>;
			onSubmit: (data: AddItemPayload) => void;
		}
	>,
	'noValidate'
> &
	StandardDialogProps;

const defaultValue = {
	brand: '',
	grade: '',
	mpn: '',
	name: '',
	quantity: 1,
	sku: '',
	supplier_id: '',
	identity: 'mpn'
};

export const AddExternalItemModal = ({
	open,
	defaultValues,
	onClose,
	onSubmit,
	...rest
}: AddExternalItemModalProps) => {
	const { vendors } = useAuthenticatedUser();
	const { handleSubmit, control, watch, reset } = useForm<AddExternalItemModalData>({
		defaultValues: defaultValues ?? defaultValue,
		resolver: yupResolver(schema)
	});

	useEffect(() => {
		reset(defaultValues);
	}, [defaultValues]);

	const identity = watch('identity');
	const onResolve = (data: AddExternalItemModalData) => {
		const vendor = vendors.find(vendor => vendor.id === data.supplier_id);
		if (!vendor) {
			// Won't happen
			return;
		}

		let identity: draft_order.DraftOrderItemExternalIdentity | null = null;
		if ((!data.identity || data.identity === 'mpn') && !!data.mpn?.length) {
			identity = {
				Part: {
					mpn: data.mpn,
					brand: data.brand ?? null
				}
			};
		} else if (data.identity === 'sku' && !!data.sku?.length) {
			identity = {
				Sku: data.sku
			};
		}

		let grade = null;
		if (data.grade) {
			grade = data.grade as ListingGrade;
		}

		const price = data.price ? createMoney(data.price) : null;
		const item: DraftOrderItemModel = {
			arrival_at: null,
			id: null,
			local_id: v4(),
			name: data.name,
			price,
			order_separately: false,
			grade,
			quantity: data.quantity,
			status: 'Pending',
			context: null,
			buyable: {
				type: 'External',
				description: data.name,
				identity
			}
		};

		onSubmit({
			vendor,
			item
		});

		reset(defaultValue);
	};

	return (
		<StandardDialog open={open} onClose={onClose}>
			<StandardDialog.Title>Add Item</StandardDialog.Title>
			<StandardDialog.Content className="w-full max-w-2xl">
				<form onSubmit={handleSubmit(onResolve)} noValidate {...rest}>
					<div className="space-y-4">
						<Controller
							control={control}
							name="supplier_id"
							render={({ field, fieldState }) => (
								<Select
									withinPortal
									required
									className="w-full"
									error={fieldState?.error?.message}
									label="Supplier"
									disabled={isDefined(defaultValues?.supplier_id)}
									placeholder="Supplier"
									data={vendors.map(vendor => ({ value: vendor.id, label: vendor.name }))}
									{...field}
								/>
							)}
						/>
						<div className="grid grid-cols-10 gap-3">
							<Controller
								control={control}
								name="quantity"
								render={({ field: { value, onChange, ...rest }, fieldState }) => (
									<NumberInput
										label="Quantity"
										placeholder="Qty"
										min={1}
										// yuck mantine error handling
										value={value === 0 ? '' : value}
										onChange={value => onChange(value || 0)}
										className="col-span-2"
										error={fieldState?.error?.message}
										required
										{...rest}
									/>
								)}
							/>
							<Controller
								control={control}
								name="name"
								render={({ field, fieldState }) => (
									<TextInput
										label="Part name"
										placeholder="Part name"
										className="col-span-4"
										error={fieldState?.error?.message}
										required
										disabled={isDefined(defaultValues?.name)}
										{...field}
									/>
								)}
							/>
							<Controller
								control={control}
								name="grade"
								render={({ field, fieldState }) => (
									<Select
										withinPortal
										className="col-span-2"
										label="Grade"
										placeholder="Grade"
										error={fieldState?.error?.message}
										data={grades}
										{...field}
									/>
								)}
							/>
							<Controller
								control={control}
								name="price"
								render={({ field: { value, onChange, ...rest }, fieldState }) => (
									<NumberInput
										label="Price"
										className="col-span-2"
										// yuck mantine error handling
										value={value === 0 ? '' : value}
										onChange={value => onChange(value || 0)}
										min={0}
										error={fieldState?.error?.message}
										parser={value => value.replace(/\$\s?|(,*)/g, '')}
										formatter={value =>
											!Number.isNaN(parseFloat(value))
												? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
												: '$ '
										}
										precision={2}
										{...rest}
									/>
								)}
							/>
						</div>
						<div>
							<Controller
								control={control}
								name="identity"
								render={({ field }) => (
									<SegmentedControl
										data={[
											{ label: 'MPN', value: 'mpn' },
											{ label: 'SKU', value: 'sku' }
										]}
										{...field}
									/>
								)}
							/>
							<div className="grid grid-cols-12 gap-3 mt-2">
								{identity === 'mpn' || !identity ? (
									<>
										<Controller
											control={control}
											name="mpn"
											render={({ field, fieldState }) => (
												<TextInput
													label="Mpn"
													disabled={isDefined(defaultValues?.mpn)}
													placeholder="Mpn"
													className="col-span-4"
													error={fieldState?.error?.message}
													{...field}
												/>
											)}
										/>
										<Controller
											control={control}
											name="brand"
											render={({ field, fieldState }) => (
												<TextInput
													label="Brand"
													placeholder="Brand"
													disabled={isDefined(defaultValues?.brand)}
													className="col-span-4"
													error={fieldState?.error?.message}
													{...field}
												/>
											)}
										/>
									</>
								) : (
									<Controller
										control={control}
										name="sku"
										render={({ field, fieldState }) => (
											<TextInput
												label="SKU"
												placeholder="SKU"
												className="col-span-4"
												error={fieldState?.error?.message}
												{...field}
											/>
										)}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="flex items-center justify-end gap-3 mt-12">
						<Button variant="default" type="submit">
							Cancel
						</Button>
						<Button type="submit">Add item</Button>
					</div>
				</form>
			</StandardDialog.Content>
		</StandardDialog>
	);
};
