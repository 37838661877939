/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
// DO NOT MODIFY THIS FILE MANUALLY
// This file was generated by reflectapi-cli
//
// Schema name: repairer-server
// Partly Repairer Server

export function client(base: string | Client): __definition.Interface {
	return __implementation.__client(base);
}
/* <----- */

export interface Client {
	request(path: string, body: string, headers: Record<string, string>): Promise<[number, string]>;
}

export type AsyncResult<T, E> = Promise<Result<T, Err<E>>>;

export type FixedSizeArray<T, N extends number> = Array<T> & { length: N };

export class Result<T, E> {
	constructor(private value: { ok: T } | { err: E }) {}

	public ok(): T | undefined {
		if ('ok' in this.value) {
			return this.value.ok;
		}
		return undefined;
	}
	public err(): E | undefined {
		if ('err' in this.value) {
			return this.value.err;
		}
		return undefined;
	}

	public ok_or<D>(defaultValue: D): T | D {
		if (this.is_ok()) {
			return this.unwrap_ok();
		}

		console.warn('Error in ok, using default value', JSON.stringify(this.err()));
		return defaultValue;
	}

	public is_ok(): boolean {
		return 'ok' in this.value;
	}
	public is_err(): boolean {
		return 'err' in this.value;
	}

	public map<U>(f: (r: T) => U): Result<U, E> {
		if ('ok' in this.value) {
			return new Result({ ok: f(this.value.ok) });
		} else {
			return new Result({ err: this.value.err });
		}
	}
	public map_err<U>(f: (r: E) => U): Result<T, U> {
		if ('err' in this.value) {
			return new Result({ err: f(this.value.err) });
		} else {
			return new Result({ ok: this.value.ok });
		}
	}

	public unwrap_ok(): T {
		if ('ok' in this.value) {
			return this.value.ok;
		}
		throw new Error(`called \`unwrap_ok\` on an \`err\` value: ${this.value.err}`);
	}
	public unwrap_err(): E {
		if ('err' in this.value) {
			return this.value.err;
		}
		throw new Error('called `unwrap_err` on an `ok` value');
	}

	public unwrap_ok_or_default(default_: T): T {
		if ('ok' in this.value) {
			return this.value.ok;
		}
		return default_;
	}
	public unwrap_err_or_default(default_: E): E {
		if ('err' in this.value) {
			return this.value.err;
		}
		return default_;
	}

	public unwrap_ok_or_else(f: (e: E) => T): T {
		if ('ok' in this.value) {
			return this.value.ok;
		}
		return f(this.value.err);
	}
	public unwrap_err_or_else(f: (v: T) => E): E {
		if ('err' in this.value) {
			return this.value.err;
		}
		return f(this.value.ok);
	}

	public toString(): string {
		if ('ok' in this.value) {
			return `Ok { ok: ${this.value.ok} }`;
		} else {
			return `Err { err: ${this.value.err} }`;
		}
	}
}

export class Err<E> {
	constructor(private value: { application_err: E } | { other_err: any }) {}

	public err(): E | undefined {
		if ('application_err' in this.value) {
			return this.value.application_err;
		}
		return undefined;
	}
	public other_err(): any | undefined {
		if ('other_err' in this.value) {
			return this.value.other_err;
		}
		return undefined;
	}

	public is_err(): boolean {
		return 'application_err' in this.value;
	}
	public is_other_err(): boolean {
		return 'other_err' in this.value;
	}

	public map<U>(f: (r: E) => U): Err<U> {
		if ('application_err' in this.value) {
			return new Err({ application_err: f(this.value.application_err) });
		} else {
			return new Err({ other_err: this.value.other_err });
		}
	}
	public unwrap(): E {
		if ('application_err' in this.value) {
			return this.value.application_err;
		} else {
			throw this.value.other_err;
		}
	}
	public unwrap_or_default(default_: E): E {
		if ('application_err' in this.value) {
			return this.value.application_err;
		}
		return default_;
	}
	public unwrap_or_else(f: () => E): E {
		if ('application_err' in this.value) {
			return this.value.application_err;
		}
		return f();
	}

	public toString(): string {
		if ('application_err' in this.value) {
			return `Application Error: ${this.value.application_err}`;
		} else {
			return `Other Error: ${this.value.other_err}`;
		}
	}
}

/* -----> */

export namespace __definition {
	export interface Interface {
		jobs: jobs.Interface;
	}

	export namespace jobs {
		export interface Interface {
			draft_orders: draft_orders.Interface;
			orders: orders.Interface;
		}

		export namespace draft_orders {
			export interface Interface {
				/// List all draft orders for a job
				list: (
					input: draft_order_handler.DraftOrdersListRequest,
					headers: context.RepairerServerHeaders
				) => AsyncResult<
					response.NormalizedResponse<response.MultipleItemsPayload<string>, {}>,
					api_error.RepairerServerError<{}>
				>;
				/// Create multiple draft orders for a job
				ingest: (
					input: draft_order_handler.DraftOrderIngestRequest,
					headers: context.RepairerServerHeaders
				) => AsyncResult<
					response.NormalizedResponse<response.MultipleItemsPayload<string>, {}>,
					api_error.RepairerServerError<{}>
				>;
			}
		}

		export namespace orders {
			export interface Interface {
				/// List all orders for a job
				list: (
					input: order_handler.OrdersListRequest,
					headers: context.RepairerServerHeaders
				) => AsyncResult<
					response.NormalizedResponse<response.MultipleItemsPayload<order.Order>, {}>,
					api_error.RepairerServerError<{}>
				>;
				/// Update a specific order on a job
				update: (
					input: order_handler.OrdersUpdateRequest,
					headers: context.RepairerServerHeaders
				) => AsyncResult<
					response.NormalizedResponse<response.SingularItemPayload<order.Order>, {}>,
					api_error.RepairerServerError<{}>
				>;
			}
		}
	}
}
export namespace api_error {
	export type RepairerServerError<T = {}> =
		| {
				Application: T;
		  }
		| {
				Unauthorized: string | null;
		  }
		| {
				BadRequest: string;
		  }
		| {
				NotFound: string;
		  }
		| {
				InternalServerError: string;
		  };
}

export namespace collision_map {
	export interface CollisionArea {
		id: string;
		name: string;
		part_slots: Array<collision_map.CollisionPartSlot>;
		collision_hcas: Array<string>;
	}

	export interface CollisionHca {
		id: string;
		name: string;
		part_slots: Array<collision_map.CollisionPartSlot>;
	}

	export interface CollisionMap {
		id: string;
		image_url: listing.RelativeUrl;
		description: string;
		collision_area_ids: Array<string>;
	}

	export interface CollisionPartSlot {
		part_slot: part_slot.PartSlot;
	}

	export type CollisionSeverity = 'Light' | 'Medium' | 'Heavy';
}

export namespace context {
	export interface RepairerServerHeaders {
		authorization: string | null;
		'accept-language'?: string | null;
		origin?: string | null;
		'partly-storefront-key': string;
		'partly-uvdb-region-id': string | null;
		'partly-accept-currency': string | null;
	}
}

export namespace currency {
	export type Currency = string;
}

export namespace date {
	export type Timestamp = number;
}

export namespace draft_order {
	export interface AutoTransitionOrder {
		/// Whether or not to attempt
		enabled: boolean;
		/// The error message if the auto transition failed.
		error: string | null;
	}

	export interface DraftOrder {
		id: order.OrderId;
		vendor: draft_order.DraftOrderVendor;
		status: draft_order.DraftOrderStatus;
		items: Array<draft_order.DraftOrderItem>;
		delivery_location: shipping.ShippingAddress;
		images: Array<listing.ListingImage>;
		vendor_notes: string | null;
		estimator_notes: string | null;
		target_deliver_before_timestamp: date.Timestamp;
		attempt_auto_transition_order: draft_order.AutoTransitionOrder;
		created_at: date.Timestamp;
		updated_at: date.Timestamp | null;
	}

	export interface DraftOrderExternalItem {
		/// The part name / description / from HCA tree
		/// Provided by estimator.
		description: string;
		identity: draft_order.DraftOrderItemExternalIdentity | null;
	}

	export interface DraftOrderFinalise {
		id: order.OrderId;
		target_deliver_before_timestamp: date.Timestamp | null | undefined;
		items: Array<draft_order.DraftOrderItemFinalise> | null | undefined;
	}

	export type DraftOrderIngest =
		| {
				Insert: draft_order.DraftOrderInsert;
		  }
		| {
				Update: draft_order.DraftOrderUpdate;
		  }
		| {
				Finalise: draft_order.DraftOrderFinalise;
		  }
		| {
				Cancel: order.OrderId;
		  }
		| {
				Remove: order.OrderId;
		  };

	export interface DraftOrderIngestOptions {
		/// Whether or not processed drafts
		/// should be finalised transitioned into real orders.
		finalise_processed?: boolean;
		/// Whether or not draft orders should be transitioned
		/// into processing.
		process_drafts?: boolean;
	}

	export interface DraftOrderInsert {
		attempt_auto_transition_order: boolean;
		vendor: draft_order.DraftOrderVendor;
		estimator_notes: string | null;
		items: Array<draft_order.DraftOrderItemInsert>;
		target_deliver_before_timestamp: date.Timestamp;
		supply_hash_id: string;
	}

	export interface DraftOrderItem {
		id: string /* uuid::Uuid */;
		quantity: number /* u32 */;
		price: listing.Money | null;
		grade: listing.ListingGrade | null;
		arrival_at: date.Timestamp | null;
		buyable: draft_order.DraftOrderItemBuyable;
		status: draft_order.DraftOrderItemStatus;
		context: part_slot.PartSelectionContexts | null;
		order_separately: boolean;
		return_policy: order.OrderItemReturnPolicy | null;
	}

	export interface DraftOrderItemApproved {
		reason: draft_order.DraftOrderItemApprovedReason;
		details: string | null;
	}

	export type DraftOrderItemApprovedReason =
		/// Supplier can approve part for a reason, with optional extra context.
		| {
				Supplier: draft_order.DraftOrderItemSupplierApprovedReason | null;
		  }
		/// Estimator chose to force override supplier.
		/// This is used for incorrect fitment / not needed. Estimator
		/// cannot override all supplier rejections.
		| 'Estimator';

	export type DraftOrderItemBuyable =
		/// A matched listing that is in our system.
		| ({ type: 'Listing' } & draft_order.DraftOrderListingItem)

		/// A part that is not in our system, so describe it best
		/// we can for supplier to find and quote for it.
		| ({ type: 'External' } & draft_order.DraftOrderExternalItem);

	export type DraftOrderItemEstimatorRejectedReason =
		/// Estimator decided part was not wanted. This may be due
		/// to price / availability / quality.
		'NotWanted';

	export type DraftOrderItemExternalIdentity =
		/// The part sku (primiarly used by dismantler suppliers)
		| {
				Sku: string;
		  }
		/// The mpn / brand id (primarily used by OEM suppliers)
		| {
				Part: order.ExternalPartIdentity;
		  };

	export interface DraftOrderItemFinalise {
		id: string /* uuid::Uuid */;
		quantity: number /* u32 */ | null | undefined;
		status: draft_order.DraftOrderItemFinaliseStatus | null | undefined;
		status_detail: string | null | undefined;
		order_separately: boolean | null | undefined;
	}

	export type DraftOrderItemFinaliseStatus =
		/// Estimator overrode supplier rejection
		| 'Approved'
		/// Estimator rejected approved part
		| {
				Rejected: draft_order.DraftOrderItemEstimatorRejectedReason;
		  };

	export type DraftOrderItemIngest =
		| {
				Insert: draft_order.DraftOrderItemInsert;
		  }
		| {
				Update: draft_order.DraftOrderItemUpdate;
		  }
		| {
				Remove: string /* uuid::Uuid */;
		  };

	export interface DraftOrderItemInsert {
		quantity: number /* u32 */;
		buyable: draft_order.DraftOrderItemBuyable;
		price: listing.Money | null;
		grade: listing.ListingGrade | null;
		arrival_at: date.Timestamp | null;
		context: part_slot.PartSelectionContexts | null;
	}

	export interface DraftOrderItemRejected {
		reason: draft_order.DraftOrderItemRejectedReason;
		details: string | null;
	}

	export type DraftOrderItemRejectedReason =
		| {
				Supplier: draft_order.DraftOrderItemSupplierRejectedReason;
		  }
		| {
				Estimator: draft_order.DraftOrderItemEstimatorRejectedReason;
		  };

	export type DraftOrderItemStatus =
		/// The part hasn\'t been sent to the supplier yet or is
		/// waiting for the supplier to review it.
		| 'Pending'
		/// The estimator or supplier has rejected the part.
		| {
				Rejected: draft_order.DraftOrderItemRejected;
		  }
		/// The estimator or supplier has approved the part.
		| {
				Approved: draft_order.DraftOrderItemApproved;
		  };

	export type DraftOrderItemSupplierApprovedReason =
		/// Part is a replacement for another part
		'Replacement';

	export type DraftOrderItemSupplierRejectedReason =
		/// Part is not in stock
		| 'OutOfStock'
		/// Part has never been sold by the supplier
		| 'NoSupply'
		/// Supplier believes part is not correct for the vehicle
		| 'IncorrectFitment'
		/// Supplier doesn\'t believe part is required. This may be because
		/// they have added another part to replace it. todo: do we want a specific state
		/// for this?
		| 'NotNeeded';

	export interface DraftOrderItemUpdate {
		id: string /* uuid::Uuid */;
		quantity: number /* u32 */ | null | undefined;
		price: listing.Money | null | undefined;
		arrival_at: date.Timestamp | null | undefined;
		grade: listing.ListingGrade | null | undefined;
		context: part_slot.PartSelectionContexts | null | undefined;
	}

	export interface DraftOrderListingItem {
		offer: supply.SupplyItemOffer;
	}

	export type DraftOrderStatus =
		/// The estimator is still working on the draft, and hasn\'t
		/// been sent yet.
		| 'Draft'
		/// The estimator has sent the draft to the supplier and
		/// is awaiting a response. No changes can be made until it
		/// has been processed.
		| {
				Processing: order.OrderStatusMetadata | null;
		  }
		/// The supplier has processed the draft order, and changes
		/// are ready for review by the estimator.
		| 'Processed'
		/// The estimator has reviewed any changes and has promoted
		/// the draft a finalised order.
		| 'Finalised'
		/// The estimator cancelled the draft.
		| {
				Cancelled: order.OrderStatusMetadata | null;
		  };

	export interface DraftOrderUpdate {
		id: order.OrderId;
		attempt_auto_transition_order: boolean | null | undefined;
		estimator_notes: string | null | undefined;
		images: Array<listing.RelativeUrl> | null | undefined;
		items: Array<draft_order.DraftOrderItemIngest> | null | undefined;
		target_deliver_before_timestamp: date.Timestamp | null | undefined;
		supply_hash_id: string | null | undefined;
	}

	export type DraftOrderVendor =
		/// Onboarded supplier with stock in our system
		{
			Partner: string /* uuid::Uuid */;
		};
}

export namespace draft_order_handler {
	export interface DraftOrderIngestRequest {
		job_id: string /* uuid::Uuid */;
		repairer_site_id: string /* uuid::Uuid */;
		entities: Array<draft_order.DraftOrderIngest>;
		options: draft_order.DraftOrderIngestOptions;
	}

	export interface DraftOrdersListRequest {
		job_id: string /* uuid::Uuid */;
	}
}

export namespace gapc_assembly {
	export interface GapcAssembly {
		id: string /* uuid::Uuid */;
		name: string;
		part: gapc_assembly.GapcAssemblyPart | null;
		gapc_diagrams_ids: Array<string /* uuid::Uuid */>;
	}

	export interface GapcAssemblyPart {
		description: string | null;
		gapc_part_identity: gapc_part.GapcPartIdentity | null;
		part_slot: part_slot.PartSlot | null;
		fitment: Array<tier1.VehicleProperty>;
		attributes: Array<tier1.PartProperty>;
		quantity: number /* u8 */ | null;
	}

	export interface GapcDiagram {
		id: string /* uuid::Uuid */;
		name: string;
		code: string;
		image: gapc_assembly.GapcImage;
		fitment: Array<tier1.VehicleProperty>;
		part_slots: Array<gapc_assembly.GapcDiagramPartSlot>;
	}

	export interface GapcDiagramHotSpot {
		x1_px: number /* i32 */;
		x2_px: number /* i32 */;
		y1_px: number /* i32 */;
		y2_px: number /* i32 */;
	}

	export interface GapcDiagramPartSlot {
		id: string /* uuid::Uuid */;
		code: string;
		hotspots: Array<gapc_assembly.GapcDiagramHotSpot>;
		parts: Array<gapc_assembly.GapcAssemblyPart>;
		assemblies: Array<string /* uuid::Uuid */>;
	}

	export interface GapcImage {
		thumb: listing.AbsoluteUrl;
		large: listing.AbsoluteUrl;
	}
}

export namespace gapc_common {
	export interface GapcAttribute {
		id: string;
		name: string;
		data_type: gapc_common.GapcAttributeDataTypes;
	}

	export type GapcAttributeDataTypes = 'Boolean' | 'String' | 'Numeric' | 'NumericRange' | 'Date';

	export type GapcAttributeVal =
		| {
				String: string;
		  }
		| {
				Bool: boolean;
		  }
		| {
				Float: number /* f64 */;
		  }
		| {
				NumericRange: [number /* f64 */, number /* f64 */];
		  }
		| {
				Integer: number /* i64 */;
		  };

	export interface GapcAttributeValue {
		id: string /* uuid::Uuid */;
		gapc_attribute_id: string;
		value: gapc_common.GapcAttributeVal;
	}

	export interface GapcBrand {
		id: string;
		name: string;
		is_oem: boolean;
	}

	export interface GapcCategory {
		id: string;
		name: string;
		vehicle_type_id: string;
	}

	export interface GapcCommonProperty {
		id: string;
		name: string;
	}

	export interface GapcPartType {
		id: string;
		name: string;
		aliases: Array<uvdb_gapc_common.UvdbGapcI18nName>;
		gapc_properties: Array<string>;
		categorizations: Array<gapc_common.GapcPartTypeCategorization>;
		uvdb_property_prefixes: Array<suid_rs.suid_prefix.UvdbSuidPrefix>;
	}

	export interface GapcPartTypeCategorization {
		subcategory_id: string;
		category_id: string;
	}

	export type GapcProperty =
		| ({ kind: 'Brand' } & gapc_common.GapcBrand)
		| ({ kind: 'Attribute' } & gapc_common.GapcAttribute)
		| ({ kind: 'PartType' } & gapc_common.GapcPartType)
		| ({ kind: 'Category' } & gapc_common.GapcCategory)
		| ({ kind: 'Other' } & gapc_common.GapcCommonProperty);
}

export namespace gapc_part {
	/// shared listing info between kit/product/variant
	export type GapcPart = {
		original_mpn: string | null;
		is_oe: boolean;
		is_universal: boolean;
		gapc_parent_id: string /* uuid::Uuid */ | null;
		gapc_properties: Array<string>;
		gapc_attributes: Array<gapc_common.GapcAttributeValue>;
		measurement: gapc_part.Measurement | null;
		/// This field store\'s every possible positions this part can fit, including the ones in custom restrictions
		available_gapc_positions: Array<string>;
	} & gapc_part.GapcPartIdentity;

	/// BrandId, PartNumber
	export interface GapcPartIdentity {
		gapc_brand_id: string;
		mpn: string;
	}

	export interface Measurement {
		width_mm: number /* u32 */ | null;
		height_mm: number /* u32 */ | null;
		length_mm: number /* u32 */ | null;
		weight_g: number /* u32 */ | null;
	}
}

export namespace i18n {
	export type WithI18n<T> = {} & T;
}

export namespace job {
	export interface CollisionAreaSeverity {
		id: string;
		severity: collision_map.CollisionSeverity;
		hcas?: Array<string>;
	}

	export interface Job {
		id: string /* uuid::Uuid */;
		collisions: Array<job.CollisionAreaSeverity>;
		created_at: date.Timestamp | null;
		completed_at: date.Timestamp | null;
		status: job.JobStatus;
		job_number: string | null;
		claim_number: string | null;
		vehicle: job.JobVehicle | null;
		added_parts_count: number /* u64 */;
		cart_items_count: number /* u64 */;
	}

	export type JobStatus = 'Active' | 'Completed' | 'Ordered' | 'Deleted';

	export interface JobType {
		id: string;
		name: string;
	}

	export interface JobVehicle {
		plate_state: string | null;
		plate_number: string | null;
		chassis_number: string | null;
		variant: job.JobVehicleVariant;
	}

	export interface JobVehicleVariant {
		token: tier1.VehicleFilterToken | null;
		description: string;
		uvdb_properties: Array<string>;
	}

	export interface PartSupplySummary {
		/// Whether there is any supply available for this part
		availability: listing.Availability;
		/// References to matching listings
		listings: Array<string>;
		/// Types of supply available for this part
		listing_types: Array<listing.ListingKind>;
		images: Array<listing.ListingImage>;
		/// Sample of part-numbers matching this part slot
		part_numbers: Array<string>;
		/// Price aggregation
		price: listing.ListingPricing | null;
		/// Shipping aggregation
		shipping: shipping.ShippingTime | null;
		/// Grades of supply available for this part
		grades: Array<listing.ListingGrade>;
	}
}

export namespace listing {
	export type AbsoluteUrl = string;

	export type Availability = 'InStock' | 'OutOfStock' | 'NoSupply';

	/// shared listing info between kit/product/variant
	export interface BaseListing<Img> {
		id: string /* uuid::Uuid */;
		gapc_part_id: string /* uuid::Uuid */ | null;
		name: string;
		sku: string | null;
		images: Array<Img>;
		tags?: Array<string>;
		created_at: date.Timestamp | null;
		updated_at: date.Timestamp | null;
	}

	export type Kit = {
		products: Array<listing.Product>;
		pricing: listing.ListingPricing;
		description: string | null;
		availability: listing.Availability;
	} & listing.BaseListing<listing.ListingImage> &
		gapc_part.GapcPart;

	export type Listing =
		| ({ kind: 'Kit' } & listing.Kit)
		| ({ kind: 'VariableProduct' } & listing.VariableProduct)
		| ({ kind: 'Product' } & listing.Variant);

	export type ListingGrade = '0' | 'A' | 'B' | 'C' | 'D';

	export interface ListingImage {
		thumb: listing.AbsoluteUrl;
		large: listing.AbsoluteUrl;
	}

	export type ListingKind = 'Kit' | 'VariableProduct' | 'Product';

	///  # An aggregation of the pricing with min and max
	///
	///  For variant, min/max is the lowest/highest price from the supplier
	///
	///  For Product, min/max is the lowest/highest price of variants
	///
	///  For Kit, min/max is the lowest/highest of products combined, lowest exclude optional products as well
	export interface ListingPricing {
		min: listing.Money;
		max: listing.Money;
		display_min: listing.Money | null;
		display_max: listing.Money | null;
		currency: currency.Currency;
	}

	/// Store money as cents * 10^3 so 10.05 becomes 10050
	export type Money = number /* u64 */;

	export type Product =
		| ({ kind: 'VariableProduct' } & listing.VariableProduct)
		| ({ kind: 'Product' } & listing.Variant);

	export type RelativeUrl = string;

	export interface SupplyInventory {
		location_id: string /* uuid::Uuid */;
		availability: listing.Availability;
	}

	export interface SupplyLocation {
		id: string /* uuid::Uuid */;
		vendor_id: string /* uuid::Uuid */;
		name: string;
	}

	export interface SupplyVendor {
		id: string /* uuid::Uuid */;
		name: string;
	}

	export type VariableProduct = {
		/// if kit_id is not null, it means this is a variable product and links to a kit, otherwise this is the root of the listing
		kit_id?: string /* uuid::Uuid */ | null;
		variants: Array<listing.Variant>;
		pricing: listing.ListingPricing;
		description: string | null;
		availability: listing.Availability;
	} & listing.BaseListing<listing.ListingImage> &
		gapc_part.GapcPart;

	/// This represent a single variant or a non-variable product
	export type Variant = {
		/// if kit_id is not null, this variant belongs to a kit, depending on the product_id, it means this is a variant or a non-variable product
		kit_id?: string /* uuid::Uuid */ | null;
		/// if product_id is not null, this is a variant belong to a product, otherwise it\'s a non-variable product
		product_id?: string /* uuid::Uuid */ | null;
		/// Aggregated pricing for this variant across all supplies
		pricing: listing.ListingPricing;
		description: string | null;
		/// Availability for this variant from any supply
		availability: listing.Availability;
		/// All available grades for this variant
		grades: Array<listing.ListingGrade>;
		/// Options for supply for this variant
		/// Normally a variant will have a single supplier/vendor but we can support multiple if needed.
		supplies: Array<listing.VariantSupply>;
	} & listing.BaseListing<listing.ListingImage> &
		gapc_part.GapcPart;

	export interface VariantSupply {
		///
		///  Represents a distinct offer that can be purchased
		///
		offer_id: string;
		vendor_id: string /* uuid::Uuid */;
		///
		///  A location the product can be shipped from
		///  Useful to display shipping information to the buyer
		///
		inventory: Array<listing.SupplyInventory>;
		price: listing.Money;
		display_price: listing.Money | null;
		grade: listing.ListingGrade | null;
		availability: listing.Availability;
	}
}

export namespace order {
	export interface ExternalPartIdentity {
		/// The name of the brand (e.g. Toyota). Not super relevant
		/// for OEMs that only have one brand.
		brand: string | null;
		mpn: string;
	}

	export interface GapcPartSnapshot {
		gapc_brand: order.GapcPropertySnapshot<string, string> | null;
		mpn: string;
		is_oe: boolean;
		is_universal: boolean;
		part_type: order.GapcPropertySnapshot<string, string> | null;
		available_gapc_positions: Array<order.GapcPropertySnapshot<string, string>>;
	}

	export interface GapcPropertySnapshot<T, E> {
		id: T;
		name: E;
	}

	export interface KitOrderOffer {
		listing: order.OrderKitListing;
		offer_ids: Array<string>;
	}

	export interface Order {
		id: order.OrderId;
		vendor: order.OrderVendor;
		items: Array<order.OrderItem>;
		images: Array<listing.RelativeUrl>;
		status: order.OrderStatus;
		delivery_location: shipping.ShippingAddress;
		deliver_before_timestamp: date.Timestamp;
		total_shipping_price: listing.Money;
		store_currency: currency.Currency;
		updated_at: date.Timestamp | null;
		created_at: date.Timestamp;
	}

	export type OrderBaseListing = {
		id: string /* uuid::Uuid */;
		name: string;
		sku: string | null;
		tags: Array<string>;
		images: Array<listing.RelativeUrl>;
	} & Partial<order.GapcPartSnapshot>;

	export interface OrderExternalItem {
		/// The part name / description / from HCA tree
		/// Provided by estimator.
		description: string;
		identity: order.OrderItemExternalIdentity | null;
	}

	export type OrderId = string;

	export interface OrderItem {
		id: string /* uuid::Uuid */;
		quantity: number /* u32 */;
		price: listing.Money;
		buyable: order.OrderItemBuyable;
		status: order.OrderItemStatus;
		grade: listing.ListingGrade | null;
		context: part_slot.PartSelectionContexts | null;
		return_policy: order.OrderItemReturnPolicy;
	}

	export type OrderItemBuyable =
		/// A matched listing that is in our system.
		| ({ type: 'listing' } & order.OrderListingItem)

		/// A part that is not in our system, so describe it best
		/// we can for supplier to find and quote for it.
		| ({ type: 'external' } & order.OrderExternalItem);

	export type OrderItemCondition = 'not_damaged';

	export type OrderItemExternalIdentity =
		/// The part sku (primiarly used by dismantler suppliers)
		| {
				sku: string;
		  }
		/// The mpn / brand id (primarily used by OEM suppliers)
		| {
				part: order.ExternalPartIdentity;
		  };

	export type OrderItemReturnPolicy =
		/// Order item cannot be returned
		| 'no_returns'
		/// Order item can be returned if it satisfies condition and return date
		| {
				allow_returns: {
					condition: order.OrderItemCondition | null;
					before_date: string | null;
				};
		  };

	export type OrderItemReturnReason =
		/// The part was damaged in transit
		| 'damaged'
		/// The part was not what was ordered
		/// (e.g. wrong part number, color, etc)
		| 'incorrectly_supplied'
		/// The part was correctly supplied, but the
		/// part doesn\'t fit the vehicle
		| 'incorrect_fitment'
		/// The part ended up not be needed for
		/// the repair
		| 'not_required'
		/// The part was ordered with x quanity but
		/// received less than x.
		| 'short_supplied'
		/// Generic other reason
		| 'other';

	export type OrderItemStatus =
		/// The order item has been created and is waiting
		/// to be delivered.
		| 'pending'
		| {
				arrived: {
					/// The time that the courrier dropped the
					/// part off.
					arrival_at: date.Timestamp;
					/// Free text notes about misc details
					details: string | null;
				};
		  }
		| {
				returned: {
					/// Structured reason for the return
					reason: order.OrderItemReturnReason;
					/// The quantity of the item being returned, will
					/// be less than or equal to the quantity ordered.
					quantity: number /* u32 */;
					/// Required details for the return, will be shown
					/// to the supplier.
					details: string;
				};
		  };

	export interface OrderItemUpdate {
		id: string /* uuid::Uuid */;
		status: order.OrderItemStatus | null | undefined;
	}

	export type OrderKitListing = {
		products: Array<order.OrderVariantListing>;
	} & order.OrderBaseListing;

	export interface OrderListingItem {
		offer: order.OrderOffer;
	}

	export type OrderOffer =
		| {
				product: order.ProductOrderOffer;
		  }
		| {
				kit: order.KitOrderOffer;
		  };

	export type OrderStatus =
		/// The order has been created and is waiting
		/// for confirmation from the supplier. Suppliers should not
		/// be rejecting orders at this stage.
		| {
				ordered: order.OrderStatusMetadata | null;
		  }
		/// For some reason the supplier cannot fulfill the order
		/// (e.g. stock was sold in between the order being placed) so
		/// the order has been cancelled.
		| 'cancelled'
		/// The order has been acknowledged as parts will arrive as requested.
		| 'acknowledged';

	export type OrderStatusMetadata =
		/// We are retring from a recoverable error
		| 'Retrying'
		/// We are unable to recover.
		| 'Failed';

	export interface OrderUpdate {
		id: order.OrderId;
		items: Array<order.OrderItemUpdate> | null | undefined;
	}

	export type OrderVariantListing = {
		/// if kit_id is not null, this variant belongs to a kit, depending on the product_id, it means this is a variant or a non-variable product
		kit_id?: string /* uuid::Uuid */ | null;
		/// if product_id is not null, this is a variant belong to a product, otherwise it\'s a non-variable product
		product_id?: string /* uuid::Uuid */ | null;
	} & order.OrderBaseListing;

	export type OrderVendor =
		/// Onboarded supplier with stock in our system
		{
			partner: listing.SupplyVendor;
		};

	export interface ProductOrderOffer {
		listing: order.OrderVariantListing;
		offer_id: string;
	}
}

export namespace order_handler {
	export interface OrdersListRequest {
		job_id: string /* uuid::Uuid */;
	}

	export interface OrdersUpdateRequest {
		job_id: string /* uuid::Uuid */;
		update: order.OrderUpdate;
	}
}

export namespace part_assembly {
	export interface PartAssembly {
		id: string /* uuid::Uuid */;
		description: string | null;
		part_slot: part_slot.PartSlot | null;
		diagrams: Array<string> | null;
		part_identity: string | null;
		assembly_type: part_assembly.PartAssemblyType;
		sub_assemblies: Array<part_assembly.PartSubAssembly>;
		fitment: Array<tier1.VehicleProperty> | null;
		attributes: Array<tier1.PartProperty> | null;
		confidence: number /* u16 */ | null;
		quantity_required: number /* u8 */ | null;
		supply: job.PartSupplySummary | null;
	}

	export type PartAssemblyType = 'human_centric' | 'part';

	export type PartSubAssembly =
		| string
		| {
				variants: Array<string>;
		  };
}

export namespace part_slot {
	export type PartSelectionContext = ({
		/// Oem description of part
		description: string | null;
	} & Partial<gapc_part.GapcPartIdentity>) &
		Partial<part_slot.PartSlot>;

	export type PartSelectionContexts = Array<part_slot.PartSelectionContext>;

	export interface PartSlot {
		gapc_part_type_id: string;
		gapc_position_id: string | null;
	}
}

export namespace resource {
	export interface ResourcesMap {
		collision_areas?: Record<string, collision_map.CollisionArea> | null;
		collision_maps?: Record<string, collision_map.CollisionMap> | null;
		collision_hcas?: Record<string, collision_map.CollisionHca> | null;
		jobs?: Record<string /* uuid::Uuid */, job.Job> | null;
		job_types?: Record<string, job.JobType> | null;
		listings?: Record<string /* uuid::Uuid */, listing.Listing> | null;
		vendors?: Record<string /* uuid::Uuid */, listing.SupplyVendor> | null;
		locations?: Record<string /* uuid::Uuid */, listing.SupplyLocation> | null;
		draft_orders?: Record<order.OrderId, draft_order.DraftOrder> | null;
		gapc_parts?: Record<string, gapc_part.GapcPart> | null;
		gapc_assemblies?: Record<string /* uuid::Uuid */, gapc_assembly.GapcAssembly> | null;
		gapc_diagrams?: Record<string /* uuid::Uuid */, gapc_assembly.GapcDiagram> | null;
		gapc_properties?: Record<string, i18n.WithI18n<gapc_common.GapcProperty>> | null;
		uvdb_properties?: Record<string, i18n.WithI18n<uvdb_common.UvdbProperty>> | null;
		part_assemblies?: Record<string /* uuid::Uuid */, part_assembly.PartAssembly> | null;
	}
}

export namespace response {
	export interface MultipleItemsPayload<Item> {
		items: Array<Item>;
	}

	export type NormalizedResponse<Payload, Additional> = {
		payload: response.NormalizedResponsePayload<Payload, Additional>;
	} & resource.ResourcesMap;

	export type NormalizedResponsePayload<Payload, Additional> = {} & Payload & Additional;

	export interface SingularItemPayload<Item> {
		item: Item;
	}
}

export namespace shipping {
	export interface GeoLocation {
		latitude: number /* f64 */;
		longitude: number /* f64 */;
	}

	export interface ShippingAddress {
		city: string | null;
		country_code: number /* i32 */ | null;
		postcode: string | null;
		state: string | null;
		street_name: string | null;
		street_number: string | null;
		suburb: string | null;
		unit: string | null;
		geo: shipping.GeoLocation | null;
	}

	export interface ShippingTime {
		eta: string | null;
		arrival_time: number /* u32 */ | null;
		business_days: number /* u32 */ | null;
		///
		///  The id of the location where the shipping
		///  time is applicable.
		///
		location_id: string /* uuid::Uuid */;
	}
}

export namespace suid_rs {
	export namespace suid_prefix {
		export type UvdbSuidPrefix =
			/// Aspiration
			| 'UASP'
			/// BedLength
			| 'UBLN'
			/// BedType
			| 'UBET'
			/// BodyNumDoors
			| 'UBND'
			/// BodyType
			| 'UBOT'
			/// BrakeAbs
			| 'UBBS'
			/// BrakeSystem
			| 'UBSY'
			/// CylinderHeadType
			| 'UCHT'
			/// DriveType
			| 'UDRT'
			/// EngineBase
			| 'UEBA'
			/// EngineDesignation
			| 'UEDN'
			/// EnginePowerOutput
			| 'UEPO'
			/// EngineVersion
			| 'UEVS'
			/// EngineDefinition
			| 'UENG'
			/// FuelDeliverySubtype
			| 'UFDS'
			/// FuelDeliveryType
			| 'UFDT'
			/// FuelSystemControlType
			| 'UFSC'
			/// FuelSystemDesign
			| 'UFSD'
			/// FuelType
			| 'UFTY'
			/// IgnitionSystemType
			| 'UGST'
			/// Make
			| 'UMAK'
			/// MfrBodyCode
			| 'UMBC'
			/// Model
			| 'UMOD'
			/// Region
			| 'UREG'
			/// Restriction
			| 'URES'
			/// Series
			| 'UVSE'
			/// SteeringSide
			| 'USSD'
			/// SteeringSystem
			| 'USSY'
			/// SteeringType
			| 'USTT'
			/// Submodel
			| 'USUB'
			/// TransmissionControlType
			| 'UTCT'
			/// TransmissionElecControl
			| 'UTEC'
			/// TransmissionMfrCode
			| 'UTMC'
			/// TransmissionNumSpeeds
			| 'UTNS'
			/// TransmissionType
			| 'UTTY'
			/// Valves
			| 'UVLV'
			/// VehicleDefinition
			| 'UDEF'
			/// VehicleSubtype
			| 'UVES'
			/// VehicleType
			| 'UVET'
			/// EngineVin
			| 'UEVN'
			/// Wheel
			| 'UWHE'
			/// WheelBase
			| 'UWHB'
			/// WheelConfig
			| 'UWHC'
			/// YearFrom
			| 'UPYF'
			/// YearTo
			| 'UPYT'
			/// MonthFrom
			| 'UPMF'
			/// MonthTo
			| 'UPMT'
			/// EngineManufacturer
			| 'UEMF'
			/// TransmissionManufacturer
			| 'UTMF'
			/// FrontSpringType
			| 'UFST'
			/// RearSpringType
			| 'URST'
			/// FrontBrakeType
			| 'UFBT'
			/// RearBrakeType
			| 'URBT'
			/// Emission standard
			| 'UEUE'
			/// ProductionYear
			| 'UPYE'
			/// ProductionMonth
			| 'UPMO';
	}
}

export namespace supply {
	export type SupplyItemOffer =
		///
		///  Selected product offer
		///
		| ({ type: 'Product' } & supply.SupplyItemOfferProduct)

		///
		///  Kit with selected variant offers
		///
		| ({ type: 'Kit' } & supply.SupplyItemOfferKit);

	export interface SupplyItemOfferKit {
		/// The kit id
		listing_id: string /* uuid::Uuid */;
		/// Explicitly specifies all included variants in the kit (allow future support for full customisation)
		/// Should include exactly one offer for each product in the kit
		offer_ids: Array<string>;
	}

	export interface SupplyItemOfferProduct {
		/// The root product id
		listing_id: string /* uuid::Uuid */;
		/// The selected variant offer
		offer_id: string;
	}
}

export namespace tier1 {
	export interface PartProperty {
		type: string;
		value: string;
	}

	export type VehicleFilterToken = string;

	export interface VehicleProperty {
		type: string;
		value: string;
	}
}

export namespace uvdb_common {
	export interface UvdbProperty {
		id: string;
		name: string;
	}
}

export namespace uvdb_gapc_common {
	export interface UvdbGapcI18nName {
		name: string;
		normalized_name: string;
	}
}

namespace __implementation {
	/* <----- */

	export function __client(base: string | Client): __definition.Interface {
		const client_instance = typeof base === 'string' ? new ClientInstance(base) : base;
		return {
			impl: {
				jobs: {
					draft_orders: {
						list: jobs__draft_orders__list(client_instance),
						ingest: jobs__draft_orders__ingest(client_instance)
					},
					orders: {
						list: jobs__orders__list(client_instance),
						update: jobs__orders__update(client_instance)
					}
				}
			}
		}.impl;
	}

	export function __request<I, H, O, E>(
		client: Client,
		path: string,
		input: I | undefined,
		headers: H | undefined
	): AsyncResult<O, E> {
		let hdrs: Record<string, string> = {
			'content-type': 'application/json'
		};
		if (headers) {
			for (const [k, v] of Object.entries(headers)) {
				hdrs[k?.toString()] = v?.toString() || '';
			}
		}
		return client
			.request(path, JSON.stringify(input), hdrs)
			.then(([status, response_body]) => {
				if (status < 200 || status >= 300) {
					let parsed_response_body;
					try {
						parsed_response_body = JSON.parse(response_body);
					} catch (e) {
						return new Result<O, Err<E>>({
							err: new Err({ other_err: `[${status}] ${response_body}` })
						});
					}
					return new Result<O, Err<E>>({
						err: new Err({ application_err: parsed_response_body as E })
					});
				}

				let parsed_response_body;
				try {
					parsed_response_body = JSON.parse(response_body);
				} catch (e) {
					return new Result<O, Err<E>>({
						err: new Err({
							other_err:
								'internal error: failure to parse response body as json on successful status code: ' +
								response_body
						})
					});
				}
				return new Result<O, Err<E>>({ ok: parsed_response_body as O });
			})
			.catch(e => {
				return new Result<O, Err<E>>({ err: new Err({ other_err: e }) });
			});
	}

	class ClientInstance {
		constructor(private base: string) {}

		public request(
			path: string,
			body: string,
			headers: Record<string, string>
		): Promise<[number, string]> {
			return (globalThis as any)
				.fetch(`${this.base}${path}`, {
					method: 'POST',
					headers: headers,
					body: body
				})
				.then((response: any) => {
					return response.text().then((text: string) => {
						return [response.status, text];
					});
				});
		}
	}

	/* -----> */

	function jobs__draft_orders__list(client: Client) {
		return (
			input: draft_order_handler.DraftOrdersListRequest,
			headers: context.RepairerServerHeaders
		) =>
			__request<
				draft_order_handler.DraftOrdersListRequest,
				context.RepairerServerHeaders,
				response.NormalizedResponse<response.MultipleItemsPayload<string>, {}>,
				api_error.RepairerServerError<{}>
			>(client, '/api/v1/jobs.draft-orders.list', input, headers);
	}
	function jobs__draft_orders__ingest(client: Client) {
		return (
			input: draft_order_handler.DraftOrderIngestRequest,
			headers: context.RepairerServerHeaders
		) =>
			__request<
				draft_order_handler.DraftOrderIngestRequest,
				context.RepairerServerHeaders,
				response.NormalizedResponse<response.MultipleItemsPayload<string>, {}>,
				api_error.RepairerServerError<{}>
			>(client, '/api/v1/jobs.draft-orders.ingest', input, headers);
	}
	function jobs__orders__list(client: Client) {
		return (input: order_handler.OrdersListRequest, headers: context.RepairerServerHeaders) =>
			__request<
				order_handler.OrdersListRequest,
				context.RepairerServerHeaders,
				response.NormalizedResponse<response.MultipleItemsPayload<order.Order>, {}>,
				api_error.RepairerServerError<{}>
			>(client, '/api/v1/jobs.orders.list', input, headers);
	}
	function jobs__orders__update(client: Client) {
		return (input: order_handler.OrdersUpdateRequest, headers: context.RepairerServerHeaders) =>
			__request<
				order_handler.OrdersUpdateRequest,
				context.RepairerServerHeaders,
				response.NormalizedResponse<response.SingularItemPayload<order.Order>, {}>,
				api_error.RepairerServerError<{}>
			>(client, '/api/v1/jobs.orders.update', input, headers);
	}
}
