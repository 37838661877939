import { ApiConfig } from '../types';
import { IResourceMap, ResourceMap } from './resources';

export class Context {
	private constructor(
		private resourceMap: ResourceMap,
		private apiConfig: ApiConfig
	) {}

	public get config(): ApiConfig {
		return this.apiConfig;
	}

	public get resources(): ResourceMap {
		return this.resourceMap;
	}

	public static create<T extends IResourceMap>(payload: T, config: ApiConfig): Context {
		const resourceMap = ResourceMap.create(payload);
		return new Context(resourceMap, config);
	}
}
