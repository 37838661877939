import { DateCell, DefaultText, HeaderCell } from '@/app/common/components/molecules/cell-builder';
import { BasicTable } from '@/app/molecules/order-table';
import { Section } from '@/app/templates/page-section';
import { formatPrice } from '@/app/utils/currency';
import { tlsx } from '@/app/utils/tw-merge';
import { draft_order } from '@/sdk/reflect/reflect';
import { InheritableElementProps } from '@/types/utilties';
import { Button } from '@mantine/core';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { OrderRequestStatus } from '../order-request-status-badge';

type OrderRequestSummary = {
	name: string;
	status: draft_order.DraftOrderStatus;
	deliveryDate: number;
	partsQty: number;
	totalPrice: number;
};

const columnHelper = createColumnHelper<OrderRequestSummary>();
const columns = [
	columnHelper.accessor('name', {
		id: 'name',
		header: () => <HeaderCell>Vendor</HeaderCell>,
		cell: info => <DefaultText>{info.getValue()}</DefaultText>
	}),
	columnHelper.accessor('status', {
		id: 'status',
		header: () => <HeaderCell>Status</HeaderCell>,
		cell: info => <OrderRequestStatus status={info.getValue()} size="small" />
	}),
	columnHelper.accessor('deliveryDate', {
		id: 'deliveryDate',
		header: () => <HeaderCell>Est. delivery</HeaderCell>,
		cell: info => <DateCell value={info.getValue()} orderSeparately={false} />
	}),
	columnHelper.accessor('partsQty', {
		id: 'partsQty',
		header: () => <HeaderCell>Parts qty.</HeaderCell>,
		cell: info => <DefaultText>{info.getValue()}</DefaultText>
	}),
	columnHelper.accessor('totalPrice', {
		id: 'totalPrice',
		header: () => <HeaderCell className="text-right">Total</HeaderCell>,
		cell: info => (
			<DefaultText className="text-right">{formatPrice(info.getValue() ?? 0)}</DefaultText>
		)
	})
];

export const OrderRequestsSummary = ({
	jobId,
	orderRequests,
	className
}: InheritableElementProps<
	'section',
	{ jobId: string; orderRequests: draft_order.exp.DraftOrder[] }
>) => {
	const items = useMemo<OrderRequestSummary[]>(() => {
		return orderRequests.map(order => ({
			name: `${order.vendor.Partner.name} #(${order.id})`,
			status: order.status,
			deliveryDate: order.target_deliver_before_timestamp,
			partsQty: order.items.length,
			totalPrice: order.items.reduce((acc, item) => acc + (item.price ?? 0) * item.quantity, 0)
		}));
	}, [orderRequests]);

	const table = useReactTable({
		data: items,
		columns,
		getCoreRowModel: getCoreRowModel()
	});

	return (
		<Section className={tlsx('bg-white px-6 pt-6 pb-4 border rounded-md', className)}>
			<div className="flex items-center justify-between">
				<Section.Title className="text-lg">Order requests</Section.Title>
				<Button variant="subtle" component={Link} to={`/job/${jobId}/supply`}>
					View requests
				</Button>
			</div>
			<div className="mt-2 overflow-y-auto max-h-56">
				<BasicTable table={table} />
			</div>
		</Section>
	);
};
