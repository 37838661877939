import { UserService } from '@/sdk/generated';
import { createJWTResponse, createUserResult } from '../transformers/user';
import {
	AuthCredentials,
	BasicAuthChangePassword,
	JWTResult,
	StorefrontResponse,
	UserResult,
	UserSuccessResult
} from '../types';
import { recursiveToSnake } from '../utils/casing';
import { execute } from '../utils/execute';

export const createUserEndpoints = (userService: UserService) => {
	const login = (payload: AuthCredentials): Promise<StorefrontResponse<JWTResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await userService.login(snakePayload);
			return createJWTResponse(result);
		});

	const get = (): Promise<StorefrontResponse<UserResult>> =>
		execute(async () => {
			const result = await userService.get();
			return createUserResult(result);
		});

	const passwordUpdate = (
		payload: BasicAuthChangePassword
	): Promise<StorefrontResponse<UserSuccessResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			await userService.passwordUpdate(snakePayload);
			return { success: true };
		});

	return {
		login,
		get,
		passwordUpdate
	};
};
