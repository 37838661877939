import { useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import {
	applySideEffects,
	getAddSearchParams,
	getRemoveSearchParams,
	getSearchKeyValue,
	getSearchParamActive,
	getSetSearchParams,
	getToggleSearchParams,
	getValues
} from './utils';

export type UseSearchQueryOptions = {
	separator?: string;
	/** A list of query params that should be removed on mutation */
	sideEffects?: string[];
	defaultValues?: string[];
};

/**
 * Manage url search param CRUD operations for a specific key.
 *
 * @param key the search key to operate on
 * @param options configurable options
 */
export const useSearchQuery = (key: string, options: UseSearchQueryOptions = {}) => {
	const { search } = useLocation();
	const navigate = useNavigate();

	/** Get the current value of the search params key */
	const searchQuery = getSearchKeyValue(search, key);

	/**
	 * Set a query params key to an explicit value
	 *
	 * @param value the new value of the query key
	 */
	const set = (value: string | string[]) => {
		let setSearchParams = getSetSearchParams(search, key, value, options.separator);
		setSearchParams = applySideEffects(setSearchParams, options.sideEffects);

		navigate({ search: setSearchParams.toString() });
	};

	/**
	 * Add or remove the value under the key
	 *
	 * @param value the value to toggle
	 */
	const toggle = (value: string) => {
		let toggleSearchParams = getToggleSearchParams(search, key, value, options.separator);

		toggleSearchParams = applySideEffects(toggleSearchParams, options.sideEffects);

		navigate({ search: toggleSearchParams.toString() }, { replace: true });
	};

	/**
	 * Add the value to the search query if not already present
	 * based under the key
	 *
	 * @param value the search query param to add the key
	 */
	const add = (value: string) => {
		let addSearchParams = getAddSearchParams(search, key, value, options.separator);

		addSearchParams = applySideEffects(addSearchParams, options.sideEffects);
		navigate({ search: addSearchParams.toString() }, { replace: true });
	};

	/**
	 * Remove the value from the search query if present
	 * under the key
	 *
	 * @param value the search query value to remove from the key
	 */
	const remove = (value: string) => {
		let removeSearchParams = getRemoveSearchParams(search, key, value, options.separator);

		removeSearchParams = applySideEffects(removeSearchParams, options.sideEffects);
		navigate({ search: removeSearchParams.toString() }, { replace: true });
	};

	/**
	 * Check if the provided value is currently present
	 * as a value in the search query params key
	 *
	 * @param value value to check against
	 */
	const active = (value: string) => getSearchParamActive(search, key, value, options.separator);

	/**
	 * Get a list of values from search query
	 */
	const values = useMemo(() => {
		if (!searchQuery) {
			return options.defaultValues ?? [];
		}

		return getValues(searchQuery, options.separator);
	}, [searchQuery, options.separator]);

	return {
		set,
		add,
		remove,
		toggle,
		active,
		values
	};
};
