import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType
} from 'react-router-dom';

import { initTelemetry } from '@partly/browser-telemetry';
import { getBaseConfig } from './config.ts';
import { getEnvironmentKey } from './environment.ts';

const config = getBaseConfig();

const untracedUrls: (string | RegExp)[] = [
	'alloy.partly.pro',
	'api-eu.mixpanel.com',
	'mixpanel.partly.com',
	/.*\.mixpanel\.com/,
	/.*\.transifex\.net/,
	/.*\.hotjar\.io/,
	/.*\.hubspot\.com/,
	/.*\.hubapi\.com/,
	/.*\.hscollectedforms\.net/,
	/.*\.hsbanner\.com/,
	/.*\.sentry\.com/
];

const propagationUrls: string[] = [
	'https://api.partly.com',
	'https://api.eu-1.repairer.partly.com',
	'https://api.au-1.repairer.partly.com',
	'https://api.au-2.repairer.partly.com',
	'https://api.dev1.partly.pro',
	'https://api.repairer-dev.partly.pro'
];

if (import.meta.env.DEV) {
	console.debug('Not initializing OpenTelemetry');
} else {
	console.debug('Enabling opentelemetry');
	try {
		initTelemetry({
			service_name: 'repairer-web',
			exporter: {
				type: 'otlp',
				otlp: {
					url: 'https://alloy.partly.pro/v1/traces'
				}
			},
			spanProcessor: { type: 'batch' },
			instrumentation: {
				'@opentelemetry/instrumentation-xml-http-request': {
					ignoreUrls: untracedUrls,
					propagateTraceHeaderCorsUrls: propagationUrls
				},
				'@opentelemetry/instrumentation-fetch': {
					ignoreUrls: untracedUrls,
					propagateTraceHeaderCorsUrls: propagationUrls
				}
			}
		});
	} catch (e) {
		console.warn('Failed to setup opentelemetry. Ignoring');
	}
}

if (config.sentryDSN === '' || import.meta.env.DEV) {
	console.debug('Not initializing Sentry');
} else {
	Sentry.init({
		dsn: config.sentryDSN,
		environment: getEnvironmentKey(),
		integrations: [
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false
			}),
			Sentry.replayCanvasIntegration(),
			Sentry.browserProfilingIntegration(),
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			})
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture all transactions
		// Control for which URLs distributed tracing using Sentry propagation
		// should be none of our backends as they all use opentelemetry tracing.
		tracePropagationTargets: [],

		// Ignore network transactions to third party services
		ignoreTransactions: untracedUrls,

		// Session Replay
		replaysSessionSampleRate: 1.0,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0
	});
}
