import { StorefrontResponse, createApi } from '@sdk/lib';
import { getConfig } from '../config';

export const unwrap = async <T>(request: () => Promise<StorefrontResponse<T>>): Promise<T> => {
	const { data, error } = await request();
	if (error) {
		throw error;
	}

	return data;
};

export const getApi = () => {
	const config = getConfig();
	return createApi(config);
};
