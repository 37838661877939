import { DEFAULT_CURRENCY } from './app/utils/currency';
import { getEnvironment } from './environment';

// FIXME: this is a hack that determins the site id for all permitted sites in the backend
export const ALL_SITES_ID = '00000000-0000-0000-0000-000000000000';

export const getBaseConfig = () => {
	const environment = getEnvironment();
	return {
		webKey: environment.webKey,
		basePath: environment.basePath,
		currencyCode: DEFAULT_CURRENCY,
		vehicle: {
			uvdbRegionId: environment.uvdbRegionId
		},
		sentryDSN: environment.sentryDSN
	};
};
