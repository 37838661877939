import { linkWithSearchParams } from '@/app/utils/url';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

type JobNumberLabelProps = {
	siteId: string;
	jobId?: string | null;
	jobNumber: string;
	claimNumber: string;
};
const JobNumberLabel = ({ siteId, jobId, jobNumber, claimNumber }: JobNumberLabelProps) => {
	const url = useMemo(
		() =>
			linkWithSearchParams('/jobs/create', {
				siteId,
				jobId: jobId ?? undefined,
				jobNumber,
				claimNumber
			}),
		[jobId, jobNumber]
	);
	return (
		<dl className="w-full">
			<div className="flex items-center justify-between w-full">
				<dt className="text-sm font-medium">Job number</dt>
				<Link to={url} className="text-sm font-medium text-theme-600">
					Start again
				</Link>
			</div>
			<dd className="w-full p-2 cursor-not-allowed opacity-60">{jobNumber}</dd>
		</dl>
	);
};

export default JobNumberLabel;
