import { CollisionAreaSeverity, JobPartInput } from '@sdk/lib';
import { analyticsGroup, schema } from './event-creator';

export const RepairhubEvents = analyticsGroup('repairhub')
	.registerEvent('job_deleted', schema<{ job_id: string }>())
	.registerEvent('job_upserted', schema<{ job_id: string; job_number: string }>())
	.registerEvent(
		'job_vehicle_upserted',
		schema<{
			job_id: string;
			plateNumber?: string;
			chassisNumber?: string;
			variant?: { description: string };
		}>()
	)
	.registerEvent(
		'job_collisions_upserted',
		schema<{ job_id: string; collisions: CollisionAreaSeverity[] }>()
	)
	.registerEvent('job_parts_upserted', schema<{ job_id: string; parts: JobPartInput[] }>())
	.registerEvent('job_cart_upserted', schema<{ job_id: string; cart_id: string }>())
	.registerEvent(
		'job_part_diagram_interaction',
		schema<{
			job_id: string;
			part: { mpn: string; gapc_brand_id: string; description: string };
			diagram: { name: string; code: string; part_slot: string };
		}>()
	)
	.registerEvent(
		'job_part_variants_ambiguity',
		schema<{
			job_id: string;
			job_number: string;
			vehicle: string;
			variants: { mpn: string; gapc_brand_id: string; description: string }[];
			is_showing_fitment_info: boolean;
		}>()
	)
	.registerEvent('job_subsequent_order_started', schema<{ job_id: string }>())
	.registerEvent('job_order_upserted', schema<{ job_id: string; order_ids: string[] }>());

export const AuthEvents = analyticsGroup('auth')
	.registerEvent('login', schema<{ email: string }>())
	.registerEvent('logout');
