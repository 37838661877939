import { Badge } from '@/app/atoms/badge';
import { BadgeProps, BadgeVariant } from '@/app/atoms/badge/types';
import { order } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { useMemo } from 'react';

type StatusInfo = {
	variant: BadgeVariant;
	text: string;
};

type OrderItemStatusProps = Omit<BadgeProps, 'variant' | 'children'> & {
	status: order.OrderItemStatus;
};

export const OrderItemStatus = ({ status, ...rest }: OrderItemStatusProps) => {
	const info = useMemo<StatusInfo>(
		() =>
			match(status, {
				pending: () => ({ variant: 'neutral', text: 'Pending' }),
				arrived: () => ({ variant: 'green', text: 'Arrived' }),
				returned: ({ reason }) => {
					const text: string = match(reason, {
						damaged: () => 'Damaged',
						incorrectly_supplied: () => 'Incorrectly supplied',
						incorrect_fitment: () => "Doesn't fit",
						not_required: () => 'No longer required',
						short_supplied: () => 'Short supplied',
						other: () => 'Returned'
					});

					return { variant: 'red', text };
				}
			}),
		[status]
	);

	return (
		<Badge variant={info.variant} {...rest}>
			{info.text}
		</Badge>
	);
};
