import { InheritableElementProps } from '@/types/utilties';
import { Badge } from '../../../../atoms/badge';

const statusProps = {
	Draft: {
		label: 'Draft',
		variant: 'white'
	},
	'In progress': {
		label: 'In progress',
		variant: 'blue'
	},
	'Parts ordered': {
		label: 'Ordered',
		variant: 'green'
	}
};

export type JobStatusBadgeProps = InheritableElementProps<
	'div',
	{
		status?: keyof typeof statusProps;
	}
>;

const JobStatusBadge = ({ status, ...rest }: JobStatusBadgeProps) => {
	if (!status) {
		return null;
	}

	const { label = 'Draft', variant = 'white' } = statusProps[status];

	return (
		<Badge className="ps-4 pe-4 py-0.5" size="small" variant={variant} rounded {...rest}>
			{label}
		</Badge>
	);
};

export default JobStatusBadge;
