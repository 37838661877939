// Constants for time thresholds (in seconds) and percentage ranges
// Percentage step for "returned"

import { useState, useEffect } from 'react';
const VIEWING_TIME_TO_50 = 600; // 10 minutes for pending to 50%
const VIEWING_TIMEFRAME = 300; // 5 minutes for viewing
const PROCESSING_TIMEFRAME = 300; // 5 minutes for processing
const RETURNED_TIMEFRAME = 120; // 2 minutes for returned

const MAX_PERCENTAGE_INITIAL = 50; // Max percentage for 0-50%
const VIEWING_PERCENTAGE_STEP = 10; // Percentage step for "viewing"
const PROCESSING_PERCENTAGE_STEP = 25; // Percentage step for "processing"
const RETURNED_PERCENTAGE_STEP = 15;

export const useOrderCompletion = (statusSetAt: Date, status: string) => {
	const [percentage, setPercentage] = useState<number>(0);

	useEffect(() => {
		const interval = setInterval(() => {
			const progress = calculateCompletionPercentage(statusSetAt, status);
			setPercentage(progress);
		}, 1000);

		return () => clearInterval(interval);
	}, [statusSetAt, status]);

	// Check if we aren't moving intentionally
	const stagnant = [50, 60, 85, 100].includes(percentage);

	return { percentage, stagnant };
};

const calculateCompletionPercentage = (stateSetAt: Date, status: string): number => {
	const now = new Date();
	const elapsedSeconds = (now.getTime() - stateSetAt.getTime()) / 1000;

	let percentage = 0;

	// "pending" or "initial state" (0-50%): Based on 10 minutes (600 seconds) from stateSetAt
	if (status === 'pending') {
		if (elapsedSeconds >= VIEWING_TIME_TO_50) {
			percentage = MAX_PERCENTAGE_INITIAL;
		} else {
			percentage = (elapsedSeconds / VIEWING_TIME_TO_50) * MAX_PERCENTAGE_INITIAL;
		}
	}

	// "viewing" state (50-60%): Adds based on 5 minutes (300 seconds)
	if (status === 'viewing') {
		if (elapsedSeconds >= VIEWING_TIMEFRAME) {
			percentage = Math.min(60, MAX_PERCENTAGE_INITIAL + VIEWING_PERCENTAGE_STEP);
		} else {
			percentage =
				MAX_PERCENTAGE_INITIAL + (elapsedSeconds / VIEWING_TIMEFRAME) * VIEWING_PERCENTAGE_STEP;
		}
	}

	// "processing" state (60-85%): Adds based on 5 minutes (300 seconds)
	if (status === 'processing') {
		if (elapsedSeconds >= PROCESSING_TIMEFRAME) {
			percentage = Math.min(85, 60 + PROCESSING_PERCENTAGE_STEP);
		} else {
			percentage = 60 + (elapsedSeconds / PROCESSING_TIMEFRAME) * PROCESSING_PERCENTAGE_STEP;
		}
	}

	// "returned" state (85-100%): Adds based on 2 minutes (120 seconds)
	if (status === 'returned') {
		if (elapsedSeconds >= RETURNED_TIMEFRAME) {
			percentage = 100;
		} else {
			percentage = 85 + (elapsedSeconds / RETURNED_TIMEFRAME) * RETURNED_PERCENTAGE_STEP;
		}
	}

	return Math.min(100, percentage);
};
