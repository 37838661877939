import { DiagramAssembly, DiagramPartSlot } from '../types';

export const partSlotConfidentSortKey = ({ assemblies }: DiagramPartSlot) =>
	-Math.max(...assemblies.map(({ confidence }) => confidence ?? 0));

export const partSlotClassificationSortKey = ({ assemblies }: DiagramPartSlot) =>
	assemblies.some(({ hcas }) => hcas.length > 0) ? -1 : 1;

export const partSlotHcaSortKey = ({ assemblies }: DiagramPartSlot) =>
	assemblies.map(assemblyHcaSortKey).reduce((acc, curr) => (curr < acc ? curr : acc), 'z');

export const assemblyHcaSortKey = (assembly: DiagramAssembly) => {
	if (assembly.hcas.length === 0) {
		return assembly.description;
	}
	return `${assembly.description} - ${assembly.hcas[0]}`;
};

export const assemblyClassificationSortKey = (assembly: DiagramAssembly) =>
	assembly.hcas.length > 0 ? -1 : 1;
