import { useReadonlyPresence, VendorOrderProcessing } from '@partly/driftdb-client';
import { useMemo } from 'react';
import { OrderRequestModel } from '../../models';
import { useOrderCompletion } from '../use-realtime-processing';

export const useRealtimeProgress = (model: OrderRequestModel) => {
	const vendorName = model.vendor.Partner.name;
	const presence = useReadonlyPresence<VendorOrderProcessing>(model.local_id);

	const data = useMemo(() => {
		const state = Object.values(presence)[0];
		if (!state) {
			return {
				message: `Someone from ${vendorName} will respond soon`,
				status: 'pending',
				date: new Date(model.updated_at ?? model.created_at)
			};
		}

		const username = state.value.user.name;
		switch (state.value.status) {
			case 'viewing': {
				return {
					message: `${username} is viewing your request`,
					status: 'viewing',
					date: new Date(state.value.timestamp)
				};
			}
			case 'processing': {
				return {
					message: `${username} is processing your request`,
					status: 'processing',
					date: new Date(state.value.timestamp)
				};
			}
			case 'returned': {
				return {
					message: `${username} has finalised your request`,
					status: 'returned',
					date: new Date(state.value.timestamp)
				};
			}
		}
	}, [presence]);

	const { percentage, stagnant } = useOrderCompletion(data.date, data.status);

	return {
		message: data.message,
		percentage,
		stagnant
	};
};
