import { JobPart } from '@/sdk/lib';
import { useMemo } from 'react';

export const useNoSupplyParts = (
	jobParts: JobPart[],
	identities: Record<string, true>
): JobPart[] => {
	const noSupplyParts = useMemo(() => {
		return jobParts.filter(part => !identities[part.mpn]);
	}, [jobParts, identities]);

	return noSupplyParts;
};
