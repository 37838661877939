import { JobVehicle } from '@/sdk/lib';
import { InheritableElementProps } from '@/types/utilties';

type VehicleDetailsProps = InheritableElementProps<
	'dl',
	{
		vehicle: JobVehicle | null;
	}
>;

export const VehicleDetails = ({ vehicle, ...rest }: VehicleDetailsProps) => {
	if (!vehicle) {
		return null;
	}

	const description = vehicle.variant?.description;

	return (
		<dl {...rest}>
			<dt className="sr-only">Vehicle:</dt>
			<dd className="text-sm font-medium text-gray-900">{description}</dd>
			<dt className="sr-only">Plate / Chassis Number</dt>
			<dd className="text-sm text-gray-700">
				{(vehicle.plateNumber ?? vehicle.chassisNumber)?.toUpperCase()}
			</dd>
		</dl>
	);
};
