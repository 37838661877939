import { InheritableElementProps } from '@/types/utilties';
import { Card, Text } from '@mantine/core';
import { VehicleConfiguration } from '@sdk/lib';
import { createVehicleDescription, createVehicleName } from '@utils/vehicle';
import { useMemo } from 'react';

type VehicleCardProps = InheritableElementProps<
	'div',
	{
		vehicle: VehicleConfiguration;
	}
>;

export const VehicleCard = ({ vehicle, className, ...rest }: VehicleCardProps) => {
	const [title, description] = useMemo<string[]>(() => {
		return [createVehicleName(vehicle), createVehicleDescription(vehicle)];
	}, [vehicle]);

	return (
		<Card withBorder className={className} {...rest}>
			<Text size="md" className="font-semibold">
				{title}
			</Text>
			<Text className="text-gray-500 text-sm mt-1">{description}</Text>
		</Card>
	);
};
