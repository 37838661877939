import { VehiclesService } from '@/sdk/generated';
import { createSearchVehicles } from '../transformers/vehicle';
import {
	ApiConfig,
	SearchVehiclePayload,
	SearchVehiclesResult,
	StorefrontResponse
} from '../types';
import { recursiveToSnake } from '../utils/casing';
import { execute } from '../utils/execute';

export const createSearchVehiclesEndpoint = (
	vehicleService: VehiclesService,
	config: ApiConfig
) => {
	const searchVehicles = (
		payload: SearchVehiclePayload
	): Promise<StorefrontResponse<SearchVehiclesResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await vehicleService.searchVehicles(snakePayload);

			return createSearchVehicles(result, config);
		});

	return { searchVehicles };
};
