import { CollisionAreaSeverity } from '@sdk/lib';
import { compact, values } from 'lodash-es';
import { CollisionAreaFormData } from './types';

export const createCollisionSelection = (
	collisions: CollisionAreaSeverity[],
	readonly = false
): CollisionAreaFormData =>
	Object.fromEntries(
		collisions.map(({ id, severity }) => [
			id,
			{
				id,
				severity,
				readonly
			}
		])
	);

export const createCollisionSeverities = (
	selection: CollisionAreaFormData
): CollisionAreaSeverity[] =>
	compact(values(selection)).map(({ id, severity }) => ({
		id,
		severity
	}));
