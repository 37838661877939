import { Section } from '@/app/templates/page-section';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { Button } from '@mantine/core';

// temp component, will be updated to be functional.

export const JobImageGrid = ({ className }: InheritableElementProps<'section', object>) => {
	return (
		<Section className={tlsx('bg-white p-6 border rounded-md', className)}>
			<div className="flex items-center justify-between">
				<Section.Title className="text-lg">Images</Section.Title>
				<Button variant="subtle" color="blue" disabled>
					Edit
				</Button>
			</div>
			<div className="grid grid-cols-3 gap-2 mt-4">
				<div className="col-span-1 aspect-square bg-gray-50 rounded-md" />
				<div className="col-span-1 aspect-square bg-gray-50 rounded-md" />
				<div className="col-span-1 aspect-square bg-gray-50 rounded-md" />
				<div className="col-span-1 aspect-square bg-gray-50 rounded-md" />
			</div>
		</Section>
	);
};
