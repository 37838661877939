import { useAuth } from '@/app/hooks/use-auth';
import React from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

const DEFAULT_REDIRECT_PATH = '/';
const REDIRECT_SEARCH_PARAM = 'redirect-to';

type HocComponentProps = Record<string, unknown>;

/**
 * Redirect the user to the sign in page if they
 * are not signed in in, then return them back to
 * where they tried to access before.
 */
export const withSignedIn = <T extends HocComponentProps>(
	Component: React.FunctionComponent<T>
) => {
	return function WithSignedIn(props: T) {
		const { isAuthenticated } = useAuth();
		const { pathname, search } = useLocation();

		if (isAuthenticated) {
			return <Component {...props} />;
		}

		// Set a redirect url to where the user was trying to go
		// so we can redirect them back after sign-in.
		//
		// Only set the redirect url if it is not the default

		const searchParams: URLSearchParams = new URLSearchParams('');
		if (search || pathname !== DEFAULT_REDIRECT_PATH) {
			searchParams.set(REDIRECT_SEARCH_PARAM, pathname + search);
		}

		return <Navigate to={{ pathname: '/sign-in', search: searchParams.toString() }} />;
	};
};

/**
 * Redirect the user to the `redirectUrl` if present,
 * falling back to the root path if they are not signed in.
 */
export const withRedirectIfSignedIn = <T extends HocComponentProps>(
	Component: React.FunctionComponent<T>
) => {
	return function WithRedirectIfSignedIn(props: T) {
		const { isAuthenticated } = useAuth();
		const [searchParams] = useSearchParams();

		if (!isAuthenticated) {
			return <Component {...props} />;
		}

		const redirectUrl: string | undefined =
			searchParams.get(REDIRECT_SEARCH_PARAM) ?? DEFAULT_REDIRECT_PATH;
		return <Navigate to={redirectUrl} />;
	};
};
