import { createPartSlotIds } from '@/app/utils/part';
import { GapcDiagram, GapcDiagramPartSlot, PartAssembly, PartSubAssembly } from '@/sdk/lib';
import { compact, entries, groupBy, isNil, minBy, partition, sortBy, uniqBy } from 'lodash-es';
import {
	CategoryTree,
	CategoryTreeLeaf,
	Diagram,
	DiagramAssembly,
	DiagramAssemblyResources,
	DiagramMesh,
	DiagramPartSlot
} from '../types';
import { assignDiagramPartSlotCode } from './code';
import {
	line2Distance,
	line2ToCenterPoint,
	line2ToRect,
	polygonToCenterPoint,
	vec2
} from './geometry';
import {
	partSlotClassificationSortKey,
	partSlotConfidentSortKey,
	partSlotHcaSortKey
} from './sort';
import { diffFitments } from './variants';

export const slice = <T extends any[]>(args: readonly [...T]) => args as T;

export const categoriesDiagrams = (_assemblies: PartAssembly[]) => {
	const trimmed = compact(_assemblies.map(trimPartAssembly));
	const [hcas, relevants] = partition(
		trimmed,
		({ assemblyType }) => assemblyType === 'human_centric'
	);

	const resources = createResources(
		trimmed.flatMap(assembly => transformDiagramAssembly(assembly))
	);
	const categories = sortBy(
		hcas.map(hca => transformCategoryTree(hca, resources, [])),
		({ kind }) => (kind === 'node' ? -1 : 1),
		({ description }) => description
	);

	const other = transformOtherCategoryTree(relevants, resources);

	return { categories, other, resources };
};

const createResources = (assemblies: DiagramAssembly[]) => {
	return {
		assemblies: new Map(assemblies.map(assembly => slice([assembly.id, assembly]))),
		parts: new Map(
			entries(groupBy(assemblies, assembly => assembly.part.partIdentity)).map(
				([partIdentity, assemblies]) => [partIdentity, uniqBy(assemblies, ({ id }) => id)] as const
			)
		)
	};
};

export const categoryLeaves = (category: CategoryTree): CategoryTreeLeaf[] => {
	if (category.kind === 'leaf') {
		return [category];
	}
	return category.assemblies.flatMap(categoryLeaves);
};

const transformCategoryTree = (
	hca: PartAssembly,
	resources: DiagramAssemblyResources,
	hcas: string[]
): CategoryTree => {
	// leaf is the last possible category which will have diagrams on it instead of more tree node
	// figuring a leaf dynamically (O2T1 + O2T3):
	// - if a node is not a "Cut", and
	// - if a node has child assemblies that are parts, or
	// - if a node is a part, or
	// - if a node is layer 3 or deeper (higher number, lower layer)
	const isFinalLayer = isLeafPart(hca);
	const isTooDeep = hcas.length > 3;
	const isCut = hca.description.includes('Cut');

	const isLastCategory = hca.subAssemblies.filter(isLeafPart).length > 0;

	const isLeaf = !isCut && (isFinalLayer || isTooDeep || isLastCategory);

	if (isLeaf) {
		const diagrams = sortBy(
			uniqBy(getAllDiagrams(hca), ({ id }) => id).map(diagram =>
				transformDiagram(diagram, resources, [...hcas, hca.description])
			),
			({ code }) => code
		);
		return {
			kind: 'leaf',
			id: hca.id,
			description: hca.description,
			diagrams,
			hcas,
			searchables: [hca.description]
		};
	}

	const assemblies = sortBy(
		compact(
			hca.subAssemblies.map(sub =>
				sub.kind === 'single'
					? transformCategoryTree(sub.assembly, resources, [...hcas, hca.description])
					: transformCategoryTree(sub.variants[0], resources, [...hcas, hca.description])
			)
		),
		({ kind }) => (kind === 'node' ? -1 : 1),
		({ description }) => description
	);

	return {
		kind: 'node',
		id: hca.id,
		description: hca.description,
		hcas,
		assemblies,
		searchables: [hca.description]
	};
};

const transformOtherCategoryTree = (
	relevants: PartAssembly[],
	resources: DiagramAssemblyResources
): CategoryTreeLeaf => {
	const diagrams = sortBy(
		uniqBy(relevants.flatMap(getAllDiagrams), ({ id }) => id),
		({ code }) => code,
		({ name }) => name
	).map(diagram => transformDiagram(diagram, resources));

	return {
		kind: 'leaf',
		id: 'rlvt',
		description: 'Other diagrams',
		diagrams,
		searchables: diagrams.flatMap(({ searchables }) => searchables),
		hcas: []
	};
};

const transformDiagram = (
	diagram: GapcDiagram,
	resources: DiagramAssemblyResources,
	hcas: string[] = []
): Diagram => {
	const partSlots = diagram.partSlots.map(({ id, parts, ...partSlot }, index): DiagramPartSlot => {
		const code = `${index + 1}`;
		const assemblies = transformDiagramPartSlotAssemblies(
			{ id, parts, ...partSlot },
			resources,
			hcas.at(0)
		).map(({ code: _, ...rest }) => ({ ...rest, code }));

		const hotspots = partSlot.hotspots.map(({ x1Px, x2Px, y1Px, y2Px }) =>
			slice([vec2(x1Px, y1Px), vec2(x2Px, y2Px)])
		);

		const polygons = partSlot.segments.map(({ vectors }) => vectors.map(({ x, y }) => vec2(x, y)));

		const lines =
			hotspots.length === 1 || polygons.length === 1
				? polygons.flatMap(polygon =>
						hotspots.map(hotspot => slice([[line2ToCenterPoint(hotspot)], polygon]))
					)
				: polygons.map(polygon => {
						const to = polygonToCenterPoint(polygon);
						const hotspot =
							minBy(hotspots, hotspot => line2Distance([line2ToCenterPoint(hotspot), to])) ??
							hotspots[0];
						return slice([[line2ToCenterPoint(hotspot)], polygon]);
					});

		return {
			id,
			code,
			pnc: partSlot.code,
			assemblies,
			meshes: [
				...hotspots.map((line): DiagramMesh => ({ kind: 'whiteout', rect: line2ToRect(line) })),
				...lines.map(
					([from, to]): DiagramMesh => ({
						kind: 'line',
						from,
						to
					})
				),
				...polygons.map((polygon): DiagramMesh => ({ kind: 'polygon', polygon })),
				...hotspots.map(
					(line): DiagramMesh => ({ kind: 'hotspot', point: line2ToCenterPoint(line) })
				)
			]
		};
	});
	return {
		id: diagram.id,
		code: diagram.code,
		description: diagram.name,
		fitment: diagram.fitment,
		image: {
			full: diagram.image.large,
			thumb: diagram.image.thumb
		},
		hcas,
		searchables: [diagram.name],
		partSlots: assignDiagramPartSlotCode(
			sortBy(partSlots, partSlotConfidentSortKey, partSlotClassificationSortKey, partSlotHcaSortKey)
		)
	};
};

const transformDiagramPartSlotAssemblies = (
	{ id, parts, ...partSlot }: GapcDiagramPartSlot,
	resources: DiagramAssemblyResources,
	hca?: string
) => {
	if (partSlot.assemblies.length > 0) {
		return diffFitments(compact(partSlot.assemblies.map(id => resources.assemblies.get(id))));
	}

	const assemblies = compact(
		parts.flatMap(({ partIdentity }) => (partIdentity ? resources.parts.get(partIdentity) : []))
	);

	// if it's not in an hca for some reason, show unique ones by mpn
	if (!hca) {
		return diffFitments(uniqBy(assemblies, ({ part }) => part.partIdentity));
	}

	// if for an hca, smartly not have multiple matches that are irrelevant
	const filtered = assemblies.filter(({ hcas }) => hcas.includes(hca));

	if (filtered.length > 0) {
		return diffFitments(
			uniqBy(
				uniqBy(filtered, ({ id }) => id),
				({ part }) => part.partIdentity
			)
		);
	}

	// fallback to show unique by mpn
	return diffFitments(uniqBy(assemblies, ({ part }) => part.partIdentity));
};

const transformDiagramAssembly = (
	assembly: PartAssembly,
	hcas: string[] = []
): DiagramAssembly[] => {
	const assemblies: DiagramAssembly[] = [];

	// is part also
	if (!isNil(assembly.part)) {
		const part: DiagramAssembly = {
			id: assembly.id,
			description: assembly.description,
			code: '',

			part: assembly.part,
			partSlot: assembly.partSlot,
			partSlotIds: createPartSlotIds(assembly.partSlot),
			hcas,
			searchables: [assembly.description, assembly.part.mpn],

			fitment: assembly.fitment,
			attributes: assembly.attributes,
			confidence: assembly.confidence,
			availability: assembly.supply?.availability,
			grades: assembly.supply?.grades
		};

		assemblies.push(part);
	}

	assemblies.push(
		...compact(
			assembly.subAssemblies.flatMap(sub => {
				if (sub.kind === 'single') {
					return transformDiagramAssembly(sub.assembly, [...hcas, assembly.description]);
				} else {
					return sub.variants.flatMap(v =>
						transformDiagramAssembly(v, [...hcas, assembly.description])
					);
				}
			})
		)
	);

	return assemblies;
};

const trimPartAssembly = (assembly: PartAssembly): PartAssembly | null => {
	const subAssemblies = compact(assembly.subAssemblies.map(trimPartSubassembly));

	// no child, no part attached
	if (subAssemblies.length === 0 && isNil(assembly.part)) {
		return null;
	}

	return {
		...assembly,
		subAssemblies
	};
};

const trimPartSubassembly = (sub: PartSubAssembly): PartSubAssembly | null => {
	if (sub.kind === 'single') {
		const assembly = trimPartAssembly(sub.assembly);
		return !isNil(assembly) ? { kind: 'single', assembly } : null;
	}
	const variants = compact(sub.variants.map(trimPartAssembly));
	if (variants.length === 0) {
		return null;
	}
	if (variants.length === 1) {
		return { kind: 'single', assembly: variants[0] };
	}
	return { kind: 'variants', variants };
};

// get all diagrams recursively in the assembly (need to stop getting diagram based on mpn, loses context on multi-purposes)
const getAllDiagrams = (assembly: PartAssembly): GapcDiagram[] => {
	const diagrams = (assembly.diagrams ?? []).map(diagram => ({
		...diagram,
		partSlots: diagram.partSlots
	}));

	const more = assembly.subAssemblies.flatMap(sub =>
		sub.kind === 'single' ? getAllDiagrams(sub.assembly) : sub.variants.flatMap(getAllDiagrams)
	);

	return uniqBy([...diagrams, ...more], ({ id }) => id);
};

const isLeafPart = (hca: PartAssembly | PartSubAssembly) => {
	if (!('kind' in hca)) {
		return !isNil(hca.part);
	}
	return hca.kind === 'variants' || !isNil(hca.assembly.part);
};
