import { EmptyState } from '@/app/atoms/empty-state';
import DeleteJobDialog from '@/app/features/jobs/components/delete-job-dialog';
import JobSearchBar from '@/app/features/jobs/components/job-search-bar';
import JobTable from '@/app/features/jobs/components/job-table';
import JobTableSkeleton from '@/app/features/jobs/components/job-table-skeleton';
import { withSignedIn } from '@/app/hoc/with-access';
import { useSearchQuery } from '@/app/hooks/use-search-param';
import { tlsx } from '@/app/utils/tw-merge';
import { ReactComponent as VehicleIcon } from '@assets/vehicle.svg';
import { PlusIcon } from '@heroicons/react/24/solid';
import { Button } from '@mantine/core';
import { Job, SearchJobsRequest } from '@sdk/lib';
import { jobsQueries } from '@sdk/react';
import { PageTemplate } from '@templates/page-template';
import { useMemo, useState } from 'react';

import { Site, useAuthenticatedUser } from '@/app/common/hooks/use-me';
import { JobSiteSelection } from '@/app/features/jobs/components/job-site-selection';
import { signOut } from '@/app/utils/auth';
import { ALL_SITES_ID } from '@/config';
import { getApi } from '@/sdk/react/utils/helpers';
import { useAnalytics } from '@hooks/use-analytics';
import { useInfiniteQuery } from '@tanstack/react-query';
import { AuthEvents } from '@utils/analytics/events.ts';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

const JobsPage = () => {
	const { sites } = useAuthenticatedUser();

	const siteIdParam = useSearchQuery('siteId', { defaultValues: [ALL_SITES_ID] });
	const siteId = siteIdParam.values.at(0)!;

	const analytics = useAnalytics();
	const keyword = useSearchQuery('keyword');
	const [debouncedKeyword] = useDebounce(keyword.values[0]?.trim() ?? '', 250);
	const [jobToDelete, setJobToDelete] = useState<Job>();

	const payload: SearchJobsRequest = {
		repairerSiteIds: siteId === ALL_SITES_ID ? sites.map(site => site.id) : [siteId],
		keyword: debouncedKeyword || undefined
	};
	const { queryKey } = jobsQueries.search(payload);

	const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
		queryKey,
		queryFn: async ({ pageParam: cursor }: { pageParam: string | null }) => {
			const { data, error } = await getApi().jobs.search({ cursor, ...payload });
			if (error) {
				throw error;
			}

			return data;
		},
		initialPageParam: null,

		// must return undefined so react query can know if there's no next/previous page
		getNextPageParam: lastPage => lastPage.pagination.nextCursor ?? undefined,
		getPreviousPageParam: firstPage => firstPage.pagination.previousCursor ?? undefined
	});

	const selectedSite = useMemo(() => {
		if (siteId === ALL_SITES_ID) {
			return {
				id: ALL_SITES_ID,
				name: 'All Sites',
				kind: 'site',
				shipping_address: undefined,
				user_groups: []
			} as Site;
		}

		const site = sites.find(site => site.id === siteId);
		if (!site) {
			return null;
		}
		const { name, ...rest } = site;
		if (!name.includes('MotorHub')) {
			return { name, ...rest };
		}
		// todo (vincent) hack to remove org out of name
		const names = name.split(' ');
		return { ...rest, name: (names.length >= 2 ? names.slice(1) : names).join(' ') };
	}, [sites, siteId]);

	const signOutUserWithEvent = () => {
		analytics.logEvent(AuthEvents.logout({}));
		signOut();
	};

	const jobs = useMemo(
		() =>
			data?.pages.flatMap(({ jobs }) => jobs).filter(({ status }) => status !== 'Deleted') ?? [],
		[data]
	);

	const createJob = useMemo(() => {
		if (!selectedSite || selectedSite.id === ALL_SITES_ID) {
			return `/jobs/create`;
		}
		return `/jobs/create?siteId=${selectedSite?.id}`;
	}, [selectedSite]);

	const hasJobs = jobs.length > 0;

	return (
		<PageTemplate className={tlsx('p-0', { 'justify-center items-center': !hasJobs })}>
			<PageTemplate.Header className="p-0" borderOnScroll>
				<nav className="grid grid-cols-3 w-full px-8 py-4">
					<JobSiteSelection
						site={selectedSite}
						onSelectSite={siteIdParam.set}
						onLogout={signOutUserWithEvent}
					/>
					<JobSearchBar className="w-full" />
					<Button
						component={Link}
						to={createJob}
						className="text-sm font-medium w-fit ml-auto"
						data-testid="job-create"
						leftIcon={<PlusIcon className="w-5 h-5 text-white" />}
					>
						New Job
					</Button>
				</nav>
			</PageTemplate.Header>
			{hasJobs ? (
				<JobTable
					sites={sites}
					jobs={jobs}
					hasNextPage={hasNextPage}
					isNextPageLoading={isFetchingNextPage}
					loadNextPage={fetchNextPage}
					onDelete={setJobToDelete}
				/>
			) : isLoading ? (
				<JobTableSkeleton />
			) : (
				<EmptyState>
					<EmptyState.Icon as={VehicleIcon} />
					<EmptyState.Title>No jobs found</EmptyState.Title>
					<EmptyState.Description>
						Start a job by tapping the plus '+' button and adding the vehicle's license plate.
					</EmptyState.Description>
				</EmptyState>
			)}
			<DeleteJobDialog
				siteId={siteIdParam.values.at(0)!}
				job={jobToDelete}
				onClose={() => setJobToDelete(undefined)}
			/>
		</PageTemplate>
	);
};

export default withSignedIn(JobsPage);
