// scaling up all mesh by a measure of 10 gives a full screen-ish size
export const MESH_SCALE = 10;

// default zoom / distance (in z axis), higher is lower zoom, lower is higher zoom
export const DEFAULT_DISTANCE_Z = 5;

// max / min scale for zooming in/out
export const MAX_MIN_DISTANCE_Z = 4;

// amount of zooming in/out before hitting the max (from 100%)
export const ZOOM_STEPS = 5;

// amount of distance increase/decrease each step
export const DISTANCE_Z_STEP = MAX_MIN_DISTANCE_Z / ZOOM_STEPS;
