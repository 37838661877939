import { Badge } from '@/app/atoms/badge';
import { BadgeProps, BadgeVariant } from '@/app/atoms/badge/types';
import { draft_order } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { useMemo } from 'react';

type StatusInfo = {
	variant: BadgeVariant;
	text: string;
};

type OrderRequestStatusProps = Omit<BadgeProps, 'variant' | 'children'> & {
	status: draft_order.DraftOrderStatus;
};

export const OrderRequestStatus = ({ status, ...rest }: OrderRequestStatusProps) => {
	const info = useMemo<StatusInfo>(
		() =>
			match(status, {
				Draft: () => ({ variant: 'neutral', text: 'Draft' }),
				Cancelled: () => ({ variant: 'red', text: 'Cancelled' }),
				Processed: () => ({ variant: 'green', text: 'Processed' }),
				Processing: () => ({ variant: 'neutral', text: 'Processing' }),
				Finalised: () => ({ variant: 'green', text: 'Finalised' })
			}),
		[status]
	);

	return (
		<Badge variant={info.variant} {...rest}>
			{info.text}
		</Badge>
	);
};
