import { UvdbSuidPrefixRaw } from '@/sdk/generated';
import { GapcPropertyKey, UvdbPropertyKey } from '../types';

type GapcSuidPrefix =
	// Brand
	| 'GBRN'
	// Category
	| 'GCCA'
	// Part codeß
	| 'GCPC'
	// Part type
	| 'GCPT'
	// Position
	| 'GCPO'
	// Subcategory
	| 'GCSC'
	// Tyre
	| 'GTYR'
	// Wheel
	| 'GWHE'
	// Attribute
	| 'GATR';

const GapcSuidMapper: Record<GapcSuidPrefix, GapcPropertyKey> = {
	GBRN: 'GapcBrand',
	GCCA: 'GapcCategory',
	GCPC: 'GapcPartCode',
	GCPT: 'GapcPartType',
	GCPO: 'GapcPosition',
	GCSC: 'GapcSubcategory',
	GTYR: 'GapcTyre',
	GWHE: 'GapcWheel',
	GATR: 'GapcAttribute'
};

// "UPY" & "UPM" are deprecated, replaced with UPYE & UPMO
type ApplicationSuidPrefix = 'UPY' | 'UPM';

type UvdbPropertyPrefixes = UvdbSuidPrefixRaw | ApplicationSuidPrefix;

const UvdbSuidMapper: Record<UvdbPropertyPrefixes, UvdbPropertyKey> = {
	UASP: 'UvdbAspiration',
	UBLN: 'UvdbBedLength',
	UBET: 'UvdbBedType',
	UBND: 'UvdbBodyNumDoors',
	UBOT: 'UvdbBodyType',
	UBBS: 'UvdbBrakeAbs',
	UBSY: 'UvdbBrakeSystem',
	UCHT: 'UvdbCylinderHeadType',
	UDRT: 'UvdbDriveType',
	UEBA: 'UvdbEngineBase',
	UEDN: 'UvdbEngineDesignation',
	UEPO: 'UvdbEnginePowerOutput',
	UEVS: 'UvdbEngineVersion',
	UENG: 'UvdbEngineDefinition',
	UFDS: 'UvdbFuelDeliverySubtype',
	UFDT: 'UvdbFuelDeliveryType',
	UFSC: 'UvdbFuelSystemControlType',
	UFSD: 'UvdbFuelSystemDesign',
	UFTY: 'UvdbFuelType',
	UGST: 'UvdbIgnitionSystemType',
	UMAK: 'UvdbMake',
	UMBC: 'UvdbMfrBodyCode',
	UMOD: 'UvdbModel',
	UREG: 'UvdbRegion',
	URES: 'UvdbRestriction',
	UVSE: 'UvdbSeries',
	USSD: 'UvdbSteeringSide',
	USSY: 'UvdbSteeringSystem',
	USTT: 'UvdbSteeringType',
	USUB: 'UvdbSubmodel',
	UTCT: 'UvdbTransmissionControlType',
	UTEC: 'UvdbTransmissionElecControl',
	UTMC: 'UvdbTransmissionMfrCode',
	UTNS: 'UvdbTransmissionNumSpeeds',
	UTTY: 'UvdbTransmissionType',
	UVLV: 'UvdbValves',
	UDEF: 'UvdbVehicleDefinition',
	UVES: 'UvdbVehicleSubtype',
	UVET: 'UvdbVehicleType',
	UEVN: 'UvdbEngineVin',
	UWHE: 'UvdbWheel',
	UWHB: 'UvdbWheelBase',
	UWHC: 'UvdbWheelConfig',
	UPYF: 'UvdbYearFrom',
	UPYT: 'UvdbYearTo',
	UPMF: 'UvdbMonthFrom',
	UPMT: 'UvdbMonthTo',
	UEMF: 'UvdbEngineManufacturer',
	UTMF: 'UvdbTransmissionManufacturer',
	UFST: 'UvdbFrontSpringType',
	URST: 'UvdbRearSpringType',
	UFBT: 'UvdbFrontBrakeType',
	URBT: 'UvdbRearBrakeType',
	UPYE: 'UvdbYear',
	UPMO: 'UvdbMonth',
	// "UPY" & "UPM" are deprecated, replaced with UPYE & UPMO
	UPY: 'UvdbYear',
	UPM: 'UvdbMonth'
};

export const getUvdbSuidType = (id: string): UvdbPropertyKey | null => {
	if (id.length < 3) {
		return null;
	}

	let prefix = id.substring(0, 4).toUpperCase() as UvdbPropertyPrefixes;
	if (/\d/.test(prefix[3])) {
		prefix = prefix.substring(0, 3).toUpperCase();
	}

	return UvdbSuidMapper[prefix as UvdbPropertyPrefixes] ?? null;
};

export const getUvdbSuidTypes = (ids: string[]): UvdbPropertyKey[] => {
	return ids.map(getUvdbSuidType).filter((item): item is UvdbPropertyKey => !!item);
};

export const getGapcSuidType = (id: string): GapcPropertyKey | null => {
	if (id.length <= 4) {
		return null;
	}

	const prefix = id.substring(0, 4).toUpperCase() as GapcSuidPrefix;
	return GapcSuidMapper[prefix] ?? null;
};

export const getSuidType = (id: string): GapcPropertyKey | UvdbPropertyKey | null => {
	const gapcType = getGapcSuidType(id);
	if (gapcType) {
		return gapcType;
	}

	const uvdbType = getUvdbSuidType(id);
	if (uvdbType) {
		return uvdbType;
	}

	return null;
};
