import { fuzzyIncludes } from '@/app/utils/string';
import { CategoryTree, CategoryTreeLeaf, Diagram } from '../types';

export const filterCategories = (categories: CategoryTree[], query?: string): CategoryTree[] => {
	if (!query) {
		return categories;
	}
	return categories
		.map(category => {
			if (category.searchables.some(searchable => fuzzyIncludes(searchable, query))) {
				return category;
			}

			if (category.kind === 'leaf') {
				return {
					...category,
					diagrams: filterDiagrams(category.diagrams, query)
				};
			}
			return {
				...category,
				assemblies: filterCategories(category.assemblies, query)
			};
		})
		.filter(({ searchables, ...category }) => {
			if (searchables.some(searchable => fuzzyIncludes(searchable, query))) {
				return true;
			}

			if (category.kind === 'leaf') {
				return category.diagrams.length > 0;
			}
			return category.assemblies.length > 0;
		});
};

export const filterOtherCategory = (
	category: CategoryTreeLeaf,
	query?: string
): CategoryTreeLeaf | null => {
	if (!query) {
		return category;
	}

	const diagrams = filterDiagrams(category.diagrams, query);

	if (diagrams.length === 0) {
		return null;
	}

	return {
		...category,
		diagrams
	};
};

export const filterDiagrams = (diagrams: Diagram[], query: string): Diagram[] => {
	return diagrams.filter(({ searchables, partSlots }) => {
		const isDiagramMatching = searchables.some(searchable => fuzzyIncludes(searchable, query));
		const isAnyPartMatching = partSlots
			.flatMap(({ assemblies }) => assemblies)
			.flatMap(({ searchables }) => searchables)
			.some(searchable => fuzzyIncludes(searchable, query));

		return isAnyPartMatching || isDiagramMatching;
	});
};
