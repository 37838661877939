import { tlsx } from '@/app/utils/tw-merge';
import { ElementType, HTMLAttributes } from 'react';

export const EmptyState = ({ className, ...rest }: HTMLAttributes<HTMLDivElement>) => (
	<div
		className={tlsx(
			'flex flex-col items-center justify-center gap-2 px-6 py-12 text-center bg-gray-100 rounded-md',
			className
		)}
		{...rest}
	/>
);

type SubProps = HTMLAttributes<HTMLElement> & { as?: ElementType };

const Icon = ({ as: Component = 'svg', className, ...rest }: SubProps) => (
	<Component className={tlsx('w-6 h-6 text-gray-900', className)} {...rest} />
);

const Title = ({ as: Component = 'h2', className, ...rest }: SubProps) => (
	<Component className={tlsx('text-base font-semibold text-gray-900', className)} {...rest} />
);

const Description = ({ as: Component = 'p', className, ...rest }: SubProps) => (
	<Component className={tlsx('text-sm text-gray-600', className)} {...rest} />
);

EmptyState.Icon = Icon;
EmptyState.Title = Title;
EmptyState.Description = Description;
