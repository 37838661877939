import { gapc_part, resource } from './reflect';

export type ResouceMapKey = keyof resource.ResourcesMap;

type ExtractRecordValue<T> = T extends Record<string, infer U> ? U : never;

type ResourceTypeMap = NoUndefinedField<{
	[K in keyof resource.ResourcesMap]: ExtractRecordValue<resource.ResourcesMap[K]>;
}>;

type NoUndefinedField<T> = { [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>> };

type IResourcePath = {
	path: ResouceMapKey;
	id: string;
};

function normalizeMPN(mpn: string): string {
	const removeLeadingZeros = /^0+/;
	const removeSpecialChars = /[-\s"'()+]/gi;

	let normalizedMpn = mpn.trim();
	normalizedMpn = normalizedMpn.replace(removeLeadingZeros, '');
	normalizedMpn = normalizedMpn.replace(removeSpecialChars, '');

	return normalizedMpn.toUpperCase();
}

export class ResourceMap {
	private constructor(private props: resource.ResourcesMap) {}

	public get<K extends ResouceMapKey>(
		resourcePath: ResourcePath<K> | { path: ResouceMapKey; id: string }
	): ResourceTypeMap[K] | null {
		const resource = this.props[resourcePath.path];
		if (!resource) {
			return null;
		}

		const item = resource[resourcePath.id] as ResourceTypeMap[K];
		if (!item) {
			return null;
		}

		return item;
	}

	public static create(resourceMap: resource.ResourcesMap): ResourceMap {
		return new ResourceMap(resourceMap);
	}
}

export class ResourcePath<K extends ResouceMapKey> {
	private constructor(private props: IResourcePath) {}

	public get path(): K {
		return this.props.path as K;
	}

	public get id(): string {
		return this.props.id;
	}

	public static create<K extends keyof resource.ResourcesMap>(
		resourcePath: string
	): ResourcePath<K> | null {
		const [path, id] = resourcePath.split('/');

		if (!path) {
			return null;
		}

		if (!id) {
			return null;
		}

		return new ResourcePath<K>({
			path: path as K,
			id
		});
	}
}

export function encodeGapcPartIdentityKey(val: gapc_part.GapcPartIdentity): string {
	return `(${val.gapc_brand_id},${normalizeMPN(val.mpn)})`;
}

export function decodeGapcPartIdentityKey(val: string): gapc_part.GapcPartIdentity {
	const [gapc_brand_id, mpn] = val.slice(1, -1).split(',');
	return {
		gapc_brand_id,
		mpn
	};
}
