import {
	NormalizedResponse_for_VehicleResponsePayload_and_NullRaw,
	VehicleConfigurationRaw
} from '../../generated';
import { ApiConfig, SearchVehiclesResult, VehicleConfiguration } from '../types';
import { Context } from '../utils/context';
import { createUvdbProperties } from './properties';

type SearchVehiclesResultRaw = NormalizedResponse_for_VehicleResponsePayload_and_NullRaw;

const createVehicleConfigurations = (
	configurations: Array<VehicleConfigurationRaw>,
	ctx: Context
) =>
	configurations.map(
		(item): VehicleConfiguration => ({
			token: item.token ?? null,
			properties: item.properties,
			uvdbProperties: createUvdbProperties(item.uvdb_properties, ctx)
		})
	);

export const createSearchVehicles = (
	result: SearchVehiclesResultRaw,
	apiConfig: ApiConfig
): SearchVehiclesResult => {
	const ctx = Context.create(result, apiConfig);
	const vehicleConfigurations = createVehicleConfigurations(
		result.payload.vehicle_configurations,
		ctx
	);

	return {
		chassisNumber: result.payload.chassis_number ?? null,
		vehicleConfigurations
	};
};
