import {
	JobPartRaw,
	PartSupplyOfferRaw,
	SupplyInventoryRaw,
	VariantSupplyRaw
} from '@/sdk/generated';
import {
	JobPartSupplies,
	PartSupplyOffer,
	SupplyInventory,
	SupplyLocation,
	VariantSupply
} from '../types';
import { Context } from '../utils/context';
import { isDefined } from '../utils/object';
import { encodeGapcPartIdentityKey } from '../utils/resources';
import { createCartSubtotalPrice, createOffer, createShippingTime } from './cart';
import { createGapcPart } from './gapc';
import { createJobPart } from './job-parts';

export const createVariantSupplies = (
	supply: VariantSupplyRaw[],
	ctx: Context
): VariantSupply[] => {
	return supply.map(supply => createVariantSupply(supply, ctx));
};

export const createVariantSupply = (supply: VariantSupplyRaw, ctx: Context): VariantSupply => {
	const inventory = createSupplyInventories(supply.inventory, ctx);
	const vendor = ctx.resources.get({ path: 'vendors', id: supply.vendor_id });

	return {
		availability: supply.availability,
		displayPrice: supply.display_price ?? null,
		grade: supply.grade ?? null,
		offerId: supply.offer_id,
		price: supply.price,
		vendor,
		inventory
	};
};

export const createSupplyInventories = (
	inventory: SupplyInventoryRaw[],
	ctx: Context
): SupplyInventory[] => {
	return inventory.map(inventory => createSupplyInventory(inventory, ctx));
};

export const createSupplyInventory = (
	inventory: SupplyInventoryRaw,
	ctx: Context
): SupplyInventory => {
	const location = createSupplyLocation(inventory, ctx);
	return {
		location,
		availability: inventory.availability
	};
};

export const createSupplyLocation = (
	supply: SupplyInventoryRaw,
	ctx: Context
): SupplyLocation | null => {
	const location = ctx.resources.get({ path: 'locations', id: supply.location_id });
	if (!location) {
		return null;
	}

	const vendor = ctx.resources.get({ path: 'vendors', id: location.vendor_id });
	if (!vendor) {
		return null;
	}

	return {
		id: location.id,
		name: location.name,
		vendor
	};
};

export const createPartSupplyOffer = (
	supplyOffer: PartSupplyOfferRaw,
	ctx: Context
): PartSupplyOffer | null => {
	const vendor = ctx.resources.get({ path: 'vendors', id: supplyOffer.vendor_id });

	const gapcParts = supplyOffer.parts
		.map(p => p.job_part)
		.map(jobPart =>
			ctx.resources.get({
				path: 'gapc_parts',
				id: encodeGapcPartIdentityKey({
					gapcBrandId: jobPart.gapc_brand_id,
					mpn: jobPart.mpn
				})
			})
		)
		.filter(isDefined)
		.map(resourceGapcPart => createGapcPart(resourceGapcPart, ctx));

	if (!vendor || gapcParts.length === 0) {
		return null;
	}

	const offer = createOffer(supplyOffer.offer, ctx);
	const price = createCartSubtotalPrice(supplyOffer.price);
	const shipping = supplyOffer.shipping_time ? createShippingTime(supplyOffer.shipping_time) : null;

	return {
		id: supplyOffer.id,
		availability: supplyOffer.availability,
		grade: supplyOffer.grade ?? null,
		gapcParts,
		offer,
		price,
		shipping,
		vendor
	};
};

export const createJobPartSupplies = (
	jobParts: JobPartRaw[],
	supplies: Record<string, PartSupplyOfferRaw>,
	ctx: Context
): JobPartSupplies[] => {
	const supply_values = Object.values(supplies);
	return jobParts
		.map(jobPart => {
			const partSuppliers = supply_values.filter(supply => {
				return supply.parts.some(
					supplyPart =>
						supplyPart.job_part.gapc_brand_id === jobPart.gapc_brand_id &&
						supplyPart.job_part.mpn === jobPart.mpn
				);
			});

			const part = createJobPart(jobPart, ctx);
			if (!part) {
				return null;
			}

			const supplies = partSuppliers
				.map(supply => createPartSupplyOffer(supply, ctx))
				.filter(isDefined);

			return {
				part,
				supplies
			};
		})
		.filter(isDefined);
};
