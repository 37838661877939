import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { CollisionArea } from '@sdk/lib';
import { Control } from 'react-hook-form';
import VehicleCollisionAreasOutline from '../vehicle-collision-areas-outline';
import { ControlledCollisionAreaSelection } from './subcomponents';
import { CollisionAreaFormData, VehicleCollisionAssembly } from './types';

type VehicleCollisionSelectorProps = InheritableElementProps<
	'div',
	{
		collisionMapId: string;
		collisionAreas: CollisionArea[];
		control: Control<CollisionAreaFormData>;
	}
>;

export const VehicleCollisionSelector = ({
	className,
	control,
	collisionMapId,
	collisionAreas,
	...rest
}: VehicleCollisionSelectorProps) => {
	return (
		<VehicleCollisionAreasOutline
			className={className}
			collisionMapId={collisionMapId}
			collisionAreas={collisionAreas}
			renderArea={({ id, name, location }) => {
				return (
					<ControlledCollisionAreaSelection
						key={id}
						control={control}
						className={location}
						id={id}
						name={name}
					/>
				);
			}}
			{...rest}
		/>
	);
};

type VehicleAssemblySelectorProps = InheritableElementProps<
	'ul',
	{
		control: Control<CollisionAreaFormData>;
		collisions: VehicleCollisionAssembly[];
	}
>;

export const VehicleAssemblySelector = ({
	control,
	collisions,
	...rest
}: VehicleAssemblySelectorProps) => {
	return (
		<ul {...rest}>
			{collisions.map(({ id: areaId, name, severity }) => (
				<li
					className="w-full flex items-center gap-3 py-2.5 border-b last:border-none"
					key={areaId}
				>
					<div className="font-medium text-sm text-gray-700 flex-1">{name}</div>
					<div
						className={tlsx(
							'font-medium text-sm text-blue-600',
							{ 'text-blue-600': severity === 'Light' },
							{ 'text-yellow-600': severity === 'Medium' },
							{ 'text-red-600': severity === 'Heavy' }
						)}
					>
						{severity}
					</div>
				</li>
			))}
		</ul>
	);
};
