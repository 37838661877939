import { draft_order, supply } from '@/sdk/reflect/reflect';
import {
	GetJobSupplyRecommendationsResult,
	PartSupplyOffer,
	SupplyItemOffer,
	SupplyVendor
} from '@sdk/lib';
import { useMemo } from 'react';
import { match } from '@/types/match';
import {
	DraftOrderSelection,
	JobPartItemModel,
	JobPartItemSelection,
	OfferStatus,
	SupplyRecommendation,
	SupplyVendorOption
} from '../../models';
import { createItemContext } from '../../order-request';
import { createAllVendors } from '../../supply';

export type SupplySelectionByJobPartIdentity = Record<string, JobPartItemSelection>;

export const useSupplyRecommendations = (
	selection: DraftOrderSelection,
	data: GetJobSupplyRecommendationsResult
) => {
	const vendors = useMemo<SupplyVendor[]>(() => {
		const allOffers = data.parts.flatMap(part => part.supplies);
		const allVendors = createAllVendors(allOffers);

		return allVendors;
	}, [data.parts]);

	const offers = useMemo(() => {
		return Object.values(data.offers).reduce<Record<string, PartSupplyOffer>>((acc, offer) => {
			acc[getOfferId(offer.offer)] = offer;
			return acc;
		}, {});
	}, [data.offers]);

	const statuses = useMemo<Record<string, OfferStatus | null>>(() => {
		const draftOrders = Object.values(selection.draft_orders ?? {}).sort(
			(a, b) => a.created_at - b.created_at
		);

		return draftOrders.reduce<Record<string, OfferStatus | null>>((acc, draftOrder) => {
			for (const item of draftOrder.items) {
				if (item.buyable.type === 'Listing') {
					const litingOfferId = getReflectOfferId(item.buyable.offer);
					const status = match<draft_order.DraftOrderItemStatus, OfferStatus | null>(item.status, {
						Pending: () =>
							match(draftOrder.status, {
								Draft: () => 'Draft',
								Processing: () => 'Processing',
								Cancelled: () => null,
								Processed: () => null,
								Finalised: () => null
							}),
						Rejected: () => 'Rejected',
						Approved: () =>
							match(draftOrder.status, {
								Draft: () => null,
								Processing: () => null,
								Cancelled: () => null,
								Processed: () => 'Accepted',
								Finalised: () => 'Ordered'
							})
					});

					if (status) {
						acc[litingOfferId] = status;
					}
				}
			}
			return acc;
		}, {});
	}, [selection]);

	const supplySelectionByJobPart = useMemo(() => {
		const draftOrders = Object.values(selection.draft_orders ?? {});
		return draftOrders.reduce<SupplySelectionByJobPartIdentity>((acc, draftOrder) => {
			if (draftOrder.status !== 'Draft') {
				// We only care about draft draft orders when referring to selection.
				return acc;
			}

			for (const item of draftOrder.items) {
				if (item.buyable.type === 'Listing') {
					const listingOfferId = getReflectOfferId(item.buyable.offer);
					const offer = offers[listingOfferId];
					if (!offer) {
						continue;
					}

					for (const jobPart of offer.gapcParts) {
						// There will only ever be one context for a part
						const jobPartSupplies = data.parts.find(
							p => p.part.partIdentity === jobPart.partIdentity
						);
						if (!jobPartSupplies) {
							continue;
						}
						acc[jobPart.partIdentity] = {
							offerId: offer.id,
							quantity: item.quantity
						};
					}
				}
			}

			return acc;
		}, {});
	}, [selection, offers]);

	const jobParts = useMemo<JobPartItemModel[]>(() => {
		return data.parts.map(item => {
			const options: SupplyVendorOption[] = vendors.map(supplier => ({
				vendor: supplier,
				supplies: item.supplies
					.filter(supply => supply.vendor.id === supplier.id)
					.map(supply => {
						const offerId = getOfferId(supply.offer);
						const status = statuses[offerId];
						return {
							...supply,
							status
						};
					})
			}));

			// There will only ever be one context for a part
			const context = createItemContext(item.part)?.[0] ?? null;
			const selection = supplySelectionByJobPart[item.part.partIdentity];

			return {
				identity: item.part.partIdentity,
				context,
				options,
				selection
			};
		});
	}, [data.parts, supplySelectionByJobPart, vendors]);

	const recommendations = useMemo<SupplyRecommendation[]>(() => {
		const seenOfferIds = new Set<string>();
		return data.recommendations
			.filter(recommendation => {
				// Filter out confusing behaviour where the same offer
				// ids are used for the same taglines.
				const sortedOfferIdsKey = recommendation.offers.slice().sort().join(',');
				if (seenOfferIds.has(sortedOfferIdsKey)) {
					return false;
				}
				seenOfferIds.add(sortedOfferIdsKey);
				return true;
			})
			.map(recommendation => {
				return {
					id: recommendation.taglines.join(':'),
					taglines: recommendation.taglines,
					offerIds: recommendation.offers
				};
			});
	}, [data.recommendations]);

	return {
		recommendations,
		jobParts,
		vendors
	};
};

const getOfferId = (offer: SupplyItemOffer) => {
	if (offer.type === 'Product') {
		return offer.offerId;
	}

	if (offer.type === 'Kit') {
		// This may not be needed, but lets not assume
		// that the order of the offer ids is the same
		const sortedIds = offer.offerIds.sort();
		return sortedIds.join(':');
	}

	return '';
};

const getReflectOfferId = (offer: supply.SupplyItemOffer) => {
	if (offer.type === 'Product') {
		return offer.offer_id;
	}

	if (offer.type === 'Kit') {
		// This may not be needed, but lets not assume
		// that the order of the offer ids is the same
		const sortedIds = offer.offer_ids.sort();
		return sortedIds.join(':');
	}

	return '';
};
