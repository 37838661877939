import { Site } from '@/app/common/hooks/use-me';
import { formatAddress } from '@/app/utils/address';
import { InheritableElementProps } from '@/types/utilties';

type LocationDetailsProps = InheritableElementProps<
	'dl',
	{
		site?: Site;
	}
>;

export const LocationDetails = ({ site, ...rest }: LocationDetailsProps) => {
	if (!site) {
		return null;
	}

	return (
		<dl {...rest}>
			<dt className="sr-only">Vehicle:</dt>
			<dd className="text-sm font-medium text-gray-900">{site.name}</dd>
			<dt className="sr-only">Plate / Chassis Number</dt>
			<dd className="text-sm text-gray-700">{formatAddress(site.shipping_address!)}</dd>
		</dl>
	);
};
