/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NormalizedResponse_for_PaginatedResult_for_JobLink_and_CursorNumericPosition_and_Null } from '../models/NormalizedResponse_for_PaginatedResult_for_JobLink_and_CursorNumericPosition_and_Null';
import type { NormalizedResponse_for_VehicleResponsePayload_and_Null } from '../models/NormalizedResponse_for_VehicleResponsePayload_and_Null';
import type { SearchJobsRequest } from '../models/SearchJobsRequest';
import type { SearchVehicleRequest } from '../models/SearchVehicleRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SearchService {
	constructor(public readonly httpRequest: BaseHttpRequest) {}
	/**
	 * search jobs
	 * @param requestBody
	 * @returns NormalizedResponse_for_PaginatedResult_for_JobLink_and_CursorNumericPosition_and_Null
	 * @throws ApiError
	 */
	public searchJobs(
		requestBody: SearchJobsRequest
	): CancelablePromise<NormalizedResponse_for_PaginatedResult_for_JobLink_and_CursorNumericPosition_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.search',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * search vehicles
	 * @param requestBody
	 * @returns NormalizedResponse_for_VehicleResponsePayload_and_Null
	 * @throws ApiError
	 */
	public searchVehicles(
		requestBody: SearchVehicleRequest
	): CancelablePromise<NormalizedResponse_for_VehicleResponsePayload_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/vehicles.search',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
}
