import { InheritableElementProps } from '@/types/utilties';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, useId } from 'react';

type RegoInputProps = InheritableElementProps<
	'input',
	{
		error?: string;
	}
>;

export const RegoInput = forwardRef(
	({ error, className, ...rest }: RegoInputProps, ref: ForwardedRef<HTMLInputElement>) => {
		const inputId = useId();
		const descriptionId = useId();
		return (
			<div>
				<div
					className={clsx(
						'relative w-full transition-shadow bg-black border-[3px] sm:border-4 border-yellow-400 rounded-lg border-spacing-2 focus-within:ring-offset-2 focus-within:ring-theme-500 focus-within:ring-2 ring ring-black',
						className
					)}
				>
					<div className="absolute w-1.5 h-1.5 bg-white rounded-full sm:w-2 sm:h-2 left-1 top-1" />
					<div className="absolute w-1.5 h-1.5 bg-white rounded-full sm:w-2 sm:h-2 left-20 top-1" />
					<div className="absolute w-1.5 h-1.5 bg-white rounded-full sm:w-2 sm:h-2 left-28 top-1" />
					<div className="absolute w-1.5 h-1.5 bg-white rounded-full sm:w-2 sm:h-2 left-1 bottom-1" />
					<div className="absolute w-1.5 h-1.5 bg-white rounded-full sm:w-2 sm:h-2 right-1 top-1" />
					<div className="absolute w-1.5 h-1.5 bg-white rounded-full sm:w-2 sm:h-2 right-20 top-1" />
					<div className="absolute w-1.5 h-1.5 bg-white rounded-full sm:w-2 sm:h-2 right-28 top-1" />
					<div className="absolute w-1.5 h-1.5 bg-white rounded-full sm:w-2 sm:h-2 right-1 bottom-1" />
					<div className="absolute w-full font-mono text-sm font-medium text-center text-yellow-400 uppercase">
						Rego
					</div>
					<label htmlFor={inputId} className="sr-only">
						Rego
					</label>
					<input
						ref={ref}
						id={inputId}
						aria-describedby={error ? descriptionId : undefined}
						className="w-full h-full px-4 pt-5 pb-4 font-mono text-3xl tracking-widest text-center text-white uppercase bg-transparent outline-none sm:text-5xl"
						{...rest}
					/>
				</div>
				{error && (
					<p className="mt-1.5 text-xs text-red-600" id={descriptionId}>
						{error}
					</p>
				)}
			</div>
		);
	}
);
