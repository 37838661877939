import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Alert } from '@mantine/core';

type AppErrorProps = {
	error: Error;
};

export const AppError = ({ error }: AppErrorProps) => (
	<main className="m-2">
		<Alert icon={<ExclamationCircleIcon className="w-5 h-5" />} title="Uh oh" color="red">
			Something went wrong, please refresh the page.
			<details>
				<summary>Details</summary>
				{error?.message}
			</details>
		</Alert>
	</main>
);
