export const linkWithSearchParams = (
	to: string,
	params: Record<string, string | string[] | undefined>
) => {
	const searchParams = new URLSearchParams();
	for (const [key, value] of Object.entries(params)) {
		if (!value) {
			continue;
		}
		if (typeof value === 'string') {
			searchParams.set(key, value);
		} else {
			value.forEach(each => searchParams.append(key, each));
		}
	}

	return `${to}?${searchParams.toString()}`;
};
